import React from "react"

interface RemotionPreviewSwitcherContextType {
  currentlyOpenPreviewId?: string
  setCurrentlyOpenPreviewId: (currentlyOpenPreviewId: string) => void
}

export const RemotionPreviewSwitcherContext =
  React.createContext<RemotionPreviewSwitcherContextType>({
    currentlyOpenPreviewId: undefined,
    setCurrentlyOpenPreviewId: () =>
      console.error(
        "setCurrentlyOpenPreviewId not passed to RemotionPreviewSwitcherContext"
      ),
  })
