import * as React from "react"

import FilterContainer from "packages/frontend/src/components/FilterContainer"

import {
  Preset,
  prettyBaseVideoSize,
  prettyBaseVideoVerticalAlignment,
} from "@trimmr/trimmr-lib/types/Preset"
import {
  horizontalAlignmentOptionIdToName,
  textAnimationIdToName,
  verticalAlignmentOptionIdToName,
} from "@trimmr/trimmr-lib/types/RenderJob"

import AspectRatioIcon from "@mui/icons-material/AspectRatio"
import ExpandIcon from "@mui/icons-material/Expand"
import PublicIcon from "@mui/icons-material/Public"
import SplitscreenIcon from "@mui/icons-material/Splitscreen"
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom"
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter"
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop"
import WarningIcon from "@mui/icons-material/Warning"
import LoadingButton from "@mui/lab/LoadingButton"
import {
  Breakpoint,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
  Tooltip,
} from "@mui/material"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Pagination from "@mui/material/Pagination"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

import { CreatePresetParams } from "../../../../api/presets/create"
import searchPresets, {
  SearchPresetParams,
} from "../../../../api/presets/search"
import ConditionalInfoAlert from "../../../../components/ConditionalInfoAlert"
import CreatedAtChip from "../../../../components/CreatedAtChip"
import HeadingContainer from "../../../../components/HeadingContainer"
import NewPresetModal from "../../../../components/NewPresetModal"
import { darkTheme } from "../../../../lib/mdTheme"
import { useAppDispatch, useAppSelector } from "../../../../redux"
import { addSnackbar } from "../../../../redux/snackbars"
import PresetButtonGroup from "./PresetButtonGroup"
import PresetPreview from "./PresetPreview"
import PresetRenderJobAssetsList from "./PresetRenderJobAssetsList"

let queryTimeout: NodeJS.Timeout

interface Props {
  asAdmin?: boolean
  hideHeading?: boolean
  hideFilterLabel?: boolean
  maxWidth?: false | Breakpoint
  disableGutters?: boolean
  selectorMode?: boolean
  onSelectPreset?: (preset: Preset) => void
  perPage?: number
  createPresetParams?: CreatePresetParams
}

const PresetsManagement: React.FC<Props> = ({
  asAdmin,
  hideHeading,
  hideFilterLabel,
  maxWidth,
  disableGutters,
  selectorMode,
  onSelectPreset,
  perPage = 12,
  createPresetParams,
}) => {
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)

  const dispatch = useAppDispatch()

  const [createPresetModalOpen, setCreatePresetModalOpen] =
    React.useState(false)
  const [email, setEmail] = React.useState("")
  const [presetsLoading, setPresetsLoading] = React.useState(true)
  const [presets, setPresets] = React.useState<Preset[] | null>(null)
  const [page, setPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(0)
  const [totalPresets, setTotalPresets] = React.useState(0)
  const [name, setName] = React.useState("")
  const [publicPresets, setPublic] = React.useState("all")

  const [errorMessage, setErrorMessage] = React.useState("")

  const handleSearchPresets = React.useCallback(
    ({ name, email, publicPresets }) => {
      const params: SearchPresetParams = {
        page,
        perPage: perPage,
        email,
        public: publicPresets === "public" ? true : false,
        mine: publicPresets === "mine" ? true : false,
        name,
        asAdmin,
        userUuid: asAdmin
          ? ""
          : currentUserProfile && publicPresets !== "all"
          ? currentUserProfile.uuid
          : "",
      }

      setPresetsLoading(true)
      searchPresets(params)
        .then(({ presets, totalPages, totalResults }) => {
          setPresets(presets)
          setTotalPages(totalPages)
          setTotalPresets(totalResults)
          setPresetsLoading(false)
        })
        .catch((err) => {
          console.error(err)
          setPresetsLoading(false)
          setErrorMessage(
            (err.response && err.response.data && err.response.data.error) ||
              err.message
          )
        })
    },
    [page, perPage, asAdmin, currentUserProfile]
  )

  React.useEffect(() => {
    setPresetsLoading(true)
    clearTimeout(queryTimeout)
    queryTimeout = setTimeout(() => {
      handleSearchPresets({
        name,
        email,
        publicPresets,
      })
    }, 300)
  }, [page, handleSearchPresets, name, email, publicPresets])

  return (
    <div>
      <NewPresetModal
        open={createPresetModalOpen}
        onClose={() => setCreatePresetModalOpen(false)}
        onCreate={() => {
          handleSearchPresets({ name, email, publicPresets })
          setCreatePresetModalOpen(false)
        }}
        createPresetParams={createPresetParams}
      />
      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error" onClose={() => setErrorMessage("")}>
            {errorMessage}
          </Alert>
        </Stack>
      )}

      {!hideHeading && (
        <HeadingContainer>
          <Typography component="h1" variant="h6" color="textPrimary" noWrap>
            Presets
          </Typography>
        </HeadingContainer>
      )}

      <FilterContainer hideFilterLabel={hideFilterLabel}>
        <FormControl style={{ width: "110px" }}>
          <InputLabel size="small" id="status">
            Type
          </InputLabel>
          <Select
            fullWidth
            size="small"
            labelId="public"
            label="Type"
            value={publicPresets}
            onChange={(e) => setPublic(e.target.value)}
          >
            <MenuItem value="all">
              <Typography variant="body1">All</Typography>
            </MenuItem>
            <MenuItem value="mine">
              <Typography variant="body1">Mine</Typography>
            </MenuItem>
            <MenuItem value="public">
              <Typography variant="body1">Public</Typography>
            </MenuItem>
          </Select>
        </FormControl>

        <TextField
          size="small"
          value={name || ""}
          onChange={(e) => setName(e.target.value)}
          label="Name"
          variant="outlined"
        />

        {asAdmin && (
          <TextField
            autoComplete="off"
            size="small"
            value={email || ""}
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            variant="outlined"
          />
        )}

        {createPresetParams && (
          <Tooltip title="Turn the currently configured styles and assets into a reusable template">
            <LoadingButton
              onClick={() => setCreatePresetModalOpen(true)}
              color="secondary"
              variant="contained"
            >
              Make Preset
            </LoadingButton>
          </Tooltip>
        )}
      </FilterContainer>

      <Divider sx={{ mb: 2 }} />

      <Container maxWidth={maxWidth ?? "md"} disableGutters={disableGutters}>
        <ConditionalInfoAlert>
          Presets are reusable theming and overlay settings that can be applied
          to any video. To use a preset, select it from the video creator
          dashboard. Subscribed users can create their own presets while editing
          a video in the video creator.
        </ConditionalInfoAlert>

        {presetsLoading && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress color="inherit" />
          </Box>
        )}

        {!presetsLoading && (
          <>
            {totalPages > 1 && (
              <Paper sx={{ mb: 2, p: 2 }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body1" color="textSecondary">
                    {totalPresets} presets
                  </Typography>

                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={(_, v) => setPage(v)}
                  />
                </Stack>
              </Paper>
            )}

            <Grid container columns={12} spacing={2} alignItems="stretch">
              {presets && presets.length === 0 && (
                <Grid item xs={12}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                      No presets found.
                    </Typography>
                    <Box>
                      {publicPresets === "mine" && (
                        <Button
                          variant="contained"
                          onClick={() => setPublic("all")}
                        >
                          Browse public Presets
                        </Button>
                      )}
                    </Box>
                  </Paper>
                </Grid>
              )}
              {presets &&
                presets.map((preset) => (
                  <Grid
                    item
                    xs={12}
                    sm={selectorMode ? 4 : 6}
                    lg={4}
                    xl={4}
                    key={preset.uuid}
                  >
                    <Paper key={preset.uuid} style={{ height: "100%" }}>
                      <Stack
                        direction="column"
                        justifyContent="space-between"
                        style={{ height: "100%" }}
                        sx={{ p: 1 }}
                      >
                        <Tooltip
                          placement="top"
                          title={
                            <ThemeProvider theme={darkTheme}>
                              <Stack
                                direction="row"
                                flexWrap="wrap"
                                gap="0.5rem"
                                sx={{ mb: 1 }}
                              >
                                {preset.public && (
                                  <>
                                    <Chip
                                      icon={<PublicIcon />}
                                      color="success"
                                      size="small"
                                      label="Public"
                                    />
                                    {currentUserProfile?.isAdmin &&
                                      preset.renderJobAssets?.find(
                                        (rja) => rja.asset?.public === false
                                      ) && (
                                        <Chip
                                          icon={<WarningIcon />}
                                          color="error"
                                          size="small"
                                          label="Non-public assets"
                                        />
                                      )}
                                  </>
                                )}
                                {(preset.renderJobAssets?.length || 0) > 0 && (
                                  <Tooltip
                                    title={
                                      // need dark theme inside tooltip
                                      <ThemeProvider theme={darkTheme}>
                                        <Box sx={{ pb: 1 }}>
                                          <PresetRenderJobAssetsList
                                            omitFirstDivider
                                            renderJobAssets={
                                              preset.renderJobAssets || []
                                            }
                                            baseVideoZIndex={
                                              preset.baseVideoZIndex
                                            }
                                          />
                                        </Box>
                                      </ThemeProvider>
                                    }
                                  >
                                    <Chip
                                      size="small"
                                      label={`${preset.renderJobAssets?.length} Assets`}
                                    />
                                  </Tooltip>
                                )}

                                <Tooltip
                                  title={
                                    <Box>
                                      <Box>
                                        <b>Width:</b> {preset.resolutionWidth}px
                                      </Box>
                                      <Box>
                                        <b>Height:</b> {preset.resolutionHeight}
                                        px
                                      </Box>
                                      <Box>
                                        <b>X Padding:</b>&nbsp;
                                        {preset.paddingHorizontalPx}px
                                      </Box>
                                      <Box>
                                        <b>Y Padding:</b>&nbsp;
                                        {preset.paddingVerticalPx}px
                                      </Box>
                                      <Box>
                                        <b>Caption Alignment:</b>&nbsp;
                                        {verticalAlignmentOptionIdToName(
                                          preset.verticalCaptionAlignment
                                        )}
                                        &nbsp;
                                        {horizontalAlignmentOptionIdToName(
                                          preset.horizontalCaptionAlignment
                                        )}
                                      </Box>
                                    </Box>
                                  }
                                >
                                  <Chip
                                    icon={<AspectRatioIcon />}
                                    size="small"
                                    label={` ${preset.resolutionWidth}x${preset.resolutionHeight}px`}
                                  />
                                </Tooltip>

                                {preset.textAnimation !== "none" && (
                                  <Tooltip
                                    title={
                                      preset.oneWordAtATime
                                        ? "One word at a time"
                                        : "Animate phrases"
                                    }
                                  >
                                    <Chip
                                      size="small"
                                      label={textAnimationIdToName(
                                        preset.textAnimation
                                      )}
                                    />
                                  </Tooltip>
                                )}

                                {preset.baseVideoSize &&
                                  preset.baseVideoVerticalAlignment && (
                                    <Tooltip title="Base video vertical size / alignment">
                                      <Chip
                                        size="small"
                                        icon={iconForBaseVideoPosition(
                                          preset.baseVideoVerticalAlignment,
                                          preset.baseVideoSize
                                        )}
                                        label={`${prettyBaseVideoVerticalAlignment(
                                          preset.baseVideoVerticalAlignment
                                        )} ${prettyBaseVideoSize(
                                          preset.baseVideoSize,
                                          true
                                        )}`}
                                      />
                                    </Tooltip>
                                  )}
                                {(!preset.public || asAdmin) && (
                                  <CreatedAtChip createdAt={preset.createdAt} />
                                )}
                              </Stack>
                            </ThemeProvider>
                          }
                        >
                          <Box flexGrow={1} sx={{ mb: 1 }}>
                            <PresetPreview
                              preset={{ ...preset }}
                              showDefaultCaption
                            />
                          </Box>
                        </Tooltip>

                        <Stack direction="column">
                          <Typography
                            variant="body1"
                            color="text.primary"
                            sx={{ mb: 1 }}
                            data-preset-uuid={preset.uuid}
                          >
                            {preset.name}
                          </Typography>

                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            sx={{ mt: -1, mb: 1 }}
                            style={{ whiteSpace: "pre-wrap" }}
                          >
                            {preset.description}
                          </Typography>

                          {asAdmin && preset.user && (
                            <Typography
                              variant="subtitle2"
                              color="text.secondary"
                              sx={{ mb: 1 }}
                            >
                              Created by {preset.user.email}
                            </Typography>
                          )}
                        </Stack>

                        <Stack spacing={1} direction="column">
                          {selectorMode && (
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => {
                                onSelectPreset && onSelectPreset(preset)
                                dispatch(
                                  addSnackbar({ text: "Preset applied." })
                                )
                              }}
                            >
                              Use Preset
                            </Button>
                          )}

                          {!selectorMode &&
                            currentUserProfile &&
                            (currentUserProfile.uuid === preset.userUuid ||
                              currentUserProfile.isAdmin) && (
                              <PresetButtonGroup
                                preset={preset}
                                onPresetChanged={() =>
                                  handleSearchPresets({
                                    name,
                                    email,
                                    publicPresets,
                                  })
                                }
                              />
                            )}
                        </Stack>
                      </Stack>
                    </Paper>
                  </Grid>
                ))}
            </Grid>
          </>
        )}
      </Container>
    </div>
  )
}

function iconForBaseVideoPosition(
  baseVideoVerticalAlignment: string,
  baseVideoSize: string
): React.ReactElement | undefined {
  switch (baseVideoSize) {
    case "content-fit-vertical":
      return <ExpandIcon />
    case "one-third-height-vertical":
    case "two-thirds-height-vertical":
    case "half-height-vertical":
      return <SplitscreenIcon />
  }

  switch (baseVideoVerticalAlignment) {
    case "top":
      return <VerticalAlignTopIcon />
    case "middle":
      return <VerticalAlignCenterIcon />
    case "bottom":
      return <VerticalAlignBottomIcon />
  }
}

export default PresetsManagement
