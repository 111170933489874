import { API_BASE_URL } from "../../consts"
import axios from "../axios"

export default function searchRenderJobs({
  page,
  perPage,
  importJobUuid,
  userUuid,
  completed,
  email,
  failed,
}: {
  page: number
  perPage: number
  importJobUuid?: string
  userUuid?: string
  email?: string
  completed?: boolean
  failed?: boolean
}) {
  const params = new URLSearchParams()

  page && params.set("page", String(page))
  perPage && params.set("perPage", String(perPage))
  importJobUuid && params.set("importJobUuid", importJobUuid)
  userUuid && params.set("userUuid", userUuid)
  completed && params.set("completed", String(completed))
  failed && params.set("failed", String(failed))
  email && params.set("email", String(email))

  return axios
    .get(`${API_BASE_URL}/render-jobs?${params.toString()}`)
    .then((response) => ({
      renderJobs: response.data,
      totalPages: Number(response.headers["x-total-pages"]),
      totalResults: Number(response.headers["x-total-results"]),
    }))
}
