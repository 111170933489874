import axios from "../axios"
import { API_BASE_URL } from "../../consts"

export default function createBlogPost(params: {
  title: string
  subtitle: string
}) {
  return axios
    .post(`${API_BASE_URL}/blogposts`, params)
    .then((response) => response.data)
}
