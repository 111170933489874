// import RocketLaunchIcon from "@mui/icons-material/RocketLaunch"
import LoadingButton from "@mui/lab/LoadingButton"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

export interface Feature {
  icon: React.ReactNode
  text: string
}

export default function SubscriptionItemContent({
  description,
  tagline,
  cost,
  annual,
  buttonText,
  disabled,
  buttonColor,
  buttonVariant,

  features,

  onClick,
  loading,
  featuresDuration,
}: {
  description?: string
  tagline?: string
  cost?: number
  annual?: boolean
  buttonText?: string
  disabled?: boolean

  buttonVariant?: "contained" | "outlined"
  buttonColor?:
    | "warning"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"

  features?: Feature[]

  onClick?: () => void
  loading?: boolean
  featuresDuration?: string
}) {
  return (
    <Stack spacing={2}>
      {description && (
        <Typography
          variant="h6"
          align="left"
          // color="text.contrastText"
          style={{
            fontWeight: 400,
            lineHeight: 1.2,
          }}
          sx={{ pt: 2 }}
        >
          {description}
        </Typography>
      )}

      {tagline && (
        <Typography
          component="h6"
          variant="h6"
          align="left"
          // color="text.contrastText"
          style={{
            fontSize: "1rem",
            fontWeight: 400,
            opacity: 0.8,
          }}
        >
          {tagline}
        </Typography>
      )}

      {typeof cost === "number" && (
        <Typography
          component="h6"
          variant="h6"
          align="left"
          // color="text.contrastText"
          style={{
            fontWeight: 400,
            lineHeight: 1.2,
          }}
        >
          US${" "}
          <Typography variant="h4" component="span">
            {cost}
          </Typography>
          /{annual ? "yr" : "mo"}
        </Typography>
      )}

      {!disabled && onClick && (
        <LoadingButton
          size="large"
          color={buttonColor || "primary"}
          fullWidth
          onClick={onClick}
          disabled={disabled}
          variant={buttonVariant || "contained"}
          loading={loading}
        >
          {/* <RocketLaunchIcon color="inherit" sx={{ mr: 1 }} /> */}
          {disabled
            ? "Manage subscription through Stripe"
            : buttonText || "Upgrade"}
        </LoadingButton>
      )}

      {features && (
        <>
          {featuresDuration && (
            <Typography variant="body1" color="text.primary">
              {featuresDuration}
            </Typography>
          )}
          {features.map((feature) => (
            <Stack
              key={feature.text}
              direction="row"
              alignItems="center"
              sx={{ mt: 2 }}
            >
              {feature.icon}

              <Typography variant="body1" sx={{ ml: 2 }}>
                {feature.text}
              </Typography>
            </Stack>
          ))}
        </>
      )}
    </Stack>
  )
}
