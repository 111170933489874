// could be improved by looking for existing video element? avoid making multiple?

export default async function loadVideoAndReturnDimensions(
  videoURL: string
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video")
    video.preload = "metadata"
    video.onloadedmetadata = () => {
      resolve({
        width: video.videoWidth,
        height: video.videoHeight,
      })
    }
    video.onerror = reject
    video.src = videoURL
  })
}
