import { API_BASE_URL } from "../../consts"
import axios from "../axios"

export default function searchBlogPosts({ hidden }: { hidden?: boolean } = {}) {
  const params = new URLSearchParams()
  hidden && params.append("hidden", String(hidden))
  return axios
    .get(`${API_BASE_URL}/blogposts?${params.toString()}`)
    .then((response) => ({
      blogPosts: response.data,
      totalPages: Number(response.headers["x-total-pages"]),
      totalResults: Number(response.headers["x-total-results"]),
    }))
}
