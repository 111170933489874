import * as React from "react"
import { useSearchParams } from "react-router-dom"

import { RenderJob } from "@trimmr/trimmr-lib/types/RenderJob"

import { InputLabel, MenuItem, Select, TextField } from "@mui/material"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import FormControl from "@mui/material/FormControl"
import Grid from "@mui/material/Grid"
import Pagination from "@mui/material/Pagination"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

import searchRenderJobs from "../../../../api/renderJobs/search"
import ConditionalInfoAlert from "../../../../components/ConditionalInfoAlert"
import FilterContainer from "../../../../components/FilterContainer"
import HeadingContainer from "../../../../components/HeadingContainer"
import { RenderJobChips } from "../../../../components/RenderJobCard/RenderJobCard"
import { useAppSelector } from "../../../../redux"
import RenderJobButtonGroup from "./RenderJobButtonGroup"

let queryTimeout: NodeJS.Timeout

const PER_PAGE = 10

interface Props {
  asAdmin?: boolean
}

export default function RenderJobsManagement({ asAdmin }: Props) {
  const [renderJobsLoading, setRenderJobsLoading] = React.useState(true)
  const [renderJobs, setRenderJobs] = React.useState<RenderJob[] | null>(null)
  const [totalRenderedVideos, setTotalRenderedVideos] = React.useState(0)
  const [status, setStatus] = React.useState("any")
  const [email, setEmail] = React.useState("")
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)

  const [page, setPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(0)

  const [errorMessage, setErrorMessage] = React.useState("")

  const [hasReadQueryParams, setHasReadQueryParams] = React.useState(false)
  const [searchParams] = useSearchParams()
  const userEmailFromQuery = searchParams.get("userEmail")

  const handleSearchRenderJobs = React.useCallback(() => {
    const userUuid = asAdmin
      ? ""
      : currentUserProfile
      ? currentUserProfile.uuid
      : ""

    let failed: boolean | undefined = undefined
    let completed: boolean | undefined = undefined

    if (status === "failed") failed = true
    if (status === "completed") completed = true

    searchRenderJobs({
      page,
      perPage: PER_PAGE,

      userUuid,
      email,
      failed,
      completed,
    })
      .then(({ renderJobs, totalPages, totalResults }) => {
        setRenderJobs(renderJobs)
        setTotalPages(totalPages)
        setTotalRenderedVideos(totalResults)
        setRenderJobsLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setRenderJobsLoading(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message
        )
      })
  }, [asAdmin, currentUserProfile, email, page, status])

  React.useEffect(() => {
    if (!hasReadQueryParams && userEmailFromQuery) {
      setEmail(decodeURIComponent(userEmailFromQuery))
      setHasReadQueryParams(true)
    }
  }, [hasReadQueryParams, userEmailFromQuery])

  React.useEffect(() => {
    setRenderJobsLoading(true)
    clearTimeout(queryTimeout)
    queryTimeout = setTimeout(() => {
      handleSearchRenderJobs()
    }, 300)
  }, [page, handleSearchRenderJobs])

  return (
    <div>
      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error" onClose={() => setErrorMessage("")}>
            {errorMessage}
          </Alert>
        </Stack>
      )}

      <HeadingContainer>
        <Typography component="h1" variant="h6" color="textPrimary" noWrap>
          Short Videos
        </Typography>
      </HeadingContainer>

      {asAdmin && (
        <FilterContainer>
          <FormControl style={{ width: "150px" }}>
            <InputLabel size="small" id="status">
              Status
            </InputLabel>
            <Select
              fullWidth
              size="small"
              labelId="status"
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value="any">
                <Typography variant="body1">Any</Typography>
              </MenuItem>
              <MenuItem value="failed">
                <Typography variant="body1">Failed</Typography>
              </MenuItem>
              <MenuItem value="completed">
                <Typography variant="body1">Completed</Typography>
              </MenuItem>
            </Select>
          </FormControl>

          <TextField
            size="small"
            autoComplete="off"
            value={email || ""}
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            variant="outlined"
          />
        </FilterContainer>
      )}

      <Divider sx={{ mb: 2 }} />

      <Container maxWidth="md">
        <ConditionalInfoAlert>
          Find all your past rendered shorts here. Hit "Open in Creator" on a
          short to start a new one from it.
        </ConditionalInfoAlert>

        {renderJobsLoading && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress color="inherit" />
          </Box>
        )}

        {!renderJobsLoading && (
          <>
            <Paper sx={{ mb: 2, p: 2 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body1" color="textSecondary">
                  {totalRenderedVideos} short videos
                </Typography>

                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={(_, v) => setPage(v)}
                />
              </Stack>
            </Paper>

            <Grid container columns={12} alignItems="stretch" spacing={2}>
              {renderJobs &&
                renderJobs.map((renderJob) => (
                  <Grid item sm={12} md={6} key={renderJob.uuid}>
                    <Paper key={renderJob.uuid} style={{ height: "100%" }}>
                      <Stack direction="row" style={{ height: "100%" }}>
                        {renderJob.completed && renderJob.thumbnailURL && (
                          <img
                            src={renderJob.thumbnailURL}
                            alt="Thumbnail for video"
                            style={{
                              maxWidth: "40%",
                              maxHeight: "30vh",
                              backgroundColor: "#333",
                              lineHeight: 0,
                            }}
                          />
                        )}

                        <Stack
                          direction="column"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          sx={{ p: 1, pt: 2 }}
                        >
                          <Box>
                            <Typography
                              variant="body1"
                              color="text.primary"
                              sx={{ mb: 1 }}
                              data-render-job-uuid={renderJob.uuid}
                            >
                              {renderJob.importJob.name ||
                                renderJob.importJob.youtubeName ||
                                renderJob.importJob.youtubeURL}
                            </Typography>
                            {renderJob.user && (
                              <Typography
                                variant="subtitle2"
                                color="text.secondary"
                                sx={{ mb: 1 }}
                              >
                                By {renderJob.user.email}
                              </Typography>
                            )}

                            <Box sx={{ mb: 1 }}>
                              <RenderJobChips renderJob={renderJob} />
                            </Box>
                          </Box>

                          <RenderJobButtonGroup renderJob={renderJob} />
                        </Stack>
                      </Stack>
                    </Paper>
                  </Grid>
                ))}
            </Grid>
          </>
        )}
      </Container>
    </div>
  )
}
