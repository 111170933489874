import * as React from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import EmailIcon from "@mui/icons-material/Email"
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Link from "@mui/material/Link"
import List from "@mui/material/List"
import Stack from "@mui/material/Stack"
import Toolbar from "@mui/material/Toolbar"
import { ThemeProvider } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

import AppBarItems from "../../components/AppBar/AppBarItems"
import AppBar from "../../components/AppBarWithDrawer/AppBar"
import Drawer from "../../components/AppBarWithDrawer/Drawer"
import DarkModeToggle from "../../components/DarkModeToggle"
import { darkTheme, lightTheme } from "../../lib/mdTheme"
import { useAppSelector } from "../../redux"
import { AccountPageList } from "./AccountPageList"

export default function Account() {
  const navigate = useNavigate()
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  const [open, setOpen] = React.useState(true)
  const { pathname, search, hash } = useLocation()

  const isLargeScreen = useMediaQuery("(min-width: 1440px)")

  React.useEffect(() => {
    setOpen(isLargeScreen)
  }, [isLargeScreen])

  const toggleDrawer = () => {
    setOpen(!open)
  }

  React.useEffect(() => {
    const fullURI: string = pathname + search + hash

    const target = "/app/signin?redirect=" + encodeURIComponent(fullURI)

    if (!currentUserProfile) {
      navigate(target)
    }
  })

  // const hasActiveStripeSubscription =
  //   currentUserProfile && currentUserProfile.hasActiveStripeSubscription

  const theme = darkModeEnabled ? darkTheme : lightTheme

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{ display: "flex", backgroundColor: "background.default" }}
        // className="light-background-img"
      >
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <ChevronRightIcon />
            </IconButton>

            {/* {!open && (
              <div style={{ overflow: "hidden", height: "3rem" }}>
                <img
                  src="/img/trimmr-man.svg"
                  className="trimmr-man-navbar-hide-under-600px"
                  alt="Cartoon Trimmr man"
                  style={{
                    height: "10rem",
                    marginRight: "1rem",
                    cursor: "pointer",
                    marginTop: "-3rem",
                  }}
                  onClick={() => navigate("/app")}
                />
              </div>
            )} */}

            <Box sx={{ flexGrow: 1 }} />
            {/* <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Account
            </Typography> */}

            <DarkModeToggle />

            <AppBarItems />
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>

          <Divider />

          <List sx={{ pt: 0 }}>
            <AccountPageList open={open} />
          </List>
        </Drawer>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
            paddingBottom: "10rem", // when iOS scroll is being dumb
          }}
          className={`mobile-have-min-width home-parallax ${
            darkModeEnabled ? "dark-background-img" : "light-background-img"
          }`}
        >
          <Toolbar />

          {currentUserProfile && (
            <>
              {!currentUserProfile.emailConfirmed && (
                <Alert
                  icon={false}
                  variant="standard"
                  severity="warning"
                  sx={{
                    ".MuiAlert-message": { width: "100%" },
                    width: "100%",
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    width="100%"
                  >
                    <Link
                      href="/app/account/profile"
                      onClick={(e) => {
                        e.preventDefault()
                        navigate("/app/account/profile")
                      }}
                    >
                      <Button variant="contained" color="warning">
                        <EmailIcon color="inherit" sx={{ mr: 1 }} />
                        Resend
                      </Button>
                    </Link>

                    <Box flexGrow={1}>
                      Please check your inbox for an email confirmation link. It
                      may take a minute to come through, and you may need to
                      check your junk.
                    </Box>
                  </Stack>
                </Alert>
              )}
              {!currentUserProfile.hasActiveStripeSubscription && (
                <Alert
                  icon={false}
                  variant="standard"
                  severity="warning"
                  color="primary"
                  sx={{
                    ".MuiAlert-message": { width: "100%" },
                    width: "100%",
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    width="100%"
                  >
                    <Link
                      href="/app/account/subscription"
                      onClick={(e) => {
                        e.preventDefault()
                        navigate("/app/account/subscription")
                      }}
                    >
                      <Button color="primary" variant="contained">
                        <RocketLaunchIcon color="inherit" sx={{ mr: 1 }} />
                        Upgrade
                      </Button>
                    </Link>

                    <Box flexGrow={1}>
                      Upgrade your account to render videos and import
                      additional videos.
                    </Box>
                    {/* <Link
                        href="/app/account/subscription"
                        onClick={(e) => {
                          e.preventDefault()
                          navigate("/app/account/subscription")
                        }}
                      >
                        <Button color="warning" variant="outlined">
                          <LocalActivityIcon color="warning" sx={{ mr: 1 }} />
                          Start Trial
                        </Button>
                      </Link>

                      <Box flexGrow={1}>
                        A free trial or subscription is required to import more
                        than 10 minutes in Youtube content.
                      </Box> */}
                  </Stack>
                </Alert>
              )}
            </>
          )}

          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  )
}
