import * as React from "react"
import { useLocation, useNavigate } from "react-router-dom"

import debounce from "lodash.debounce"

import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import LoadingButton from "@mui/lab/LoadingButton"
import Alert, { AlertColor } from "@mui/material/Alert"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import passwordStrength from "../../api/auth/passwordStrength"
import completeResetPasswordRequest from "../../api/resetPasswordRequests/update"
import AppBar from "../../components/AppBar"
import Copyright from "../../components/Copyright"
import { useAppDispatch } from "../../redux"
import { addSnackbar } from "../../redux/snackbars"

export default function CompletePasswordReset() {
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const navigate = useNavigate()

  const [submitting, setSubmitting] = React.useState(false)
  const [loadingPassword, setLoadingPassword] = React.useState(false)
  const [passwordValidationErrorMessage, setPasswordValidationErrorMessage] =
    React.useState("")

  const [newPassword, setNewPassword] = React.useState("")

  const [errorMessage, setErrorMessage] = React.useState("")
  const [messageSeverity, setMessageSeverity] =
    React.useState<AlertColor>("error")

  const handleSubmit = (event) => {
    event.preventDefault()

    setSubmitting(true)
    const params = new URLSearchParams(search)
    const token = params.get("token")
    const email = params.get("email")

    completeResetPasswordRequest(email || "", newPassword, token || "")
      .then(() => {
        setSubmitting(false)

        dispatch(
          addSnackbar({
            id: "passwordResetComplete",
            text: "Password reset complete.",
          })
        )

        navigate("/app/signin")
      })
      .catch((err) => {
        console.error(err)
        setSubmitting(false)
        setMessageSeverity("error")
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message
        )
      })
  }

  React.useEffect(() => {
    const debounced = debounce(() => {
      if (!newPassword) {
        setLoadingPassword(false)
        setPasswordValidationErrorMessage("")
        return
      }

      setLoadingPassword(true)
      setPasswordValidationErrorMessage("")
      passwordStrength(newPassword)
        .then(({ errorMessage }) => {
          setLoadingPassword(false)
          setPasswordValidationErrorMessage(errorMessage)
        })
        .catch((err) => {
          setLoadingPassword(false)
          console.error("Error checking password strength", err)
          dispatch(
            addSnackbar({
              text: "Error checking password strength.",
            })
          )
        })
    }, 200)

    debounced()

    return debounced.cancel
  }, [dispatch, newPassword, setPasswordValidationErrorMessage])

  const submitDisabled = !(newPassword && !passwordValidationErrorMessage)

  return (
    <>
      <AppBar title="Trimmr" />

      <Toolbar />

      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity={messageSeverity}>{errorMessage}</Alert>
        </Stack>
      )}

      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" color="text.primary">
            Complete Password Reset
          </Typography>

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
            width="100%"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="password"
                  label="New password"
                  type="password"
                  name="password"
                  value={newPassword || ""}
                  onChange={(e) => setNewPassword(e.target.value)}
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>

            {passwordValidationErrorMessage && (
              <Typography sx={{ mt: 2 }} color="error">
                {passwordValidationErrorMessage}
              </Typography>
            )}

            <LoadingButton
              disabled={submitDisabled}
              type="submit"
              fullWidth
              loading={loadingPassword || submitting}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit Password
            </LoadingButton>
            <Grid container justifyContent="center">
              <Grid item style={{ paddingRight: "1rem" }}>
                <Link href="/app/signin" variant="body2">
                  Sign In
                </Link>
              </Grid>
              <Grid item>
                <Link href="/app/signup" variant="body2">
                  Sign Up
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Copyright sx={{ mt: 5 }} />
      </Container>
    </>
  )
}
