import { API_BASE_URL } from "../../consts"
import { ContentIdea } from "../../types/ContentIdea"
import axios from "../axios"

export default async function searchContentIdeas({
  page,
  perPage,
  importJobUuid,
  asAdmin,
}: {
  page: number
  perPage: number
  importJobUuid?: string
  asAdmin?: boolean
}) {
  const params = new URLSearchParams()

  page && params.set("page", String(page))
  perPage && params.set("perPage", String(perPage))
  importJobUuid && params.set("importJobUuid", String(importJobUuid))
  asAdmin && params.set("asAdmin", "true")

  const response = await axios.get(
    `${API_BASE_URL}/content-ideas?${params.toString()}`
  )

  return {
    contentIdeas: response.data as ContentIdea[],
    totalPages: Number(response.headers["x-total-pages"]),
    totalResults: Number(response.headers["x-total-results"]),
  }
}
