import * as React from "react"

import { AxiosError } from "axios"

import { Asset } from "@trimmr/trimmr-lib/types/Asset"

import LoadingButton from "@mui/lab/LoadingButton"
import {
  Alert,
  AlertTitle,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
} from "@mui/material"
// import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

import createAsset from "../../../../api/assets/create"
import Progress from "../../../../components/Progress"
import createVideoThumbnail from "../../../../lib/createVideoThumbnail"
import { useAppSelector } from "../../../../redux"

// import { useAppSelector } from "../../../../redux"

const videoFileExtensions = ["mp4", "mov", "avi", "mkv", "webm"]

function EditAssetModal({
  open,
  onClose,
  onAssetCreate,
}: {
  open: boolean
  onClose: () => void
  onAssetCreate: (asset: Asset) => void
}) {
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)

  const [assetPublic, setAssetPublic] = React.useState(false)

  // const currentUserProfile = useAppSelector((state) => state.currentUserProfile)
  const [uploadedImage, setUploadedVideo] = React.useState<File | null>(null)
  const [uploadProgress, setUploadProgress] = React.useState<number>(0)
  const [name, setName] = React.useState("")
  const [errorMessage, setErrorMessage] = React.useState("")
  const [isSaving, setIsSaving] = React.useState(false)

  const onUploadProgress = React.useCallback(
    (event: ProgressEvent) => {
      setUploadProgress(Math.round((event.loaded * 100) / event.total))
    },
    [setUploadProgress]
  )

  const uploadDisabled = !name

  const uploadAsset = React.useCallback(
    async (uploadedImage: File) => {
      setIsSaving(true)
      setErrorMessage("")
      setUploadedVideo(null)

      const finishWithSizes = async (
        height: string,
        width: string,
        duration?: string,
        thumbnail?: Blob
      ) => {
        const formData = new FormData()
        if (uploadedImage)
          formData.append("file", uploadedImage, uploadedImage.name)
        if (thumbnail) formData.append("thumbnail", thumbnail, "thumbnail.jpg")
        formData.append("name", name)
        formData.append("originalHeight", height)
        formData.append("originalWidth", width)
        formData.append("originalDuration", duration || "0")
        try {
          const asset = await createAsset(formData, onUploadProgress)
          onAssetCreate(asset)
        } catch (err) {
          console.error("Error creating asset")
          setErrorMessage(
            (err as AxiosError)?.response?.data?.error || "Error creating asset"
          )
        }
        setIsSaving(false)
        setUploadProgress(0)
        setName("")
      }

      if (
        videoFileExtensions.includes(
          uploadedImage.name.split(".").splice(-1)[0].toLowerCase()
        )
      ) {
        const video = document.createElement("video")
        video.preload = "metadata"
        video.onloadedmetadata = async () => {
          const thumbnail = await createVideoThumbnail(uploadedImage)

          await finishWithSizes(
            String(video.videoHeight),
            String(video.videoWidth),
            String(video.duration),
            thumbnail
          )
        }
        video.src = URL.createObjectURL(uploadedImage)
      } else {
        const reader = new FileReader()
        reader.readAsDataURL(uploadedImage)

        reader.onloadend = async function () {
          const image = new Image()

          const objectUrl = window.URL.createObjectURL(uploadedImage)

          image.src = objectUrl

          image.onload = async function () {
            const sizes = this as unknown as { width: number; height: number }
            window.URL.revokeObjectURL(objectUrl)

            await finishWithSizes(String(sizes.height), String(sizes.width))
          }
        }
      }
    },
    [name, onUploadProgress, onAssetCreate]
  )

  React.useEffect(() => {
    if (uploadedImage) {
      uploadAsset(uploadedImage)
    }
  }, [uploadAsset, uploadedImage])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="create-asset-title"
      aria-describedby="create-asset-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="create-asset-dialog-title">Upload</DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <Alert severity="info" sx={{ mb: 2 }}>
          Upload an image or video so it can be overlaid in a short. First
          provide a human-readable name then hit "Select File" to browse for
          media.
          <br />
          <br />
          Accepted file formats are:
          <br />
          .jpg, .jpeg, .png, .gif, .bmp, .webp, .svg, .ico, .tif, .tiff
          <br />
          .mp4, .m4v, .mkv, .mov, .avi, .wmv, .flv, .3gp, .3g2, .mpeg, .mpg,
          .ogv, .webm
        </Alert>

        {errorMessage && (
          <Stack sx={{ width: "100%", mb: 2 }} spacing={2}>
            <Alert severity="error" onClose={() => setErrorMessage("")}>
              {errorMessage}
            </Alert>
          </Stack>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="name"
              required
              fullWidth
              id="name"
              value={name || ""}
              label="Asset Name"
              sx={{ mt: 1 }}
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
          </Grid>

          {currentUserProfile && currentUserProfile.isAdmin && (
            <Grid item xs={12}>
              <Alert severity="warning">
                <AlertTitle>This feature is only visible to admins.</AlertTitle>
                <FormControlLabel
                  label="Is Public?"
                  sx={{ color: "text.primary" }}
                  control={
                    <Checkbox
                      checked={assetPublic}
                      onChange={(e) => {
                        setAssetPublic(e.target.checked)
                      }}
                    />
                  }
                />
              </Alert>
            </Grid>
          )}
        </Grid>

        {uploadProgress > 0 && (
          <Box sx={{ my: 2 }}>
            <Progress progressPercent={uploadProgress} />
            <Typography sx={{ mt: 2 }}>Uploading asset...</Typography>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        {/* <LoadingButton
          onClick={() => handleSave()}
          loading={saving}
          disabled={!name}
          color="primary"
          variant="contained"
        >
          Save
        </LoadingButton> */}

        <label htmlFor="asset-file-upload-button">
          <input
            style={{ display: "none" }}
            accept="image/*,video/*"
            id="asset-file-upload-button"
            type="file"
            disabled={uploadDisabled}
            onChange={(e) =>
              e.target.files && setUploadedVideo(e.target.files[0])
            }
          />
          <LoadingButton
            disabled={uploadDisabled}
            loading={isSaving}
            variant="contained"
            size="medium"
            component="span"
            style={{ height: "100%" }}
            sx={{ mr: 2 }}
          >
            Select File
          </LoadingButton>
        </label>
        <LoadingButton onClick={onClose}>Cancel</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default EditAssetModal
