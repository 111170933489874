import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"

import window from "../../lib/window"
import Copyright from "../Copyright"
import RouterLink from "../RouterLink"

function FooterLink({
  to,
  href,
  label,
}: {
  to?: string
  href?: string
  label?: string
}) {
  return (
    <Box className="footer-item-link" sx={{ mb: 2, width: "100%" }}>
      {href && (
        <Link
          onClick={(e) => {
            e.preventDefault()
            href && window.open(href, "_blank")
          }}
          color="textPrimary"
          href={href}
        >
          <span style={{ textAlign: "center", display: "block" }}>{label}</span>
        </Link>
      )}
      {to && (
        <RouterLink href={to} underline="hover">
          <span style={{ textAlign: "center", display: "block" }}>{label}</span>
        </RouterLink>
      )}
    </Box>
  )
}

function FooterColumn(props) {
  return (
    <Grid
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      item
      xs={4}
    >
      {props.children}
    </Grid>
  )
}

export default function Footer() {
  return (
    <Container maxWidth="md" sx={{ pb: 6 }}>
      <Box sx={{ mt: 10, mb: 2 }}>
        <Grid container spacing={2}>
          <FooterColumn>
            <FooterLink to="/app" label="Home" />
            <FooterLink to="/app/signin" label="Sign In" />
            <FooterLink to="/app/signup" label="Sign Up" />
          </FooterColumn>
          <FooterColumn>
            <FooterLink to="/app/contact" label="Contact" />
            <FooterLink to="/app/privacy" label="Privacy Policy" />
            <FooterLink to="/app/terms" label="Terms of Use" />
          </FooterColumn>
          <FooterColumn>
            {/* <FooterLink href="/app/affiliates" label="Become an affiliate" /> */}
            <FooterLink href="/app/blog" label="Guides" />
          </FooterColumn>
        </Grid>
      </Box>

      <Copyright sx={{}} />
    </Container>
  )
}
