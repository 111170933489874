import * as React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

import EmailIcon from "@mui/icons-material/Email"
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Stack,
  Typography,
} from "@mui/material"

import window from "../../lib/window"
import ContactForm from "../../pages/Contact/components/ContactForm"
import { useAppSelector } from "../../redux"

export default function ContactFab() {
  const [errorMessage, setErrorMessage] = React.useState("")
  const [open, setOpen] = React.useState(false)

  const infoAlertsEnabled = useAppSelector((state) => state.infoAlertsEnabled)
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)

  // must be logged in to disable info alert
  if (!infoAlertsEnabled && currentUserProfile) return null

  return (
    <>
      <Fab
        variant="extended"
        onClick={() => setOpen(true)}
        color="info"
        sx={{
          position: "fixed",
          bottom: 16,
          right: 24,
        }}
      >
        <EmailIcon sx={{ mr: 1 }} />
        Contact
      </Fab>

      {open && (
        <GoogleReCaptchaProvider reCaptchaKey={window.RECAPTCHA_SITE_KEY}>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="content-dialog-title"
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="content-dialog-title">Contact</DialogTitle>
            <DialogContent>
              <Typography component="p" color="text.primary" sx={{ mb: 2 }}>
                Please reach out if you have any questions or need any support.
                Trimmr is constantly being updated and improved, and we'd love
                to hear your feedback!
              </Typography>
              <Typography component="p" color="text.primary" sx={{ mb: 2 }}>
                You should hear back from us within a day.
              </Typography>

              {errorMessage && (
                <Stack sx={{ width: "100%", mb: 2 }} spacing={2}>
                  <Alert severity="error" onClose={() => setErrorMessage("")}>
                    {errorMessage}
                  </Alert>
                </Stack>
              )}

              <ContactForm
                setErrorMessage={setErrorMessage}
                hideDevice
                onSend={() => setOpen(false)}
              />
            </DialogContent>
          </Dialog>
        </GoogleReCaptchaProvider>
      )}
    </>
  )
}
