import { API_BASE_URL } from "../../consts"
import axios from "../axios"

export default function getUsage(userUuid: string): Promise<{
  renderUsage: number
  importUsage: number
  contentIdeasUsage: number
}> {
  return axios
    .get(`${API_BASE_URL}/users/${userUuid}/usage`)
    .then((response) => response.data)
}
