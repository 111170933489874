import * as React from "react"

import { RenderJobAsset } from "@trimmr/trimmr-lib/types/RenderJobAsset"

import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import TheatersIcon from "@mui/icons-material/Theaters"
import VolumeOffIcon from "@mui/icons-material/VolumeOff"
import VolumeUpIcon from "@mui/icons-material/VolumeUp"
import { Divider } from "@mui/material"
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

import { AssetTypeChip } from "../AssetsManagement/AssetsManagement"

const PresetRenderJobAssetsList: React.FC<{
  renderJobAssets: RenderJobAsset[]
  omitFirstDivider?: boolean
  baseVideoZIndex: number
}> = ({ renderJobAssets, omitFirstDivider = false, baseVideoZIndex }) => {
  // we'll be rendering base video at some index
  const renderJobAssetsPositionsFirst = renderJobAssets.slice(
    0,
    baseVideoZIndex
  )
  const renderJobAssetsPositionsSecond = renderJobAssets.slice(baseVideoZIndex)

  const renderEntries = (
    renderJobAssetsSubset: RenderJobAsset[],
    firstGroup = false
  ) => {
    return renderJobAssetsSubset.map((renderJobAsset, i) => (
      <Box key={renderJobAsset.uuid}>
        {!(omitFirstDivider && i === 0 && firstGroup) && (
          <Divider sx={{ my: 1 }} />
        )}
        <Typography variant="body1" sx={{ mb: 0.5 }}>
          {renderJobAsset.asset?.name}
        </Typography>
        <Stack direction="row" flexWrap="wrap" gap="0.5rem">
          {renderJobAsset.asset && (
            <AssetTypeChip asset={renderJobAsset.asset} />
          )}
          <Chip
            size="small"
            label={`Scale: ${Math.ceil(renderJobAsset.xScale)}`}
          />
          <Chip
            size="small"
            label={`X: ${Math.ceil(renderJobAsset.xPosition)}px`}
          />
          <Chip
            size="small"
            label={`Y: ${Math.ceil(renderJobAsset.xPosition)}px`}
          />
          {renderJobAsset.asset?.type === "VIDEO" && (
            <Chip
              icon={renderJobAsset.muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
              size="small"
              label={renderJobAsset.muted ? "Muted" : "Sound on"}
            />
          )}
        </Stack>
      </Box>
    ))
  }

  return (
    <Stack direction="column" alignItems="stretch" spacing={0} sx={{ py: 1 }}>
      {renderEntries(renderJobAssetsPositionsFirst, true)}

      {renderJobAssetsPositionsFirst.length > 0 && <Divider sx={{ my: 1 }} />}

      <Typography variant="body1" sx={{ mb: 0.5 }}>
        Base video
      </Typography>
      <Stack direction="row" flexWrap="wrap" gap="0.5rem">
        <Chip icon={<TheatersIcon />} size="small" label="Video" />
        <Chip
          icon={<CloudDownloadIcon />}
          color="info"
          size="small"
          label="Base video"
        />
      </Stack>

      {renderEntries(renderJobAssetsPositionsSecond)}
    </Stack>
  )
}

export default PresetRenderJobAssetsList
