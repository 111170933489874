import { API_BASE_URL } from "../../consts"
import axios from "../axios"

export default function updateAsset(
  assetUuid: string,
  params: {
    name?: string
    public?: boolean
  }
) {
  return axios
    .patch(`${API_BASE_URL}/assets/${assetUuid}`, params)
    .then((response) => response.data)
}
