import * as React from "react"

import {
  PREMIUM_IDEAS_GENERATED,
  PREMIUM_PLAN_ANNUAL_COST,
  PREMIUM_PLAN_MONTHLY_COST,
  PREMIUM_SECONDS_IMPORT,
  PREMIUM_SECONDS_RENDER,
  STARTER_IDEAS_GENERATED,
  STARTER_PLAN_MONTHLY_COST,
  STARTER_SECONDS_IMPORT,
  STARTER_SECONDS_RENDER,
  UNLIMITED_IDEAS_GENERATED,
  UNLIMITED_PLAN_ANNUAL_COST,
  UNLIMITED_PLAN_MONTHLY_COST,
  UNLIMITED_SECONDS_IMPORT,
  UNLIMITED_SECONDS_RENDER,
} from "@trimmr/trimmr-lib/constants"

import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh"
// import CardGiftcardIcon from "@mui/icons-material/CardGiftcard"
import LightbulbIcon from "@mui/icons-material/Lightbulb"
import VideoCallIcon from "@mui/icons-material/VideoCall"
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary"
// import Alert from "@mui/material/Alert"
// import AlertTitle from "@mui/material/AlertTitle"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Switch from "@mui/material/Switch"
import Typography from "@mui/material/Typography"

import SubscriptionItemContent from "./SubscriptionItemContent"

export default function SwitchableSubscriptionItem({
  plan,

  onClickAnnual,
  onClickMonthly,
  disabled,

  variant,
  loading,

  tagline,
}: // showTrialAlert = true,
{
  plan?: "Unlimited" | "Premium" | "Starter"
  disabled?: boolean

  onClickAnnual?: () => void
  onClickMonthly?: () => void

  variant?: string
  loading?: boolean
  // showTrialAlert?: boolean
  tagline?: string
}) {
  const [annual, setAnnual] = React.useState(false)

  let cost: number
  let premiumFeatures: { text: string; icon: JSX.Element }[]

  switch (plan) {
    case "Unlimited":
      cost = annual ? UNLIMITED_PLAN_ANNUAL_COST : UNLIMITED_PLAN_MONTHLY_COST
      premiumFeatures = [
        {
          icon: <VideoLibraryIcon />,
          text: `${UNLIMITED_SECONDS_IMPORT / 60 / 60} hours of video (~${
            UNLIMITED_SECONDS_IMPORT / 60 / 15
          } videos)`,
        },
        {
          icon: <VideoCallIcon />,
          text: `${
            UNLIMITED_SECONDS_RENDER / 60 / 60
          } hours of video content (~${UNLIMITED_SECONDS_RENDER / 30} shorts)`,
        },
        {
          icon: <LightbulbIcon />,
          text: `${UNLIMITED_IDEAS_GENERATED / 1000}k AI-edited videos`,
        },
        {
          icon: <AutoFixHighIcon />,
          text: `Create custom presets`,
        },
      ]
      break

    case "Premium":
      cost = annual ? PREMIUM_PLAN_ANNUAL_COST : PREMIUM_PLAN_MONTHLY_COST
      premiumFeatures = [
        {
          icon: <VideoLibraryIcon />,
          text: `Import ${PREMIUM_SECONDS_IMPORT / 60 / 60} hours of video (~${
            PREMIUM_SECONDS_IMPORT / 60 / 15
          } videos)`,
        },
        {
          icon: <VideoCallIcon />,
          text: `Render ${
            PREMIUM_SECONDS_RENDER / 60 / 60
          } hours of video content (~${PREMIUM_SECONDS_RENDER / 30} shorts)`,
        },
        {
          icon: <LightbulbIcon />,
          text: `Generate ${PREMIUM_IDEAS_GENERATED / 1000}k AI-edited videos`,
        },
        {
          icon: <AutoFixHighIcon />,
          text: `Create custom presets`,
        },
      ]
      break

    case "Starter":
    default:
      cost = STARTER_PLAN_MONTHLY_COST
      premiumFeatures = [
        {
          icon: <VideoLibraryIcon />,
          text: `Import ${STARTER_SECONDS_IMPORT / 60 / 60} hours of video (~${
            STARTER_SECONDS_IMPORT / 60 / 15
          } videos)`,
        },
        {
          icon: <VideoCallIcon />,
          text: `Render ${
            STARTER_SECONDS_RENDER / 60
          } minutes of video content (~${STARTER_SECONDS_RENDER / 30} shorts)`,
        },
        {
          icon: <LightbulbIcon />,
          text: `Generate ${STARTER_IDEAS_GENERATED / 1000}k AI-edited videos`,
        },
      ]
  }

  return (
    <Paper
      elevation={variant === "home" ? 0 : 4}
      sx={{ width: "100%", p: 4 }}
      style={{
        backgroundColor: variant === "home" ? "#000000" : undefined,
        borderRadius: variant === "home" ? 0 : undefined,
      }}
    >
      {/* {showTrialAlert && (
        <Alert color="success" sx={{ mb: 2 }} icon={<CardGiftcardIcon />}>
          <AlertTitle>Try premium for free!</AlertTitle>
          Try all premium features free for 7 days by subscribing. Cancel within
          the first week and you won't be charged.
        </Alert>
      )} */}

      {plan && (
        <Typography
          component="h1"
          variant="h5"
          align="left"
          // color="text.contrastText"
          style={{
            fontWeight: 800,
          }}
        >
          {plan}
        </Typography>
      )}

      {tagline && (
        <Typography
          component="h6"
          variant="h6"
          align="left"
          style={{
            fontWeight: 400,
            lineHeight: 1.2,
          }}
          sx={{ py: 2 }}
        >
          {tagline}
        </Typography>
      )}

      <SubscriptionItemContent
        cost={cost}
        // tagline="No charge for 7 days."
        onClick={onClickAnnual && annual ? onClickAnnual : onClickMonthly}
        featuresDuration="Per month:"
        features={premiumFeatures}
        disabled={disabled}
        annual={annual}
        loading={loading}
        buttonVariant={plan === "Premium" ? "contained" : "outlined"}
        buttonText="Sign Up"
      />

      {onClickAnnual && onClickMonthly && (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 4 }}
        >
          Monthly
          <Switch
            checked={annual}
            onChange={(e) => setAnnual(e.target.checked)}
          />
          Annual
        </Stack>
      )}
    </Paper>
  )
}
