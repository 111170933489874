import { LinearProgress } from "@mui/material"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

import durationToMSString from "../../../../helpers/soundFile/durationToMSString"

interface Props {
  title: string
  usageSeconds?: number
  usageCount?: number
  limitString: string
  limit: number
  exceededMessage?: string
  zeroMessage?: string
}

export default function UsageSection({
  title,
  usageSeconds,
  usageCount,
  limitString,
  limit,
  exceededMessage,
  zeroMessage,
}: Props) {
  let usagePercent = 100
  // support limit = 0
  if (limit > 0) {
    // round so that progress bar doesn't wrap
    usagePercent = Math.min(
      100,
      (100 * (Number(usageSeconds || 0) || Number(usageCount || 0))) / limit
    )
  }

  return (
    <Box>
      <Typography variant="h6" color="textPrimary">
        {title}
        <Typography color="textSecondary" display="inline" fontSize="0.9rem">
          &nbsp;(past 30 days):
        </Typography>
      </Typography>
      <Typography sx={{ mb: 0 }} variant="h6" color="textPrimary">
        {usageSeconds !== undefined && (
          <>{durationToMSString(usageSeconds, true, true)} </>
        )}
        {usageCount !== undefined && <>{usageCount} </>}
        <Typography color="textSecondary" display="inline" fontSize="0.9rem">
          / {limitString}
        </Typography>
      </Typography>
      <LinearProgress
        variant="determinate"
        color={
          limit === 0 ? "info" : usagePercent >= 100 ? "warning" : "success"
        }
        value={usagePercent}
      />
      {exceededMessage && usagePercent >= 100 && limit > 0 && (
        <Stack sx={{ mt: 2 }} spacing={2}>
          <Alert variant="outlined" severity="warning">
            {exceededMessage}
          </Alert>
        </Stack>
      )}
      {limit === 0 && zeroMessage && (
        <Stack sx={{ mt: 2 }} spacing={2}>
          <Alert variant="outlined" severity="info">
            {zeroMessage}
          </Alert>
        </Stack>
      )}
    </Box>
  )
}
