import * as React from "react"
import { Link, useSearchParams } from "react-router-dom"

import { format } from "date-fns"
import FilterContainer from "packages/frontend/src/components/FilterContainer"

import AutoDeleteIcon from "@mui/icons-material/AutoDelete"
import ErrorIcon from "@mui/icons-material/Error"
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Pagination from "@mui/material/Pagination"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"

import searchImportJobs, {
  SearchImportJobParams,
} from "../../../../api/importJobs/search"
import ConditionalInfoAlert from "../../../../components/ConditionalInfoAlert"
import CreatedAtChip from "../../../../components/CreatedAtChip"
import HeadingContainer from "../../../../components/HeadingContainer"
import durationToMSString from "../../../../helpers/soundFile/durationToMSString"
import { darkTheme } from "../../../../lib/mdTheme"
import window from "../../../../lib/window"
import { useAppSelector } from "../../../../redux"
import { ImportJob } from "../../../../types/ImportJob"
import DailyIdeaCheckbox from "./DailyIdeaCheckbox"
import ImportJobButtonGroup from "./ImportJobButtonGroup"

let queryTimeout: NodeJS.Timeout
const PER_PAGE = 10

interface Props {
  asAdmin?: boolean
}

const ImportJobsManagement: React.FC<Props> = ({ asAdmin }) => {
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)
  const [status, setStatus] = React.useState("any")
  const [email, setEmail] = React.useState("")
  const [importJobsLoading, setImportJobsLoading] = React.useState(true)
  const [importJobs, setImportJobs] = React.useState<ImportJob[] | null>(null)
  const [page, setPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(0)
  const [totalImportJobs, setTotalImportJobs] = React.useState(0)

  const [errorMessage, setErrorMessage] = React.useState("")

  const [hasReadQueryParams, setHasReadQueryParams] = React.useState(false)
  const [searchParams] = useSearchParams()
  const userEmailFromQuery = searchParams.get("userEmail")

  const handleImportJobUpdated = (
    importJobUuid: string,
    { dailyIdeaEmailEnabled }: { dailyIdeaEmailEnabled: boolean }
  ) => {
    setImportJobs(
      (importJobs || []).map((importJob) => {
        if (importJob.uuid === importJobUuid)
          importJob.dailyIdeaEmailEnabled = dailyIdeaEmailEnabled
        return importJob
      })
    )
  }

  const handleSearchImportJobs = React.useCallback(() => {
    const params: SearchImportJobParams = {
      page,
      perPage: PER_PAGE,
      email,
      userUuid: asAdmin
        ? ""
        : currentUserProfile
        ? currentUserProfile.uuid
        : "",
    }
    if (status === "failed") params.failed = true
    if (status === "completed") params.completed = true

    searchImportJobs(params)
      .then(({ importJobs, totalPages, totalResults }) => {
        setImportJobs(importJobs)
        setTotalPages(totalPages)
        setTotalImportJobs(totalResults)
        setImportJobsLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setImportJobsLoading(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message
        )
      })
  }, [page, email, asAdmin, currentUserProfile, status])

  React.useEffect(() => {
    if (!hasReadQueryParams && userEmailFromQuery) {
      setEmail(decodeURIComponent(userEmailFromQuery))
      setHasReadQueryParams(true)
    }
  }, [hasReadQueryParams, userEmailFromQuery])

  React.useEffect(() => {
    setImportJobsLoading(true)
    clearTimeout(queryTimeout)
    queryTimeout = setTimeout(() => {
      handleSearchImportJobs()
    }, 300)
  }, [page, handleSearchImportJobs])

  return (
    <div>
      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error" onClose={() => setErrorMessage("")}>
            {errorMessage}
          </Alert>
        </Stack>
      )}

      <HeadingContainer>
        <Typography component="h1" variant="h6" color="textPrimary" noWrap>
          Imported Videos
        </Typography>
      </HeadingContainer>

      {asAdmin && (
        <FilterContainer>
          <FormControl style={{ width: "140px" }}>
            <InputLabel size="small" id="status">
              Status
            </InputLabel>
            <Select
              fullWidth
              size="small"
              labelId="status"
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value="any">
                <Typography variant="body1">Any</Typography>
              </MenuItem>
              <MenuItem value="failed">
                <Typography variant="body1">Failed</Typography>
              </MenuItem>
              <MenuItem value="completed">
                <Typography variant="body1">Completed</Typography>
              </MenuItem>
            </Select>
          </FormControl>

          <TextField
            size="small"
            value={email || ""}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="off"
            label="Email"
            variant="outlined"
          />
        </FilterContainer>
      )}

      <Divider sx={{ mb: 2 }} />

      <Container maxWidth="md">
        <ConditionalInfoAlert>
          Here are all the videos you've imported so far. You can create a new
          short video from one by hitting "Open in Creator".
        </ConditionalInfoAlert>

        {importJobsLoading && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress color="inherit" />
          </Box>
        )}
        {!importJobsLoading && (
          <>
            <Paper sx={{ mb: 2, p: 2 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body1" color="textSecondary">
                  {totalImportJobs} imported videos
                </Typography>

                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={(_, v) => setPage(v)}
                />
              </Stack>
            </Paper>

            <Grid container columns={12} spacing={2} alignItems="stretch">
              {importJobs &&
                importJobs.map((importJob) => (
                  <Grid item xs={12} sm={6} key={importJob.uuid}>
                    <Paper key={importJob.uuid} style={{ height: "100%" }}>
                      <Stack
                        direction="column"
                        justifyContent="space-between"
                        style={{ height: "100%" }}
                        sx={{ p: 1 }}
                      >
                        <Stack direction="column">
                          {importJob.completed && importJob.thumbnailURL && (
                            <img
                              src={importJob.thumbnailURL}
                              alt="Thumbnail"
                              style={{
                                width: "100%",
                                backgroundColor: "#333",
                              }}
                            />
                          )}
                          <Typography
                            variant="body1"
                            data-import-job-uuid={importJob.uuid}
                            style={{ wordBreak: "break-word" }}
                          >
                            {importJob.videoFileOriginalName ||
                              importJob.youtubeName ||
                              importJob.youtubeURL}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                          >
                            <Link
                              onClick={(e) => {
                                e.preventDefault()
                                importJob.youtubeURL &&
                                  window.open(importJob.youtubeURL, "_blank")
                              }}
                              to={importJob.youtubeURL}
                              style={{ color: darkTheme.palette.primary.main }}
                            >
                              {importJob.youtubeURL}
                            </Link>
                          </Typography>

                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            sx={{ mb: 2 }}
                          >
                            Created{" "}
                            {format(new Date(importJob.createdAt), "Pp")}
                            {importJob.user && ` by ${importJob.user.email}`}
                          </Typography>

                          <Stack
                            direction="row"
                            flexWrap="wrap"
                            gap="0.5rem"
                            sx={{ mb: 1 }}
                          >
                            {!importJob.completed && !importJob.failed && (
                              <Chip
                                size="small"
                                label="Pending"
                                color="info"
                                variant="filled"
                              />
                            )}
                            {importJob.fileRemoved && (
                              <Tooltip title="Old files on free accounts are removed">
                                <Chip
                                  size="small"
                                  label="File removed"
                                  color="error"
                                  variant="filled"
                                  icon={<AutoDeleteIcon />}
                                />
                              </Tooltip>
                            )}

                            {importJob?.dailyIdeaLastSentAt && (
                              <Tooltip
                                title={`Daily idea sent ${format(
                                  new Date(importJob.dailyIdeaLastSentAt),
                                  "Pp"
                                )}`}
                              >
                                <Chip
                                  size="small"
                                  label="Daily idea sent"
                                  icon={<MarkEmailReadIcon />}
                                  variant="filled"
                                />
                              </Tooltip>
                            )}

                            {importJob.failed && (
                              <Tooltip title={importJob.failedReason}>
                                <Chip
                                  size="small"
                                  icon={<ErrorIcon />}
                                  label="Failed"
                                  color="error"
                                  variant="filled"
                                />
                              </Tooltip>
                            )}

                            <CreatedAtChip createdAt={importJob.createdAt} />

                            {importJob.completedAt && (
                              <Tooltip
                                title={`Completed ${format(
                                  new Date(importJob.completedAt),
                                  "Pp"
                                )}`}
                              >
                                <Chip
                                  size="small"
                                  label={`Took ${durationToMSString(
                                    (new Date(importJob.completedAt).getTime() -
                                      new Date(importJob.createdAt).getTime()) /
                                      1000
                                  )}`}
                                />
                              </Tooltip>
                            )}

                            {importJob.durationFromYoutube && (
                              <Chip
                                size="small"
                                label={`Duration ${durationToMSString(
                                  importJob.durationFromYoutube
                                )}`}
                              />
                            )}

                            {Boolean(importJob.renderJobCount) && (
                              <Chip
                                size="small"
                                variant="outlined"
                                label={`${importJob.renderJobCount} Render Jobs`}
                              />
                            )}
                            {Boolean(importJob.contentIdeasCount) && (
                              <Chip
                                size="small"
                                variant="outlined"
                                label={`${importJob.contentIdeasCount} Content Ideas`}
                              />
                            )}
                          </Stack>
                        </Stack>

                        <Stack direction="column">
                          {importJob.completed && (
                            <DailyIdeaCheckbox
                              disabled={Boolean(asAdmin)}
                              importJobUuid={importJob.uuid}
                              checked={importJob.dailyIdeaEmailEnabled}
                              onChange={(dailyIdeaEmailEnabled: boolean) =>
                                handleImportJobUpdated(importJob.uuid, {
                                  dailyIdeaEmailEnabled,
                                })
                              }
                            />
                          )}
                          <Box sx={{ mt: 1 }}>
                            <ImportJobButtonGroup importJob={importJob} />
                          </Box>
                        </Stack>
                      </Stack>
                    </Paper>
                  </Grid>
                ))}
            </Grid>
          </>
        )}
      </Container>
    </div>
  )
}

export default ImportJobsManagement
