import * as React from "react"

import LinearDeterminate from "./LinearDeterminate"

export default function Progress({
  id = "x",
  progressPercent,
  totalTime = 100,
  relativeToTime,
}: {
  id?: string
  progressPercent?: number
  totalTime?: number // seconds
  relativeToTime?: Date
}) {
  const [progress, setProgress] = React.useState(0)
  const intervalRef = React.useRef<NodeJS.Timer | undefined>()

  React.useEffect(() => {
    // HACK: idk why node being naughty
    clearInterval(intervalRef.current as unknown as number)
    intervalRef.current = undefined

    intervalRef.current = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 100
        }

        if (relativeToTime) {
          const now = new Date().getTime()

          const elapsed = now - relativeToTime.getTime()

          const percentage = (elapsed / (totalTime * 1000)) * 100
          return percentage
        }
        const diff = Math.random() * (100 / totalTime)
        return Math.min(oldProgress + diff, 100)
      })
    }, 500)

    return () => {
      clearInterval(intervalRef.current as unknown as number)
      intervalRef.current = undefined
    }
  }, [totalTime, id, relativeToTime])

  return (
    <LinearDeterminate progress={progressPercent ?? Math.min(progress, 100)} />
  )
}
