import * as React from "react"

import { PresetRenderAttributes } from "@trimmr/trimmr-lib/types/Preset"
import { SRTWithWordTimings } from "@trimmr/trimmr-lib/types/SRT/SRTWithWordTimings"

import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import { Box, Stack, Typography, styled } from "@mui/material"

import PresetPreview from "../../../pages/Account/pages/PresetsManagement/PresetPreview"

export const HoverColorBox = styled(Box)(() => ({
  backgroundColor: "rgba(0,0,0,0.5)",
  "&:hover": {
    backgroundColor: "rgba(0,0,0,0.6)",
  },
}))

// can't do subtitles because only the player can nicely scale down
// the fontSizePx
const MockVideoComposition: React.FC<{
  id: string

  // videoURL: string
  // thumbnailURL: string
  startSeconds: number
  resolutionWidth: number
  resolutionHeight: number
  setCurrentlyOpenPreviewId: (setCurrentlyOpenPreviewId: string) => void
  presetRenderAttributes: PresetRenderAttributes
  srtWithWordTimings: SRTWithWordTimings[]
  thumbnailUrl?: string
}> = ({
  id,
  // thumbnailURL,
  startSeconds,
  resolutionWidth,
  resolutionHeight,
  setCurrentlyOpenPreviewId,
  presetRenderAttributes,
  srtWithWordTimings,
  thumbnailUrl
}) => {
  const aspectRatio = `${resolutionWidth}/${resolutionHeight}`

  return (
    <Box
      width="100%"
      style={{
        aspectRatio,
        border: "1px solid #444",
        position: "relative",
      }}
    >
      <HoverColorBox
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",

          objectFit: "cover",
          objectPosition: "center",
          zIndex: 3,

          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          cursor: "pointer",
        }}
        // style={{
        //   zIndex: 3
        // }}
        onClick={() => setCurrentlyOpenPreviewId(id)}
      >
        <Stack
          style={{
            zIndex: 3,
            color: "white",
            textShadow: "0px 0px 10px rgba(0,0,0,0.9)",
          }}
          alignItems="center"
        >
          <PlayArrowIcon fontSize="large" style={{ color: "white" }} />

          <Typography variant="body1">Preview Short</Typography>
        </Stack>
      </HoverColorBox>

      <PresetPreview
        preset={{...presetRenderAttributes, thumbnailUrl}}
        showBorder={false}
        startSeconds={startSeconds}
        srtWithWordTimings={srtWithWordTimings}
      />
    </Box>
  )
}

export default MockVideoComposition
