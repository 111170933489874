import { API_BASE_URL } from "../../consts"

export default function oauth2Decision(transaction_id: string) {
  // Create a form element
  const form = document.createElement("form")
  form.method = "POST"
  form.action = `${API_BASE_URL}/oauth2/decision`

  // Create hidden form elements for each data you want to send
  const transactionIdField = document.createElement("input")
  transactionIdField.type = "hidden"
  transactionIdField.name = "transaction_id"
  transactionIdField.value = transaction_id

  const allowField = document.createElement("input")
  allowField.type = "hidden"
  allowField.name = "allow"
  allowField.value = "true" // Assuming you're always allowing here

  // Append the inputs to the form
  form.appendChild(transactionIdField)
  form.appendChild(allowField)

  // Append the form to the body
  document.body.appendChild(form)

  // Submit the form
  form.submit()
}
