import axios from "../axios"
import { API_BASE_URL } from "../../consts"

export default function updateRenderJob(
  renderJobUuid: string,
  params: { notifyOnComplete: boolean }
) {
  return axios
    .patch(`${API_BASE_URL}/render-jobs/${renderJobUuid}`, params)
    .then((response) => response.data)
}
