import React, { PropsWithChildren } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router"
import { useLocation } from "react-router-dom"

import fetchMe from "../api/users/fetchMe"
import { useAppSelector } from "../redux"
import { setCurrentUserProfile } from "../redux/currentUserProfile"
import { addSnackbar } from "../redux/snackbars"

// Purpose is to navigate user home if de-authed, also
// keep their profile in memory fresh
export default function FetchUserProfile(props: PropsWithChildren<unknown>) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)
  const [checked, setChecked] = React.useState(false)
  const { pathname, search, hash } = useLocation()

  const fetchUserProfile = React.useCallback(() => {
    const fullURI: string = pathname + search + hash

    if (!checked && currentUserProfile && currentUserProfile.uuid) {
      fetchMe()
        .then((user) => {
          dispatch(setCurrentUserProfile(user))
          setChecked(true)
        })
        .catch((err) => {
          console.error(err)
          setChecked(true)
          if (
            err.response &&
            err.response.data &&
            err.response.data.error === "You must be signed in."
          ) {
            dispatch(setCurrentUserProfile(null))
            if (pathname !== "/app/signin")
              navigate("/app/signin?redirect=" + encodeURIComponent(fullURI))

            dispatch(addSnackbar({ text: "Session expired." }))
          }
        })
    }
  }, [checked, currentUserProfile, dispatch, hash, navigate, pathname, search])

  // run once
  React.useEffect(() => {
    fetchUserProfile()
  }, [fetchUserProfile])

  return <>{props.children}</>
}
