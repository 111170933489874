import * as React from "react"

import { useElementSize } from "usehooks-ts"

import { RenderJobAsset } from "@trimmr/trimmr-lib/types/RenderJobAsset"
import { defaultTextStyle } from "@trimmr/trimmr-lib/types/TextStyle"

import CloseIcon from "@mui/icons-material/Close"
import { Box, Stack, Typography } from "@mui/material"

import { SubtitleWithWordTimings } from "../../../../components/CreateRenderJobModal/RemotionPreview/SubtitleWithWordTimings"
import { horizontalAlignmentToFlex } from "../../../../components/CreateRenderJobModal/RemotionPreview/horizontalAlignmentToFlex"

export const AbsolutelyPositionedRenderJobAsset: React.FC<{
  renderJobAsset: RenderJobAsset
  videoResolutionWidth: number
}> = ({ videoResolutionWidth, renderJobAsset }) => {
  const { xPosition, yPosition, xScale, yScale, asset } = renderJobAsset
  const [ref, { width }] = useElementSize()
  const {
    originalWidth,
    originalHeight,
    assetUrl,
    type,
    name = "",
    textStyle = defaultTextStyle,
    thumbnailUrl,
  } = asset || {
    videoResolutionWidth: videoResolutionWidth,
    originalWidth: 1,
    originalHeight: 1,
  }

  const currentRenderScale = width / videoResolutionWidth

  if (type === "TEXT") {
    return (
      <Box style={{ position: "absolute", inset: 0 }} ref={ref}>
        <Box
          style={{
            position: "absolute",
            left: xPosition * currentRenderScale,
            top: yPosition * currentRenderScale,

            transformOrigin: "top left",
            transform: `scale(${currentRenderScale}, ${currentRenderScale})`,
          }}
        >
          <Stack
            direction="column"
            alignItems="stretch"
            justifyContent={
              horizontalAlignmentToFlex[textStyle.horizontalCaptionAlignment]
            }
          >
            {name.split("\n").map((lineText, i) => (
              <SubtitleWithWordTimings
                key={`${lineText}${i}`}
                frame={0}
                noContainer
                useTextPreformattedSizing
                srtWithWordTimings={[
                  {
                    index: 0,
                    start: 0,
                    end: 1,
                    words: lineText.split(" ").map((word, index) => ({
                      uuid: `${word}${index}`,
                      text: word,
                      start: 0,
                    })),
                  },
                ]}
                textStyleAttributes={textStyle}
                paddingVerticalPx={0}
                paddingHorizontalPx={0}
                startSeconds={1}
                endSeconds={1}
                backgroundColor={textStyle.backgroundColor}
              />
            ))}
          </Stack>
        </Box>
      </Box>
    )
  }

  return (
    <Box style={{ position: "absolute", inset: 0 }} ref={ref}>
      <Box
        style={{
          position: "absolute",
          left: xPosition * currentRenderScale,
          top: yPosition * currentRenderScale,

          width: originalWidth * xScale * currentRenderScale,
          height: originalHeight * yScale * currentRenderScale,
        }}
      >
        <Box
          style={{
            position: "relative",
            width: "100%",
            height: "100%",

            border: "0px solid rgba(0,0,0,0)",
            transformOrigin: "top left",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              position: "absolute",
              inset: 0,
              overflow: "hidden",
              backgroundColor: !assetUrl
                ? "rgba(255,255,255,0.75)"
                : "transparent",
            }}
          >
            {!assetUrl && (
              <>
                <CloseIcon
                  sx={{ mb: 2 }}
                  style={{ width: "50%", height: "50%", color: "red" }}
                />

                <Typography
                  variant="subtitle2"
                  style={{ color: "red" }}
                  fontSize="5rem"
                >
                  Failed to locate asset
                </Typography>
              </>
            )}
          </Box>

          {assetUrl && (
            <img
              src={type === "IMAGE" ? assetUrl : thumbnailUrl || ""}
              style={{
                backgroundImage: "/img/home-bg-thumb.jpg",
                backgroundSize: "cover",
                inset: 0,
                pointerEvents: "none",
                position: "absolute",
                width: "100%",
                height: "100%",
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}
