import { BaseVideoSize, BaseVideoVerticalAlignment } from '../../types/Preset'

export const calculateBaseVideoPositionAndScale = (
  baseVideoSize: BaseVideoSize,
  baseVideoVerticalAlignment: BaseVideoVerticalAlignment,
  resolutionWidth: number,
  resolutionHeight: number,
  importJobDimensions: { width: number; height: number }
) => {
  let verticalScale: number | null = null
  // prettier-ignore
  switch (baseVideoSize) {
  case 'half-height-vertical':
    verticalScale = 0.5
    break
  case 'one-third-height-vertical':
    verticalScale = 0.34
    break
  case 'two-thirds-height-vertical':
    verticalScale = 0.67
    break
  }

  let newBaseVideoScale =
    Number(resolutionWidth || 1) / importJobDimensions.width

  if (verticalScale !== null)
    newBaseVideoScale =
      (verticalScale * Number(resolutionHeight || 1)) /
      importJobDimensions.height

  let newXPosition =
    -(
      newBaseVideoScale * (importJobDimensions.width || 1) -
      Number(resolutionWidth || 1)
    ) / 2

  let newYPosition = 0

  // prettier-ignore
  switch (baseVideoSize) {
  case 'content-fit-horizontal':
  case 'one-third-height-vertical':
  case 'two-thirds-height-vertical':
  case 'half-height-vertical':
    switch (baseVideoVerticalAlignment) {
    case 'middle':
      newYPosition =
            -(
              newBaseVideoScale * (importJobDimensions.height || 1) -
              Number(resolutionHeight || 1)
            ) / 2

      break

    case 'bottom':
      newYPosition =
            Number(resolutionHeight || 1) -
            newBaseVideoScale * (importJobDimensions.height || 1)
      break
    }
    break
  case 'content-fit-vertical':
  default:
    newBaseVideoScale = resolutionHeight / importJobDimensions.height
    newXPosition =
        -(
          newBaseVideoScale * (importJobDimensions.width || 1) -
          resolutionWidth
        ) / 2

    newYPosition = 0
    break
  }

  return {
    baseVideoXPosition: newXPosition,
    baseVideoYPosition: newYPosition,
    baseVideoXScale: newBaseVideoScale,
    baseVideoYScale: newBaseVideoScale,
  }
}
