import { API_BASE_URL } from "../../consts"
import axios from "../axios"

export default function getCaptionsWithWordTimings(
  importJobUuid: string,
  params: {
    startTime?: number
    endTime?: number
    filename?: string
  }
) {
  return axios
    .post(
      `${API_BASE_URL}/import-jobs/${importJobUuid}/get-captions-with-word-timings`,
      params
    )
    .then((response) => response.data)
}
