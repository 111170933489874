import { PropsWithChildren } from "react"
import { useNavigate } from "react-router"

import Link from "@mui/material/Link"

export default function RouterLink(
  props: PropsWithChildren<{
    underline?: "none" | "hover" | "always" | undefined
    href?: string
  }>
) {
  const { children, underline, href } = props
  const navigate = useNavigate()

  return (
    <Link
      onClick={(e) => {
        e.preventDefault()
        href && navigate(href)
      }}
      underline={underline}
      // color={color}
      color="textPrimary"
      href={href}
    >
      {children}
    </Link>
  )
}
