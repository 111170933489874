import { AxiosResponse } from "axios"

import { API_BASE_URL } from "../../consts"
import { ImportJob } from "../../types/ImportJob"
import axios from "../axios"

export interface SearchImportJobParams {
  page: number
  perPage: number
  userUuid?: string
  completed?: boolean
  email?: string
  failed?: boolean
  fileRemoved?: boolean
}

export default async function searchImportJobs(
  {
    page,
    perPage,
    userUuid,
    completed,
    email,
    failed,
    fileRemoved,
  }: SearchImportJobParams,
  signal?: AbortSignal
): Promise<{
  importJobs: ImportJob[]
  totalPages: number
  totalResults: number
}> {
  const params = new URLSearchParams()

  page && params.set("page", String(page))
  perPage && params.set("perPage", String(perPage))
  userUuid && params.set("userUuid", String(userUuid))
  completed && params.set("completed", String(completed))
  failed && params.set("failed", String(failed))
  email && params.set("email", String(email))
  fileRemoved === false && params.set("fileRemoved", "false")

  const response = await axios.get<void, AxiosResponse>(
    `${API_BASE_URL}/import-jobs?${params.toString()}`,
    { signal }
  )

  return {
    importJobs: response.data as ImportJob[],
    totalPages: Number(response.headers["x-total-pages"]),
    totalResults: Number(response.headers["x-total-results"]),
  }
}
