import { PropsWithChildren } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh"
import BookIcon from "@mui/icons-material/Book"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import MissedVideoCallIcon from "@mui/icons-material/MissedVideoCall"
import PermMediaIcon from "@mui/icons-material/PermMedia"
import PersonIcon from "@mui/icons-material/Person"
import Box from "@mui/material/Box"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import ListSubheader from "@mui/material/ListSubheader"

const NavListItem = (props: PropsWithChildren<{ path: string }>) => {
  const { path } = props

  const navigate = useNavigate()
  const location = useLocation()

  return (
    <ListItem
      selected={location.pathname.startsWith(path)}
      button
      onClick={() => navigate(path)}
    >
      {props.children}
    </ListItem>
  )
}

export const AdminPageList = () => {
  return (
    <Box>
      <ListSubheader style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
        Admin
      </ListSubheader>

      <NavListItem path="/app/admin/users">
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </NavListItem>

      <NavListItem path="/app/admin/presets">
        <ListItemIcon>
          <AutoFixHighIcon />
        </ListItemIcon>
        <ListItemText primary="Presets" />
      </NavListItem>
      <NavListItem path="/app/admin/assets">
        <ListItemIcon>
          <PermMediaIcon />
        </ListItemIcon>
        <ListItemText primary="Assets" />
      </NavListItem>
      <NavListItem path="/app/admin/import-jobs">
        <ListItemIcon>
          <CloudDownloadIcon />
        </ListItemIcon>
        <ListItemText primary="Imports" />
      </NavListItem>
      <NavListItem path="/app/admin/render-jobs">
        <ListItemIcon>
          <MissedVideoCallIcon />
        </ListItemIcon>
        <ListItemText primary="Shorts" />
      </NavListItem>

      <NavListItem path="/app/admin/blogposts">
        <ListItemIcon>
          <BookIcon />
        </ListItemIcon>
        <ListItemText primary="Blog Posts" />
      </NavListItem>
    </Box>
  )
}

export default AdminPageList
