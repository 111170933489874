import { textShadowOptions } from './RenderJob'
import {
  HorizontalAlignmentOption,
  TextAnimationOption,
  VerticalAlignmentOption,
} from './RenderJobAsset'

export const defaultTextStyle: TextStyleAttributes = {
  fontColor: '#FFFFFF',
  fontName: 'AlfaSlabOne-Regular',
  strokeWidth: 20,
  strokeColor: '#000',

  verticalCaptionAlignment: 'middle',
  horizontalCaptionAlignment: 'center',
  fontSizePx: 80,
  oneWordAtATime: true,
  textHighlightColor: '#5FFF93',
  textShadow: textShadowOptions[0].value,
  textAnimation: 'none',
  textCapitalization: 'uppercase',
  backgroundColor: '#333',
}

export interface TextStyleAttributes {
  fontColor: string
  fontName: string
  strokeWidth: number
  strokeColor: string
  verticalCaptionAlignment: VerticalAlignmentOption
  horizontalCaptionAlignment: HorizontalAlignmentOption
  fontSizePx: number
  oneWordAtATime: boolean
  textHighlightColor: string
  textShadow: string
  textAnimation: TextAnimationOption
  textCapitalization: string
  backgroundColor: string
}
