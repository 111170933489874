import * as React from "react"
import { useDispatch } from "react-redux"

import { Preset } from "@trimmr/trimmr-lib/types/Preset"

import LoadingButton from "@mui/lab/LoadingButton"
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
} from "@mui/material"

import updatePreset from "../../../../api/presets/update"
import { useAppSelector } from "../../../../redux"
import { addSnackbar } from "../../../../redux/snackbars"

function EditPresetModal({
  preset,
  open,
  onSave,
  onClose,
}: {
  preset: Preset
  open: boolean
  onSave: () => void
  onClose: () => void
}) {
  const dispatch = useDispatch()
  const [saving, setSaving] = React.useState(false)
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)

  const [errorMessage, setErrorMessage] = React.useState("")
  const [presetName, setPresetName] = React.useState("")
  const [description, setDescription] = React.useState("")
  const [presetPublic, setPresetPublic] = React.useState(false)

  const [imgSrc, setImgSrc] = React.useState<string>("")
  const [selectedImage, setSelectedImage] = React.useState<Blob | null>(null)

  React.useEffect(() => {
    if (selectedImage) {
      const reader = new FileReader()
      reader.readAsDataURL(selectedImage)
      reader.onloadend = () => setImgSrc(reader.result as string)
    }
  }, [selectedImage])

  const handleReceivePreset = (preset: Preset) => {
    setPresetName(preset.name)
    setDescription(preset.description || "")
    setPresetPublic(preset.public)
    setImgSrc(preset.thumbnailUrl)
  }

  React.useEffect(() => {
    handleReceivePreset(preset)
  }, [preset])

  const handleSave = () => {
    setSaving(true)
    updatePreset(
      preset.uuid,
      {
        public: presetPublic,
        name: presetName,
        description,
      },
      selectedImage || undefined
    )
      .then(() => {
        onSave()
        setSaving(false)
        dispatch(addSnackbar({ text: "Preset updated." }))
      })
      .catch((err) => {
        console.error(err)
        setSaving(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message
        )
      })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="edit-preset-title"
      aria-describedby="edit-preset-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="edit-preset-dialog-title">Edit preset</DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        {errorMessage && (
          <Stack sx={{ width: "100%", mb: 2 }} spacing={2}>
            <Alert severity="error" onClose={() => setErrorMessage("")}>
              {errorMessage}
            </Alert>
          </Stack>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="presetName"
              required
              fullWidth
              id="presetName"
              value={presetName || ""}
              label="Preset Name"
              sx={{ mt: 1 }}
              onChange={(e) => {
                setPresetName(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="description"
              label="Description"
              name="description"
              multiline
              value={description}
              rows={5}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>

          {currentUserProfile && currentUserProfile.isAdmin && (
            <Grid item xs={12}>
              <FormControlLabel
                label="Is Public?"
                sx={{ color: "text.primary" }}
                control={
                  <Checkbox
                    checked={presetPublic}
                    onChange={(e) => {
                      setPresetPublic(e.target.checked)
                    }}
                  />
                }
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <label htmlFor="contained-button-file">
              <input
                style={{ display: "none" }}
                accept="image/*"
                id="contained-button-file"
                type="file"
                onChange={(e) =>
                  e.target.files && setSelectedImage(e.target.files[0])
                }
              />

              <Button
                fullWidth
                variant="contained"
                component="span"
                sx={{ mb: 1 }}
              >
                Upload Cover Image
              </Button>
            </label>

            {imgSrc && (
              <Box sx={{ mb: 3 }} maxWidth="600px">
                <img
                  src={imgSrc}
                  height="auto"
                  width="auto"
                  alt="Preview"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "300px",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          onClick={() => handleSave()}
          loading={saving}
          disabled={!presetName}
          color="primary"
          variant="contained"
        >
          Save
        </LoadingButton>
        <LoadingButton onClick={onClose}>Cancel</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default EditPresetModal
