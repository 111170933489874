import * as React from "react"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import Stack from "@mui/material/Stack"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import completeEmailConfirmation from "../../api/auth/confirmEmail"
import AppBar from "../../components/AppBar"
import Copyright from "../../components/Copyright"
import { addSnackbar } from "../../redux/snackbars"

export default function CompleteConfirmEmail() {
  const dispatch = useDispatch()
  const { search } = useLocation()
  const navigate = useNavigate()

  const [submitting, setSubmitting] = React.useState(true)

  const [errorMessage, setErrorMessage] = React.useState("")
  const [messageSeverity, setMessageSeverity] = React.useState<
    "error" | "warning"
  >("error")

  const handleCompleteEmailConfirmation = React.useCallback(() => {
    setSubmitting(true)
    const params = new URLSearchParams(search)
    const token = params.get("token")
    const email = params.get("email")

    completeEmailConfirmation(email || "", token || "")
      .then(() => {
        setSubmitting(false)

        dispatch(
          addSnackbar({
            id: "emailConfirmSuccess",
            text: "Email confirmation successful.",
          })
        )

        navigate("/app/account/video-creator")
      })
      .catch((err) => {
        console.error(err)
        setSubmitting(false)
        setMessageSeverity("error")
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message
        )
      })
  }, [dispatch, navigate, search])

  React.useEffect(() => {
    handleCompleteEmailConfirmation()
  }, [handleCompleteEmailConfirmation])

  return (
    <>
      <AppBar title="Trimmr" />

      <Toolbar />

      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity={messageSeverity}>{errorMessage}</Alert>
        </Stack>
      )}

      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5" color="text.primary">
            Confirming email...
          </Typography>

          <Box sx={{ mt: 3 }}>
            {submitting && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Box>

        <Copyright sx={{ mt: 5 }} />
      </Container>
    </>
  )
}
