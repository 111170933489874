import { API_BASE_URL } from "../../consts"
import axios from "../axios"

export default function searchUsers({
  email,
  name,
  page,
  perPage,
  subscribed,
}: {
  email: string
  name: string
  page: number
  perPage: number
  subscribed?: boolean
}) {
  const params = new URLSearchParams()

  email && params.set("email", email)
  name && params.set("name", name)
  page && params.set("page", String(page))
  perPage && params.set("perPage", String(perPage))
  subscribed && params.set("subscribed", String(subscribed))

  return axios
    .get(`${API_BASE_URL}/users?${params.toString()}`)
    .then((response) => ({
      users: response.data,
      totalPages: Number(response.headers["x-total-pages"]),
      totalResults: Number(response.headers["x-total-results"]),
    }))
}
