import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export const infoAlertsEnabledSlice = createSlice({
  name: "infoAlertsEnabled",
  initialState: true,
  reducers: {
    setInfoAlertsEnabled: (_, action: PayloadAction<boolean>) => {
      return action.payload
    },
  },
})

export const { setInfoAlertsEnabled } = infoAlertsEnabledSlice.actions

export default infoAlertsEnabledSlice.reducer
