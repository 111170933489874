import { Suspense, lazy, useEffect, useState } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"

import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import { ThemeProvider } from "@mui/material/styles"

import ContactFab from "./components/ContactFab"
import { RemotionPreviewSwitcherContext } from "./components/CreateRenderJobModal/RemotionPreview/RemotionPreviewSwitcherManager"
import GlobalPopups from "./components/GlobalPopups"
import Snackbars from "./components/Snackbars"
import CouponCatcher from "./hoc/CouponCatcher"
import FetchUserProfile from "./hoc/FetchUserProfile"
import GoogleAnalyticsReporter from "./hoc/GoogleAnalyticsReporter"
import SSRSkipComponent from "./hoc/SSRSkipComponent"
// import AdAssets from './pages/AdAssets'
import { darkTheme, lightTheme } from "./lib/mdTheme"
import Account from "./pages/Account"
import APIKeys from "./pages/Account/pages/APIKeys"
import AccountUsage from "./pages/Account/pages/AccountUsage"
import AssetsManagement from "./pages/Account/pages/AssetsManagement"
import ContentIdeasManagement from "./pages/Account/pages/ContentIdeasManagement"
import DailyIdeasManagement from "./pages/Account/pages/DailyIdeasManagement"
import ImportJobsManagement from "./pages/Account/pages/ImportJobsManagement"
import PresetsManagement from "./pages/Account/pages/PresetsManagement"
import Profile from "./pages/Account/pages/Profile"
import RenderJobsManagement from "./pages/Account/pages/RenderJobsManagement"
import AdminDashboard from "./pages/AdminDashboard"
import AdminBlogPostManagement from "./pages/AdminDashboard/pages/AdminBlogPostManagement"
import AdminEditUser from "./pages/AdminDashboard/pages/AdminEditUser"
import AdminUserManagement from "./pages/AdminDashboard/pages/AdminUserManagement"
// import Developers from "./pages/Developers"
import AuthorizeOauth2 from "./pages/AuthorizeOauth2"
import PostList from "./pages/Blog/pages/PostList"
import ShowPost from "./pages/Blog/pages/ShowPost"
import CompleteConfirmEmail from "./pages/CompleteConfirmEmail"
import CompletePasswordReset from "./pages/CompletePasswordReset"
import ConfirmEmail from "./pages/ConfirmEmail"
import Contact from "./pages/Contact"
import ForgotPassword from "./pages/ForgotPassword"
import Home from "./pages/Home"
import Privacy from "./pages/Privacy"
import SignIn from "./pages/SignIn"
import TermsOfUse from "./pages/TermsOfUse"
import ZapierLandingPage from "./pages/ZapierLandingPage"
import { useAppSelector } from "./redux"

const AdminEditBlogPost = lazy(
  () => import("./pages/AdminDashboard/pages/AdminEditBlogPost")
)
const VideoGenerator = lazy(
  () => import("./pages/Account/pages/VideoGenerator")
)
const SignUp = lazy(() => import("./pages/SignUp"))
const ChangePassword = lazy(
  () => import("./pages/Account/pages/ChangePassword")
)
const AccountSubscriptions = lazy(
  () => import("./pages/Account/pages/AccountSubscriptions")
)

const Fallback = () => {
  return (
    <main style={{ height: "100%" }}>
      <Box
        sx={{ display: "flex", justifyContent: "center", height: "100%", p: 2 }}
      >
        <CircularProgress color="inherit" />
      </Box>
    </main>
  )
}

const FallbackRedirect = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate("/app")
  })
  return <Fallback />
}

export default function Router() {
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  const [currentlyOpenPreviewId, setCurrentlyOpenPreviewId] = useState("")

  return (
    <>
      <RemotionPreviewSwitcherContext.Provider
        value={{
          currentlyOpenPreviewId,
          setCurrentlyOpenPreviewId,
        }}
      >
        <FetchUserProfile>
          <GoogleAnalyticsReporter>
            <CouponCatcher>
              <ThemeProvider theme={darkModeEnabled ? darkTheme : lightTheme}>
                <Snackbars />
                <GlobalPopups />
                <Box
                  sx={{ backgroundColor: "background.default" }}
                  style={{ height: "100vh", overflowY: "auto" }}
                >
                  <ContactFab />
                  {/* <Suspense fallback={<Fallback />}> */}
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="app" element={<Home />} />
                    {/* <Route path="developers" element={<Developers />} /> */}
                    {/* <Route path="app/developers" element={<Developers />} /> */}
                    {/* <Route path="app/affiliates" element={<Affiliates />} /> */}
                    <Route path="app/zapier" element={<ZapierLandingPage />} />
                    {/* <Route path="app/ad-assets" element={<AdAssets />} /> */}
                    <Route path="app/signin" element={<SignIn />} />
                    <Route
                      path="app/signup"
                      element={
                        <SSRSkipComponent>
                          <Suspense fallback={<Fallback />}>
                            <SignUp />
                          </Suspense>
                        </SSRSkipComponent>
                      }
                    />
                    <Route path="app/contact" element={<Contact />} />
                    <Route path="app/privacy" element={<Privacy />} />
                    <Route path="app/terms" element={<TermsOfUse />} />
                    <Route
                      path="app/forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route
                      path="app/complete-password-reset"
                      element={<CompletePasswordReset />}
                    />
                    <Route
                      path="app/confirm-email"
                      element={<ConfirmEmail />}
                    />
                    <Route
                      path="app/complete-confirm-email"
                      element={<CompleteConfirmEmail />}
                    />

                    <Route
                      path="app/oauth2/authorize"
                      element={<AuthorizeOauth2 />}
                    />

                    <Route path="app/blog" element={<PostList />} />
                    <Route
                      path="app/blog/:blogPostUuid"
                      element={<ShowPost />}
                    />

                    <Route path="app/account/*" element={<Account />}>
                      <Route
                        path="video-creator"
                        element={
                          <SSRSkipComponent>
                            <Suspense fallback={<Fallback />}>
                              <VideoGenerator />
                            </Suspense>
                          </SSRSkipComponent>
                        }
                      />
                      <Route
                        path="usage"
                        element={
                          <SSRSkipComponent>
                            <Suspense fallback={<Fallback />}>
                              <AccountUsage />
                            </Suspense>
                          </SSRSkipComponent>
                        }
                      />
                      <Route path="profile" element={<Profile />} />
                      <Route
                        path="change-password"
                        element={
                          <SSRSkipComponent>
                            <Suspense fallback={<Fallback />}>
                              <ChangePassword />
                            </Suspense>
                          </SSRSkipComponent>
                        }
                      />
                      <Route
                        path="import-jobs"
                        element={<ImportJobsManagement />}
                      />
                      <Route path="api-keys" element={<APIKeys />} />
                      <Route
                        path="daily-ideas"
                        element={<DailyIdeasManagement />}
                      />
                      <Route path="assets" element={<AssetsManagement />} />
                      <Route
                        path="render-jobs"
                        element={<RenderJobsManagement />}
                      />

                      <Route path="presets" element={<PresetsManagement />} />
                      <Route
                        path="past-ideas"
                        element={<ContentIdeasManagement />}
                      />

                      <Route
                        path="subscription"
                        element={
                          <SSRSkipComponent>
                            <Suspense fallback={<Fallback />}>
                              <AccountSubscriptions />
                            </Suspense>
                          </SSRSkipComponent>
                        }
                      />
                      <Route
                        path="subscription/:status"
                        element={
                          <SSRSkipComponent>
                            <Suspense fallback={<Fallback />}>
                              <AccountSubscriptions />
                            </Suspense>
                          </SSRSkipComponent>
                        }
                      />

                      <Route path="*" element={<div />} />
                    </Route>

                    <Route path="app/admin/*" element={<AdminDashboard />}>
                      <Route path="users" element={<AdminUserManagement />} />
                      <Route
                        path="users/:userUuid"
                        element={<AdminEditUser />}
                      />
                      <Route
                        path="blogposts"
                        element={<AdminBlogPostManagement />}
                      />
                      <Route
                        path="blogposts/:blogPostUuid"
                        element={
                          <SSRSkipComponent>
                            <Suspense fallback={<Fallback />}>
                              <AdminEditBlogPost />
                            </Suspense>
                          </SSRSkipComponent>
                        }
                      />
                      <Route
                        path="import-jobs"
                        element={<ImportJobsManagement asAdmin />}
                      />
                      <Route
                        path="past-ideas"
                        element={<ContentIdeasManagement asAdmin />}
                      />
                      <Route
                        path="assets"
                        element={<AssetsManagement asAdmin />}
                      />
                      <Route
                        path="render-jobs"
                        element={<RenderJobsManagement asAdmin />}
                      />
                      <Route
                        path="presets"
                        element={<PresetsManagement asAdmin />}
                      />

                      <Route path="*" element={<div />} />
                    </Route>

                    <Route path="*" element={<FallbackRedirect />} />
                  </Routes>
                  {/* </Suspense> */}
                </Box>
              </ThemeProvider>
            </CouponCatcher>
          </GoogleAnalyticsReporter>
        </FetchUserProfile>
      </RemotionPreviewSwitcherContext.Provider>
    </>
  )
}
