import * as React from "react"

import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { CircularProgress, Grid, Pagination, Typography } from "@mui/material"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"

import searchContentIdeas from "../../api/contentideas/search"
import { ContentIdea } from "../../types/ContentIdea"
import { ImportJob } from "../../types/ImportJob"
import IdeaCard from "../NewContentIdeasForm/IdeaCard"

const PER_PAGE = 3

const PastIdeasExplorer: React.FC<{
  importJob?: ImportJob
  onSelectIdea?: (idea: ContentIdea) => void
  hasLargeContainer?: boolean
  asAdmin?: boolean
  mostRecentIdeaUuid?: string // used to refresh list when new ideas are generated
}> = ({
  importJob,
  onSelectIdea,
  hasLargeContainer,
  asAdmin,
  mostRecentIdeaUuid,
}) => {
  const [errorMessage, setErrorMessage] = React.useState("")
  const [page, setPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(0)

  const importJobUuid = importJob?.uuid

  const fetchPastContentIdeas = async ({ queryKey }) => {
    const page = queryKey[1]

    try {
      const { contentIdeas, totalPages } = await searchContentIdeas({
        page,
        perPage: PER_PAGE,
        importJobUuid: importJob?.uuid,
        asAdmin,
      })
      setTotalPages(totalPages)
      setErrorMessage("")
      return contentIdeas
    } catch (errO) {
      const err = errO as AxiosError
      console.error(err)
      const errMessage =
        (err.response && err.response.data && err.response.data.error) ||
        err.message
      throw new Error(errMessage)
    }
  }

  const {
    data: ideas,
    error: fetchContentIdeasError,
    isFetching: loading,
  } = useQuery<ContentIdea[], Error>({
    queryKey: ["pastIdeas", page, mostRecentIdeaUuid, importJobUuid],
    queryFn: fetchPastContentIdeas,
  })

  React.useEffect(() => {
    if (fetchContentIdeasError) setErrorMessage(fetchContentIdeasError.message)
  }, [fetchContentIdeasError])

  return (
    <>
      {errorMessage && (
        <Stack sx={{ width: "100%", mb: 2 }} spacing={2}>
          <Alert severity="error" onClose={() => setErrorMessage("")}>
            {errorMessage}
          </Alert>
        </Stack>
      )}

      {ideas && (
        <>
          {totalPages > 1 && (
            <Pagination
              count={totalPages}
              page={page}
              onChange={(_, v) => {
                setPage(v)
              }}
              sx={{ mb: 2 }}
            />
          )}

          {loading && (
            <Box sx={{ display: "flex", justifyContent: "center", pb: 2 }}>
              <CircularProgress />
            </Box>
          )}

          {!loading && (
            <Grid container spacing={2} columns={12}>
              {ideas && ideas.length === 0 && (
                <Grid item xs={12}>
                  <Typography variant="body1">No ideas created yet</Typography>
                </Grid>
              )}
              {ideas.map((idea) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={hasLargeContainer ? 4 : 6}
                  lg={hasLargeContainer ? 3 : 4}
                  xl={hasLargeContainer ? 3 : 4}
                  key={idea.uuid}
                >
                  <IdeaCard
                    importJob={importJob}
                    idea={idea}
                    onEditIdea={onSelectIdea}
                    height="100%"
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    </>
  )
}

export default PastIdeasExplorer
