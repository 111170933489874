import * as React from "react"

import FilterContainer from "packages/frontend/src/components/FilterContainer"

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

// import searchContentIdeas from "../../../../api/contentideas/search"
import ConditionalInfoAlert from "../../../../components/ConditionalInfoAlert"
import HeadingContainer from "../../../../components/HeadingContainer"
import PastIdeasExplorer from "../../../../components/PastIdeasExplorer"

// import { ContentIdea } from "../../../../types/ContentIdea"

// let queryTimeout: NodeJS.Timeout
// const PER_PAGE = 10

interface Props {
  asAdmin?: boolean
}

const ContentIdeasManagement: React.FC<Props> = ({ asAdmin }) => {
  // const currentUserProfile = useAppSelector((state) => state.currentUserProfile)

  const [status, setStatus] = React.useState("any")
  const [email, setEmail] = React.useState("")
  const [contentIdeasLoading] = React.useState(false)
  // const [contentIdeas, setContentIdeas] = React.useState<ContentIdea[] | null>(
  //   null
  // )
  // const [page, setPage] = React.useState(1)
  // const [totalPages, setTotalPages] = React.useState(0)
  const [totalContentIdeas] = React.useState(0)

  const [errorMessage, setErrorMessage] = React.useState("")

  // const handleSearchContentIdeas = React.useCallback(() => {
  //   const params = {
  //     page,
  //     perPage: PER_PAGE,
  //     email,
  //     userUuid: asAdmin
  //       ? ""
  //       : currentUserProfile
  //       ? currentUserProfile.uuid
  //       : "",
  //   }

  //   searchContentIdeas(params)
  //     .then(({ contentIdeas: results, totalPages, totalResults }) => {
  //       setContentIdeas(results)
  //       setTotalPages(totalPages)
  //       setTotalContentIdeas(totalResults)
  //       setContentIdeasLoading(false)
  //     })
  //     .catch((err) => {
  //       console.error(err)
  //       setContentIdeasLoading(false)
  //       setErrorMessage(
  //         (err.response && err.response.data && err.response.data.error) ||
  //           err.message
  //       )
  //     })
  // }, [page, email, asAdmin, currentUserProfile])

  // React.useEffect(() => {
  //   setContentIdeasLoading(true)
  //   clearTimeout(queryTimeout)
  //   queryTimeout = setTimeout(() => {
  //     handleSearchContentIdeas()
  //   }, 300)
  // }, [page, handleSearchContentIdeas])

  return (
    <div>
      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error" onClose={() => setErrorMessage("")}>
            {errorMessage}
          </Alert>
        </Stack>
      )}

      <HeadingContainer>
        <Typography component="h1" variant="h6" color="textPrimary" noWrap>
          Past Ideas
        </Typography>
      </HeadingContainer>

      {asAdmin && (
        <FilterContainer>
          <FormControl style={{ width: "150px" }}>
            <InputLabel size="small" id="status">
              Status
            </InputLabel>
            <Select
              fullWidth
              size="small"
              labelId="status"
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value="any">
                <Typography variant="body1">Any</Typography>
              </MenuItem>
              <MenuItem value="failed">
                <Typography variant="body1">Failed</Typography>
              </MenuItem>
              <MenuItem value="completed">
                <Typography variant="body1">Completed</Typography>
              </MenuItem>
            </Select>
          </FormControl>

          <TextField
            size="small"
            value={email || ""}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="off"
            label="Email"
            variant="outlined"
          />
        </FilterContainer>
      )}

      <Divider sx={{ mb: 2 }} />

      <Container maxWidth="md">
        <ConditionalInfoAlert>
          Here are all the videos you've imported so far. You can create a new
          short video from one by hitting "Open in Creator".
        </ConditionalInfoAlert>

        {contentIdeasLoading && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress color="inherit" />
          </Box>
        )}
        {!contentIdeasLoading && (
          <>
            <Paper sx={{ mb: 2, p: 2 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body1" color="textSecondary">
                  {totalContentIdeas} content ideas
                </Typography>

                {/* <Pagination
                  count={totalPages}
                  page={page}
                  onChange={(_, v) => setPage(v)}
                /> */}
              </Stack>

              <Box sx={{ mt: 2 }}>
                <PastIdeasExplorer asAdmin={asAdmin} />
              </Box>
            </Paper>

            {/* <Grid container columns={12} spacing={2} alignItems="stretch">
            </Grid> */}
          </>
        )}
      </Container>
    </div>
  )
}

export default ContentIdeasManagement
