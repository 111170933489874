export default function nameForUser(user: {
  firstname?: string
  lastname?: string
}) {
  if (user.firstname && user.lastname)
    return `${user.firstname} ${user.lastname}`
  if (user.firstname) return user.firstname
  if (user.lastname) return user.lastname
  return ""
}
