import { API_BASE_URL } from "../../consts"
import axios from "../axios"

export default function updatePreset(
  presetUuid: string,
  params: {
    name?: string
    description?: string
    public?: boolean
  },
  thumbnail?: Blob
) {
  const formData = new FormData()
  if (thumbnail) formData.append("file", thumbnail, "thumbnail.jpg")
  if (params.name) formData.append("name", params.name)
  if (params.description || params.description === "")
    formData.append("description", params.description)
  if (params.public === true) formData.append("public", "true")
  if (params.public === false) formData.append("public", "false")

  return axios
    .patch(`${API_BASE_URL}/presets/${presetUuid}`, formData)
    .then((response) => response.data)
}
