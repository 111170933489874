import { hydrateRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"

import { CacheProvider } from "@emotion/react"

import CssBaseline from "@mui/material/CssBaseline"

import { App } from "./App"
import createEmotionCache from "./createEmotionCache"
import GoogleAnalyticsReporter from "./hoc/GoogleAnalyticsReporter"
import window from "./lib/window"
import { createStore } from "./redux"
import reportWebVitals from "./reportWebVitals"

const cache = createEmotionCache()

const renderMethod = hydrateRoot

const serverState = window.preloadedReduxState
const store = createStore(serverState)

const root = document.getElementById("root")

if (!root) throw new Error("Could not find document root to hydrate")

renderMethod(
  root,
  <BrowserRouter>
    <CacheProvider value={cache}>
      <CssBaseline />
      <Provider store={store} serverState={serverState}>
        <GoogleAnalyticsReporter>
          <App />
        </GoogleAnalyticsReporter>
      </Provider>
    </CacheProvider>
  </BrowserRouter>
)

reportWebVitals()
