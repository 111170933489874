import React from "react"

import { text } from "body-parser"

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"

import { useAppDispatch, useAppSelector } from "../../redux"
import { removePopup } from "../../redux/popups"

const GlobalPopups: React.FC = () => {
  const popups = useAppSelector((state) => state.popups)
  const dispatch = useAppDispatch()

  const handleClick = React.useCallback(
    (popup) => {
      dispatch(removePopup(popup.id))
    },
    [dispatch]
  )
  return (
    <>
      {popups.map((popup) => (
        <Dialog open fullWidth maxWidth="sm" key={popup.id}>
          <DialogTitle>{popup.title}</DialogTitle>
          <DialogContent>{popup.text}</DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              disabled={!text}
              onClick={() => handleClick(popup)}
            >
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      ))}
    </>
  )
}

export default GlobalPopups
