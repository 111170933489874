import updateImportJob from "../../api/importJobs/update"
import { ImportJob } from "../../types/ImportJob"
import loadVideoAndReturnDimensions from "../video/loadVideoAndReturnDimensions"

/**
 * This function is weird but I want it to be convenient:
 * Pass import job through it and it will return
 * the same object with the originalHeight and originalWidth
 * set and saved if it wasn't hydrated already.
 */
export default async function getVideoDimensionsAndSyncToImportJob(
  importJob: ImportJob
): Promise<{ originalHeight: number; originalWidth: number }> {
  const importJobHadHeight = importJob.originalHeight

  if (!importJobHadHeight) {
    const dimensions = await loadVideoAndReturnDimensions(importJob.videoURL)
    await updateImportJob(importJob.uuid, {
      originalWidth: dimensions.width,
      originalHeight: dimensions.height,
    })
    return {
      ...importJob,
      originalWidth: dimensions.width,
      originalHeight: dimensions.height,
    }
  }

  return importJob
}
