import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom"

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { Chip, Paper, useMediaQuery } from "@mui/material"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { Theme, ThemeProvider } from "@mui/material/styles"

import AppBar from "../../components/AppBar"
import BlackButton from "../../components/BlackButton"
import FaqAccordion from "../../components/FaqAccordion"
import Footer from "../../components/Footer"
import FullPageContentContainer from "../../components/FullPageContentContainer"
import SwitchableSubscriptionItem from "../../components/SubscriptionItem/SwitchableSubscriptionItem"
import DeferredYoutubePreview from "../../components/YoutubePreview/DeferredYoutubePreview"
import { darkTheme } from "../../lib/mdTheme"
import { useAppSelector } from "../../redux"
import features from "./features"

export default function Home() {
  const navigate = useNavigate()
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)
  // const [youtubeURL, setYoutubeURL] = useState("")

  const isLargeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("sm")
  )

  // const handleCreateImportJob = () => {
  //   navigate("/app/signup")
  // }

  const videoAnnotationFontSize = isLargeScreen ? "h5" : "h6"

  return (
    <ThemeProvider theme={darkTheme}>
      <Helmet titleTemplate="%s">
        <title>trimmr</title>
        <meta
          name="description"
          content="trimmr is the best shorts generator."
        />
      </Helmet>

      <Box className="home-parallax dark-background-img">
        <AppBar invert hideDarkModePicker hideTitle />
        <Box className="hero" sx={{ pb: 5 }}>
          <Toolbar />

          <FullPageContentContainer
            minHeight="auto"
            maxWidth="lg"
            sx={{ pt: 0 }}
          >
            <Box sx={{ px: 5, py: 10, pb: 5 }}>
              <Typography
                variant="h3"
                color="textPrimary"
                align="center"
                sx={{ mt: 4, mb: 1 }}
              >
                Turn your <b style={{ textDecoration: "underline" }}>long</b>{" "}
                videos into{" "}
                <b style={{ textDecoration: "underline" }}>shorts</b>
              </Typography>

              <Typography
                variant="h4"
                color="textPrimary"
                align="center"
                sx={{ mb: 10 }}
              >
                Repurpose long-form content using automation and AI
              </Typography>

              <Stack direction="row" spacing={2} justifyContent="center">
                <BlackButton
                  variant="contained"
                  onClick={() =>
                    currentUserProfile
                      ? navigate("/app/account/video-creator")
                      : navigate("/app/signup")
                  }
                  size="large"
                >
                  {currentUserProfile ? "open editor" : "sign up for free"}{" "}
                  <ArrowForwardIosIcon sx={{ ml: 1 }} />
                </BlackButton>
              </Stack>
            </Box>

            {/* {!currentUserProfile && (
              <Grid container sx={{ mb: 10 }} justifyContent="center">
                <Grid item xs={12} sm={8} md={6}>
                  <AccordionSection
                    icon={<VideoCallIcon />}
                    title="Import New Video"
                    open
                  >
                    <NewImportJobForm
                      youtubeURL={youtubeURL}
                      setYoutubeURL={setYoutubeURL}
                      languageOption={[
                        { id: "en", name: "English", autogenerated: false },
                      ]}
                      isSaving={false}
                      handleCreateImportJob={handleCreateImportJob}
                      waitForLanguageToLoad={false}
                    />

                    {youtubeURL && (
                      <Box sx={{ mt: 2 }}>
                        <YoutubePreview
                          youtubeURL={cleanYoutubeUrl(youtubeURL)}
                        />
                      </Box>
                    )}
                  </AccordionSection>
                </Grid>
              </Grid>
            )} */}

            <Container
              maxWidth="lg"
              disableGutters
              sx={{
                mt: 2,
              }}
            >
              <Grid
                container
                columns={12}
                sx={{ alignItems: "center" }}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sx={{ p: 2, pt: 0, pb: 4 }}
                  display="flex"
                  alignSelf="stretch"
                  justifyContent="center"
                >
                  <Typography
                    variant={videoAnnotationFontSize}
                    color="textPrimary"
                    align="center"
                  >
                    Trimmr creates videos with engaging graphics on autopilot.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ my: 5 }} />
                </Grid>

                <Grid
                  item
                  xs={6}
                  sm={3.5}
                  display="flex"
                  alignSelf="stretch"
                  justifyContent="center"
                >
                  <Stack
                    direction="column"
                    justifyContent="space-around"
                    spacing={2}
                    flexGrow={1}
                  >
                    <Typography
                      variant={videoAnnotationFontSize}
                      color="textPrimary"
                      align="right"
                    >
                      <b>AI-automated</b> curation of short-form video ideas
                      that repurpose your content.
                    </Typography>
                    <Typography
                      variant={videoAnnotationFontSize}
                      color="textPrimary"
                      align="right"
                    >
                      <b>Leverage trends in viewer retention</b> with engaging
                      overlays and customizable presets.
                    </Typography>
                  </Stack>
                </Grid>

                {/* pt: 2 for better video/text vertical alignment by offsetting text beneath */}
                <Grid item xs={6} sm={5} sx={{ pt: 2 }}>
                  <MadeWithTrimmrVideo
                    maxWidth="100%"
                    src={`https://trimmr-cdn.s3.amazonaws.com/home_video.mp4`}
                    poster={`https://trimmr-cdn.s3.amazonaws.com/home-video.jpg`}
                  />

                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    align="center"
                  >
                    trimmr is not affiliated with this channel.
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={3.5}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignSelf: "stretch",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant={videoAnnotationFontSize}
                    color="textPrimary"
                    align="left"
                    sx={{ py: 4 }}
                  >
                    <b>Automated captions</b> and a powerful{" "}
                    <b>web-based editor</b>.
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </FullPageContentContainer>
        </Box>

        <Container maxWidth="lg">
          <Divider sx={{ my: 10, width: "100%", mt: 0 }} />

          <Stack direction="row" justifyContent="center" sx={{ mb: 10 }}>
            <ThemeProvider theme={darkTheme}>
              <Paper
                sx={{
                  p: 2,
                  mx: 2,
                  borderRadius: "16px",
                  width: "100%",
                  border: "none",
                  background: "none",
                  boxShadow: "none",
                }}
              >
                <Stack
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={4}
                >
                  <Stack
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    gap="2rem"
                    width="100%"
                  >
                    <img
                      alt="Zapier logo"
                      src={"/img/zapier-logo_white.svg"}
                      style={{ height: "3rem" }}
                    />

                    <Chip
                      clickable
                      // icon={<AttachMoneyIcon />}
                      label="Learn more"
                      style={{
                        fontSize: "1rem",
                        borderWidth: "3px",
                      }}
                      color="info"
                      onClick={() => navigate("/app/zapier")}
                    />
                  </Stack>
                  <Typography variant="body1" fontSize="1.5rem">
                    Zapier allows you to put your workflows on autopilot, and is
                    perfect for automating Trimmr. The possibilities and
                    integrations are limitless.
                  </Typography>

                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-ignore */}
                  <zapier-zap-templates
                    style={{ alignSelf: "stretch" }}
                    sign-up-email={currentUserProfile?.email || ""}
                    client-id="sf00LohWSl1qgld5vW3rrEKdLQa2rDEfd89r3pgp"
                    theme={"dark"}
                    apps="trimmr"
                    create-without-template="hide"
                    limit={5}
                    use-this-zap="show"
                  />
                </Stack>
              </Paper>
            </ThemeProvider>
          </Stack>

          <Divider sx={{ my: 10, width: "100%", mt: 0 }} />

          <HeadTwoThirds>
            <Typography variant="h1" color="white">
              trimmr
            </Typography>
            <Typography variant="h4" color="text.secondary" sx={{ mb: 1 }}>
              /ˈtrimer/ (TRIHM-uhr) ○ noun
            </Typography>
            <Typography variant="h5" color="white">
              An AI-powered application that shortens long videos into shareable
              clips by identifying the most interesting or relevant segments of
              a video. It helps content creators and marketers produce viral
              videos that capture viewers' attention with quality editing.
            </Typography>
          </HeadTwoThirds>

          <Divider sx={{ my: 10, width: "100%" }} />

          <Grid container columns={12} spacing={4}>
            {features.features.map((feature) => {
              return (
                <Grid item xs={6} sm={4} key={feature.name}>
                  {feature.Icon && (
                    <feature.Icon
                      sx={{
                        fontSize: "4rem",
                        color: "text.primary",
                        mb: 1,
                        ml: -0.5,
                      }}
                    />
                  )}

                  <Typography color="textPrimary" variant="h5" sx={{ mb: 1 }}>
                    {feature.name}
                  </Typography>
                  <Typography color="textPrimary" variant="body1">
                    {feature.description}
                  </Typography>
                </Grid>
              )
            })}
          </Grid>

          <Divider sx={{ my: 10, width: "100%" }} />

          <HeadTwoThirds sx={{ mb: 10 }}>
            <Typography variant="h2" color="textPrimary" align="left">
              Pricing
            </Typography>
            <Typography variant="h4" color="textSecondary" align="left">
              Preview all premium features with a free account
            </Typography>
          </HeadTwoThirds>

          <Grid
            container
            columns={12}
            spacing={2}
            flexDirection="row"
            justifyContent="center"
            alignItems="stretch"
          >
            <Grid item xs={12} sm={4}>
              <Stack direction="row" alignItems="stretch" height="100%">
                <SwitchableSubscriptionItem
                  variant="home"
                  plan="Starter"
                  tagline="A low-cost option for new users, unlocks all premium features."
                  onClickMonthly={() => navigate("/app/account/subscription")}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4}>
              {/* for content fill */}
              <Stack direction="row" alignItems="stretch" height="100%">
                <SwitchableSubscriptionItem
                  variant="home"
                  plan="Premium"
                  tagline="Use all features with increased limits for larger channels."
                  onClickAnnual={() =>
                    currentUserProfile
                      ? navigate("/app/account/subscription")
                      : navigate("/app/signup")
                  }
                  onClickMonthly={() =>
                    currentUserProfile
                      ? navigate("/app/account/subscription")
                      : navigate("/app/signup")
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Stack direction="row" alignItems="stretch" height="100%">
                <SwitchableSubscriptionItem
                  variant="home"
                  plan="Unlimited"
                  tagline="Enables high-volume throughput for dedicated power-users."
                  onClickAnnual={() =>
                    currentUserProfile
                      ? navigate("/app/account/subscription")
                      : navigate("/app/signup")
                  }
                  onClickMonthly={() =>
                    currentUserProfile
                      ? navigate("/app/account/subscription")
                      : navigate("/app/signup")
                  }
                />
              </Stack>
            </Grid>
          </Grid>

          <Grid container spacing={2} columns={12} justifyContent="center">
            <Grid item xs={10} sm={8} md={6}>
              <Box display="flex" justifyContent="center" sx={{ pt: 5 }}>
                <BlackButton
                  variant="contained"
                  onClick={() =>
                    currentUserProfile
                      ? navigate("/app/account/video-creator")
                      : navigate("/app/signup")
                  }
                  size="large"
                  fullWidth
                >
                  {currentUserProfile ? "open editor" : "sign up for free"}{" "}
                  <ArrowForwardIosIcon sx={{ ml: 1 }} />
                </BlackButton>
              </Box>
            </Grid>
          </Grid>

          <Divider sx={{ my: 10, width: "100%" }} />

          <HeadTwoThirds>
            <Typography variant="h2" color="textPrimary" align="left">
              Hack your viewer's attention
            </Typography>

            <Typography
              variant="h4"
              color="textSecondary"
              align="left"
              sx={{ mb: 4 }}
            >
              Use viral and hypnotizing clips to capture your audience
            </Typography>

            <Typography variant="h6" color="white" sx={{ mb: 5 }}>
              Take advantage of our unique 'attention bait' templates. This
              unlocks an entirely new way to engage, and capture the attention
              of your audience or even new users.
            </Typography>

            <Typography variant="h6" color="white" sx={{ mb: 5 }}>
              Using trimmr AI's artificial intelligence algorithm, you will now
              be able to create attention bait videos with a simple click.
              Utilize our extensive library of videos and presets to customize
              the video to your exact needs.
            </Typography>

            <Typography variant="h6" color="white" sx={{ mb: 5 }}>
              Attention bait videos are the best way to engage with new users.
              It breaks up the mindless doom scrolling with engaging and unique
              content. Although they are focusing on the Subway Surfer or Temple
              Run video, they are absorbing the content.
            </Typography>
          </HeadTwoThirds>

          <Divider sx={{ my: 10, width: "100%" }} />
          <FaqAccordion
            faqs={[
              {
                question:
                  "How does Trimmr streamline short-form content creation?",
                answer:
                  "Trimmr revolutionizes short-form content creation by leveraging ChatGPT to select compelling segments from videos. It simplifies editing with automated captioning and cloud-based rendering. Our intuitive browser-based editor lets you overlay graphics and other videos, which can be saved as custom presets. Our library of presets is crafted to engage users immersed in scrolling through content, offering a refreshing and captivating experience that encourages content consumption.",
              },
              {
                question: "What video formats can I upload to Trimmr?",
                answer:
                  "Trimmr supports a range of formats for graphical overlays, including MP4, MOV, AVI, MKV, and WEBM. For the primary video content, Trimmr currently accepts videos imported via YouTube URLs, providing a seamless starting point for your creative process.",
              },
              {
                question: "Which languages does Trimmr support for captioning?",
                answer:
                  "Trimmr harnesses YouTube's automatic transcription, which supports a wide array of languages, to import captions for videos. This means virtually all languages are accommodated. Note that if English captions are unavailable, it may limit some functionalities, like automated idea generation, but you can still edit and render your video effectively.",
              },
              {
                question: "Can I customize captions in Trimmr?",
                answer:
                  "Absolutely! Trimmr automatically imports video captions from YouTube, which you can then fine-tune in our browser-based editor. Our advanced caption editor enables precise control over the timing of animations, down to individual words, with the added benefit of real-time editing capabilities during playback.",
              },
              {
                question: "What are 'attention bait presets' in Trimmr?",
                answer:
                  "In the realm of short-form content, mixing primary content with engaging side-content, like gameplay footage, is trending. Trimmr offers a video templating system with a variety of pre-designed presets to captivate audiences engaged in passive content scrolling. These presets are engineered to enhance viewer retention with visually striking and engaging elements. Furthermore, Trimmr empowers you to upload your own assets and design custom presets, offering limitless creative possibilities.",
              },
              {
                question: "What is the cost structure for using Trimmr?",
                answer:
                  "Trimmr offers a free account tier, allowing full access to all platform features, except for cloud rendering. For full video rendering and export capabilities, a paid subscription is necessary. This structure ensures you can explore and familiarize yourself with Trimmr's features before committing to a subscription.",
              },
              {
                question: "Does Trimmr have an enterprise solution?",
                answer:
                  "Yes, Trimmr provides tailored enterprise solutions. If you're looking for a customized plan that suits your specific needs, reach out to us through the contact form or email us at hello@trimmr.ai for a personalized consultation.",
              },
              {
                question: "How can I access support for Trimmr?",
                answer:
                  "For any assistance or inquiries, Trimmr's support team is readily available. You can contact us using the online form or directly via email at hello@trimmr.ai, and we'll ensure a prompt and helpful response.",
              },
            ]}
          />

          {/* <Divider sx={{ mt: 10, width: "100%" }} /> */}
          {/*
          <HeadTwoThirds sx={{ mb: 10 }}>
            <Typography variant="h2" color="textPrimary" align="left">
              Made with trimmr
            </Typography>

            <Typography variant="h4" color="textSecondary" align="left">
              See how trimmr instantly creates viral shorts from long-form
              videos
            </Typography>
          </HeadTwoThirds>

          <Box style={{ overflowX: "scroll" }}>
            <Grid container flexWrap="nowrap" style={{ gap: "2rem" }}>
              {data.map((data) => (

                <Grid
                  item
                  xs={12}
                  sm={6}
                  key={data.youtubeURL}
                  style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
                >
                  <MadeWithTrimmrVideo

                    src={`https://trimmr-cdn.s3.amazonaws.com/made-with-trimmr/${data.mp4}`}
                    poster={`https://trimmr-cdn.s3.amazonaws.com/made-with-trimmr/${data.poster}`}
                    title={data.category}
                    youtubeURL={data.youtubeURL}
                  />
                </Grid>
              ))}
            </Grid>
          </Box> */}
          {/*
          <Typography
            variant="subtitle2"
            color="textSecondary"
            align="center"
            sx={{ mt: 5 }}
          >
            trimmr is not affiliated with these creators.
          </Typography> */}

          {/* <Grid
            container
            spacing={2}
            columns={12}
            justifyContent="center"
            sx={{ mb: 5 }}
          >
            <Grid item xs={12} md={6}>
              <SubscriptionBenefits variant="home" />
            </Grid>
          </Grid> */}

          <Divider sx={{ my: 10, width: "100%" }} />

          <HeadTwoThirds>
            <Typography variant="h2" color="textPrimary" align="left">
              Why use trimmr
            </Typography>
            <Typography
              variant="h4"
              color="textSecondary"
              align="left"
              sx={{ mb: 5 }}
            >
              Youtube Shorts made easy
            </Typography>

            <Typography variant="h6" color="white" sx={{ mb: 5 }}>
              trimmr is a game-changer for content creators looking to maximize
              their reach across multiple platforms without compromising on
              quality or spending countless hours in the editing process.
            </Typography>
            <Typography variant="h6" color="white" sx={{ mb: 5 }}>
              Leverage the power of AI to transform long-form YouTube content
              into platform-tailored short-form videos for YouTube Shorts,
              Instagram Reels, TikTok, and Pinterest. With a highly customizable
              editor, you retain full creative control over your output.
            </Typography>
            <Typography variant="h6" color="white" sx={{ mb: 5 }}>
              Whether your focus is fashion, entertainment, podcasts, or beyond,
              trimmr caters to your unique needs, saving you significant time
              and effort. The process is easy, the results are high quality, and
              your content becomes more accessible than ever. With trimmr, you
              can truly make the most of your content and engage your audience
              wherever they are.
            </Typography>
          </HeadTwoThirds>

          <Box sx={{ mb: 20 }} />
        </Container>
        <Divider />
        <Footer />
      </Box>
    </ThemeProvider>
  )
}

function HeadTwoThirds({ children, sx = {}, alignRight = false }) {
  return (
    <Grid container columns={3} sx={sx}>
      {alignRight && <Grid item xs={0} md={1} />}
      <Grid item xs={3} md={2}>
        {children}
      </Grid>
    </Grid>
  )
}

const MadeWithTrimmrVideo: React.FC<{
  src: string
  poster: string
  title?: string
  maxWidth?: string
  youtubeURL?: string
}> = ({ src, title, maxWidth, poster, youtubeURL }) => {
  return (
    <Box style={{ width: "100%", maxWidth: maxWidth || "300px" }}>
      <video
        src={src}
        poster={poster}
        controls
        style={{ width: "100%", boxShadow: "0px 0px 10px black" }}
        preload="none"
      />
      {title && youtubeURL && (
        <Box sx={{ mt: 2 }}>
          <DeferredYoutubePreview
            alt={title}
            thumbnail={poster}
            youtubeURL={youtubeURL}
          />
        </Box>
      )}

      {title && (
        <Typography
          style={{
            color: "white",
            textAlign: "center",
            fontSize: "1.5rem",
          }}
          sx={{ mt: 2 }}
        >
          {title}
        </Typography>
      )}
    </Box>
  )
}
