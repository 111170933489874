import AnimationIcon from "@mui/icons-material/Animation"
import AutoModeIcon from "@mui/icons-material/AutoMode"
import ClosedCaptionOffIcon from "@mui/icons-material/ClosedCaptionOff"
import PaletteIcon from "@mui/icons-material/Palette"
import PermMediaIcon from "@mui/icons-material/PermMedia"
import SmartToyIcon from "@mui/icons-material/SmartToy"
import TextFieldsIcon from "@mui/icons-material/TextFields"
import VisibilityIcon from "@mui/icons-material/Visibility"

const features: {
  features: {
    Icon?: React.ElementType
    name: string
    description: string
  }[]
} = {
  features: [
    {
      Icon: ClosedCaptionOffIcon,
      name: "Automatic Captions",
      description:
        "Effortlessly render your spoken words as animated captions, improving engagement for viewers.",
    },
    {
      Icon: AutoModeIcon,
      name: "Daily Video Ideas",
      description:
        "Never run out of content inspiration with Youtube shorts automatically generated and edited for you daily.",
    },
    {
      Icon: AnimationIcon,
      name: "Text Animations",
      description:
        "Add flair to your text with dynamic animations, making your captions pop and catch attention.",
    },
    {
      Icon: TextFieldsIcon,
      name: "Customize Fonts",
      description:
        "Personalize and brand your videos by choosing from a vast library of fonts and styles to fit your style and brand aesthetics.",
    },
    {
      Icon: VisibilityIcon,
      name: "Attention Bait Presets",
      description:
        "Grab your viewers' attention instantly with captivating video elements designed to stop the scroll and engage.",
    },
    {
      Icon: PermMediaIcon,
      name: "Custom Video Assets",
      description:
        "Incorporate your brand's logos, images, or any other media seamlessly into your videos for a branded look.",
    },
    {
      Icon: SmartToyIcon,
      name: "AI-Powered Editing",
      description:
        "Let artificial intelligence do the heavy lifting, auto-detecting key moments in your content to streamline your editing process.",
    },
    {
      Icon: PaletteIcon,
      name: "Interactive Templates",
      description:
        "Choose from a range of professionally designed templates to enhance your video's visual appeal.",
    },
  ],
}

export default features
