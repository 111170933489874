import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { combineReducers } from "redux"
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist"
import storage from "redux-persist/lib/storage"

import { EnhancedStore, configureStore } from "@reduxjs/toolkit"

import currentUserProfile from "./currentUserProfile"
// defaults to localStorage for web
import darkModeEnabled from "./darkModeEnabled"
import infoAlertsEnabled from "./infoAlertsEnabled"
import popups from "./popups"
import snackbars from "./snackbars"

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["currentUserProfile", "popups", "snackbars"],
}

const rootReducer = combineReducers({
  currentUserProfile,
  darkModeEnabled,
  infoAlertsEnabled,
  popups,
  snackbars,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const dummyStore = configureStore({ reducer: persistedReducer })

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof dummyStore.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//       },
//     }),
// })

// export const persistor = persistStore(store)

// export default store

let store: EnhancedStore | null = null

export const createStore = (preloadedState) => {
  store = configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  })
  if (preloadedState) persistStore(store)
  return store
}

export const getStoreAfterInitialization = () => {
  return store
}
