import { API_BASE_URL } from "../../consts"
import axios from "../axios"

export default function createAsset(
  formData: FormData,
  onUploadProgress?: (progressEvent: ProgressEvent) => void
) {
  return axios
    .post(`${API_BASE_URL}/assets`, formData, { onUploadProgress })
    .then((response) => response.data)
}
