import LinearProgress from '@mui/material/LinearProgress'
import Box from '@mui/material/Box'

export default function LinearDeterminate({progress}: {progress: number}) {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress variant="determinate" value={progress} />
    </Box>
  )
}
