import { PropsWithChildren } from "react"

import Paper from "@mui/material/Paper"

const HeadingContainer = (props: PropsWithChildren<{ minHeight?: string }>) => (
  <Paper>
    <div
      style={{
        display: "flex",
        minHeight: props.minHeight || "64px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: "24px",
        paddingRight: "24px",
      }}
    >
      {props.children}
    </div>
  </Paper>
)

export default HeadingContainer
