import React from "react"
import { useDispatch } from "react-redux"

import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"
import Snackbar from "@mui/material/Snackbar"

import { useAppSelector } from "../redux"
import { removeSnackbar } from "../redux/snackbars"

export default function Snackbars() {
  const snackbars = useAppSelector((state) => state.snackbars)
  const dispatch = useDispatch()

  return (
    <div>
      {snackbars.map((snackbar) => {
        const handleClose = () => dispatch(removeSnackbar({ id: snackbar.id }))

        const action = (
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        )

        return (
          <Snackbar
            open
            action={action}
            key={snackbar.id}
            autoHideDuration={6000}
            onClose={handleClose}
            message={snackbar.text}
          />
        )
      })}
    </div>
  )
}
