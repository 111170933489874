import * as React from "react"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"

import { formatDistanceToNow } from "date-fns"

import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import CircularProgress from "@mui/material/CircularProgress"
import Link from "@mui/material/Link"
import Stack from "@mui/material/Stack"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import fetchBlogPost from "../../../../api/blogposts/get"
import AppBar from "../../../../components/AppBar"
import Footer from "../../../../components/Footer"
import FullPageContentContainer from "../../../../components/FullPageContentContainer"
import HeadingContainer from "../../../../components/HeadingContainer"
import contentToReadTimeString from "../../../../helpers/strings/contentToReadTimeString"
import { useAppSelector } from "../../../../redux"
import { BlogPost } from "../../../../types/BlogPost"

export default function PostList() {
  const { blogPostUuid } = useParams()
  const navigate = useNavigate()
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)

  const [errorMessage, setErrorMessage] = React.useState("")
  const [blogPostLoading, setBlogPostLoading] = React.useState(false)
  const [blogPost, setBlogPost] = React.useState<BlogPost | null>(null)

  React.useEffect(() => {
    fetchBlogPost(blogPostUuid || "")
      .then((blogPost) => {
        setBlogPostLoading(false)
        setBlogPost(blogPost)
      })
      .catch((err) => {
        console.error(err)
        setBlogPostLoading(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message
        )
      })
  }, [blogPostUuid])

  return (
    <>
      <AppBar title="Trimmr" />

      <Toolbar />

      {blogPost && (
        <Helmet>
          <title>{blogPost.title}</title>
          <meta name="description" content={blogPost.subtitle} />
        </Helmet>
      )}

      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error" onClose={() => setErrorMessage("")}>
            {errorMessage}
          </Alert>
        </Stack>
      )}

      <HeadingContainer>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            onClick={(e) => {
              e.preventDefault()
              navigate("/app/blog")
            }}
            underline="hover"
            color="inherit"
            href="/app/blog"
          >
            Guides
          </Link>

          <Typography color="text.primary">Show Post</Typography>
        </Breadcrumbs>
      </HeadingContainer>

      <FullPageContentContainer maxWidth="md">
        {blogPostLoading && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}

        {blogPost && (
          <Box sx={{ p: 3, pt: 0 }}>
            <Typography
              sx={{ pb: 3 }}
              variant="h3"
              color="textPrimary"
              component="h1"
              className="blog-title"
            >
              {blogPost.title}
            </Typography>
            <Typography
              sx={{ pb: 3 }}
              color="text.secondary"
              variant="h6"
              component="h2"
              className="blog-subtitle"
            >
              Created{" "}
              {formatDistanceToNow(new Date(blogPost.createdAt), {
                addSuffix: true,
              })}{" "}
              | {contentToReadTimeString(blogPost.content)}
            </Typography>

            {blogPost.coverImageUrl && (
              <Box sx={{ mb: 4 }}>
                <img
                  alt="Cover art"
                  style={{ width: "100%" }}
                  src={blogPost.coverImageUrl}
                />
              </Box>
            )}

            <Box sx={{ pb: 3 }} className="blog-body">
              <Typography color="text.primary">
                <div
                  dangerouslySetInnerHTML={{ __html: blogPost.content }}
                  className={darkModeEnabled ? "dark-mode-links" : ""}
                />
              </Typography>
            </Box>
          </Box>
        )}
      </FullPageContentContainer>

      <Footer />
    </>
  )
}
