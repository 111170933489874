import * as React from "react"

import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import Switch from "@mui/material/Switch"

import updateImportJob from "../../../../api/importJobs/update"
import { useAppDispatch } from "../../../../redux"
import { addSnackbar } from "../../../../redux/snackbars"

interface Props {
  importJobUuid: string
  disabled?: boolean
  checked: boolean
  onChange: (dailyIdeaEmailEnabled: boolean) => void
}

const DailyIdeaCheckbox: React.FC<Props> = ({
  importJobUuid,
  disabled,
  checked,
  onChange,
}) => {
  const dispatch = useAppDispatch()

  const [loading, setLoading] = React.useState(false)

  const setDailyIdeaEnabled = React.useCallback(
    async (dailyIdeaEmailEnabled: boolean) => {
      setLoading(true)

      try {
        await updateImportJob(importJobUuid, { dailyIdeaEmailEnabled })
        onChange(dailyIdeaEmailEnabled)
      } catch (err) {
        console.error("Error updating import job daily idea checkbox", err)

        dispatch(
          addSnackbar({
            id: "updatedailyideaimportfail",
            text: "Failed to update import job.",
          })
        )
      }

      setLoading(false)
    },
    [dispatch, importJobUuid, onChange]
  )

  return (
    <FormGroup>
      <FormControlLabel
        style={{ fontSize: "1rem" }}
        labelPlacement="end"
        control={
          <Switch
            disabled={disabled || loading}
            checked={checked}
            size="medium"
            onChange={(e) => setDailyIdeaEnabled(e.target.checked)}
          />
        }
        label="Enable daily idea emails?"
      />
    </FormGroup>
  )
}

export default DailyIdeaCheckbox
