import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { v4 as uuidv4 } from "uuid"

export interface Snackbar {
  id: string
  text: string
}

export interface AddSnackbarAction {
  id?: string
  text: string
}

export const snackbars = createSlice({
  name: "snackbars",
  initialState: [] as Snackbar[],
  reducers: {
    addSnackbar: (state, action: PayloadAction<AddSnackbarAction>) => {
      const newSnackbar = {
        id: `${action.payload.id || uuidv4()}${Date.now()}`,
        text: action.payload.text,
      }
      return [newSnackbar, ...state]
    },
    removeSnackbar: (state, action) => {
      return state.filter((s) => s.id !== action.payload.id)
    },
  },
})

export const { addSnackbar, removeSnackbar } = snackbars.actions

export default snackbars.reducer
