import * as React from "react"

import AddCircleIcon from "@mui/icons-material/AddCircle"
import { IconButton, Stack, Tooltip } from "@mui/material"

interface Props {
  onClick: () => void
  disabled?: boolean
}

export const AddCaptionSectionButton: React.FC<Props> = ({
  onClick,
  disabled,
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      onClick={onClick}
      style={{
        height: "1px",
        position: "relative",
        cursor: "pointer",
        overflow: "visible",
        zIndex: 2,
      }}
      sx={{ pr: 2, ml: -0.5 }}
    >
      <Tooltip title={"Add phrase"}>
        <IconButton onClick={onClick} size="small" disabled={disabled}>
          <AddCircleIcon color="inherit" />
        </IconButton>
      </Tooltip>
    </Stack>
  )
}
