import * as React from "react"

// .container {
//   max-width: 500px;
//   padding: 10px;
// }

// .videoRatio {
//   overflow: hidden;
//   padding: 56.25% 0 0 0;
//   position: relative;
//   width: 100%;
// }

const DeferredYoutubePreview = ({
  youtubeURL,
  alt,
  thumbnail,
}: {
  youtubeURL: string
  alt: string
  thumbnail: string
}) => {
  const [showVideo, setShowVideo] = React.useState(false)
  return (
    <div
      style={{
        overflow: "hidden",
        paddingBottom: "56.25%",
        position: "relative",
        height: 0,
      }}
    >
      {!showVideo && (
        <button
          style={{
            bottom: 0,
            height: "100%",
            left: 0,
            position: "absolute",
            right: 0,
            top: 0,
            width: "100%",

            backgroundColor: "transparent",
            border: 0,
            cursor: "pointer",
            display: "block",
            margin: 0,
            padding: 0,
            textDecoration: "none",
          }}
          onClick={() => {
            React.startTransition(() => {
              setShowVideo(true)
            })
          }}
        >
          <div
            style={{
              bottom: "0",
              height: "100%",
              left: "0",
              position: "absolute",
              right: "0",
              top: "0",
              width: "100%",
            }}
          >
            <img
              alt={alt}
              src={thumbnail}
              style={{ width: "100%", paddingBottom: "56.25%" }}
              loading="lazy"
            />
            <img
              alt="Play Video"
              src="/img/yt.svg"
              loading="lazy"
              style={{
                height: "42px",
                left: "calc(50% - 30px)",
                position: "absolute",
                top: "calc(50% - 21px)",
                transition: "all 0.3s ease-in-out",
                width: "60px",
              }}
            />
          </div>
        </button>
      )}

      {showVideo && (
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/${
            (youtubeURL || "").split("v=")[1]
          }`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
          style={{
            left: 0,
            top: 0,
            height: "100%",
            width: "100%",
            position: "absolute",
          }}
        />
      )}
    </div>
  )
}

export default DeferredYoutubePreview
