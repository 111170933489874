import * as React from "react"
import { Helmet } from "react-helmet-async"

import { format } from "date-fns"

import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Link from "@mui/material/Link"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import searchBlogPosts from "../../../../api/blogposts/search"
import AppBar from "../../../../components/AppBar"
import Footer from "../../../../components/Footer"
import FullPageContentContainer from "../../../../components/FullPageContentContainer"
import contentToReadTimeString from "../../../../helpers/strings/contentToReadTimeString"
import { BlogPost } from "../../../../types/BlogPost"

export default function PostList() {
  const [errorMessage, setErrorMessage] = React.useState("")
  const [blogPostsLoading, setBlogPostsLoading] = React.useState(false)
  const [blogPosts, setBlogPosts] = React.useState<BlogPost[] | null>(null)

  React.useEffect(() => {
    searchBlogPosts()
      .then((response) => {
        setBlogPostsLoading(false)
        setBlogPosts(response.blogPosts)
      })
      .catch((err) => {
        console.error(err)
        setBlogPostsLoading(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message
        )
      })
  }, [])

  return (
    <>
      <AppBar title="Trimmr" />

      <Toolbar />

      <Helmet>
        <title>Guides</title>
        <meta
          name="description"
          content="Helpful information about how to set up Trimmr and route audio between applications."
        />
      </Helmet>

      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error" onClose={() => setErrorMessage("")}>
            {errorMessage}
          </Alert>
        </Stack>
      )}

      <FullPageContentContainer maxWidth="md">
        {blogPostsLoading && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}

        <Typography
          variant="h2"
          component="h1"
          className="blog-title"
          sx={{ mt: 2, mb: 5 }}
          color="textPrimary"
        >
          Guides
        </Typography>

        {blogPosts &&
          blogPosts.map((post) => (
            <Link href={`/app/blog/${post.uuid}`} className="no-underline">
              <Paper style={{ cursor: "pointer" }} sx={{ p: 3, my: 3 }}>
                <Box className="blog-post-item">
                  {post.coverImageUrl && (
                    <Box className="blog-post-image">
                      <img
                        alt="Cover art"
                        style={{ width: "100%" }}
                        src={post.coverImageUrl}
                      />
                    </Box>
                  )}
                  <Box>
                    <Typography
                      sx={{ mb: 2 }}
                      variant="h4"
                      className="blog-title"
                    >
                      {post.title}
                    </Typography>
                    <Typography
                      sx={{ mb: 2 }}
                      component="p"
                      className="blog-subtitle"
                    >
                      {post.subtitle}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      component="p"
                      className="blog-subtitle"
                    >
                      Created {format(new Date(post.createdAt), "Pp")} |{" "}
                      {contentToReadTimeString(post.content)}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Link>
          ))}
      </FullPageContentContainer>

      <Footer />
    </>
  )
}
