import * as React from "react"

import { AxiosError } from "axios"
import { v4 as uuidv4 } from "uuid"

import RefreshIcon from "@mui/icons-material/Refresh"
import LoadingButton from "@mui/lab/LoadingButton"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  Tooltip,
} from "@mui/material"
import Alert from "@mui/material/Alert"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

import updateUser from "../../../../api/users/update"
import HeadingContainer from "../../../../components/HeadingContainer"
import { useAppDispatch, useAppSelector } from "../../../../redux"
import { addSnackbar } from "../../../../redux/snackbars"

const dummyAPIKey = uuidv4()

const ApiKeyForm = ({
  oneOrTwo,
  onRefresh,
  hasExistingKey,
}: {
  oneOrTwo: "one" | "two"
  onRefresh: () => void
  hasExistingKey: boolean
}) => {
  const dispatch = useAppDispatch()
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)

  const [errorMessage, setErrorMessage] = React.useState("")

  const [apiKey, setApiKey] = React.useState<string | null>(null)

  const [password, setPassword] = React.useState("")
  const [updatingApiKey, setUpdatingApiKey] = React.useState(false)

  const [refreshing, setRefreshing] = React.useState(false)

  const handleRefreshApiKey = React.useCallback(async () => {
    setRefreshing(true)
    setErrorMessage("")
    if (!currentUserProfile) return

    try {
      const { newApiKeyOne, newApiKeyTwo } = await updateUser(
        currentUserProfile.uuid,
        {
          refreshApiKeyOne: oneOrTwo === "one",
          refreshApiKeyTwo: oneOrTwo === "two",
          password,
        }
      )
      if (oneOrTwo === "one") {
        setApiKey(newApiKeyOne)
      } else if (oneOrTwo === "two") {
        setApiKey(newApiKeyTwo)
      }

      setErrorMessage("")

      setRefreshing(false)
      dispatch(
        addSnackbar({
          text: "Successfully updated API key, please copy it now as it will not be shown again.",
        })
      )
      setUpdatingApiKey(false)
      onRefresh()
    } catch (err) {
      console.error(err)
      setRefreshing(false)
      const axiosError = err as AxiosError
      setErrorMessage(
        (axiosError.response &&
          axiosError.response.data &&
          axiosError.response.data.error) ||
          axiosError.message
      )
    }
  }, [currentUserProfile, dispatch, onRefresh, oneOrTwo, password])

  const inputId = `standard-adornment-api-key-${oneOrTwo}`
  return (
    <>
      {updatingApiKey && (
        <Dialog
          open={Boolean(updatingApiKey)}
          maxWidth="sm"
          fullWidth
          onClose={() => setUpdatingApiKey(false)}
        >
          <DialogTitle>
            Refresh API key {oneOrTwo === "one" ? "1" : "2"}
          </DialogTitle>
          <DialogContent>
            {errorMessage && (
              <Stack sx={{ width: "100%", mb: 2 }} spacing={2}>
                <Alert severity="error" onClose={() => setErrorMessage("")}>
                  {errorMessage}
                </Alert>
              </Stack>
            )}

            <Alert severity="info" sx={{ mb: 3 }}>
              Please provide your password before refreshing this API key.
              Hitting "confirm" will reset this API key and you will be given
              one opportunity to copy it.
            </Alert>

            <TextField
              autoComplete="password"
              name="password"
              required
              type="password"
              fullWidth
              id="password"
              value={password || ""}
              label="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <LoadingButton
              disabled={!password}
              onClick={() => handleRefreshApiKey()}
              loading={refreshing}
              variant="contained"
            >
              Confirm
            </LoadingButton>

            <Button variant="outlined" onClick={() => setUpdatingApiKey(false)}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <FormControl sx={{ m: 1, width: "400px" }} variant="standard">
        <InputLabel htmlFor={inputId}>
          API Key {oneOrTwo === "one" ? "1" : "2"}
        </InputLabel>
        <Input
          id={inputId}
          type={apiKey ? "text" : "password"}
          disabled
          value={apiKey || (hasExistingKey ? dummyAPIKey : "")}
          endAdornment={
            <InputAdornment position="end">
              <Tooltip title="Refresh API key">
                <IconButton
                  aria-label="Refresh"
                  onClick={() => setUpdatingApiKey(true)}
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
        />
      </FormControl>
    </>
  )
}

export default function Developer() {
  const [hasRefreshed, setHasRefreshed] = React.useState(false)

  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)

  return (
    <>
      <HeadingContainer>
        <Typography component="h1" variant="h6" color="textPrimary" noWrap>
          API Keys
        </Typography>
      </HeadingContainer>

      <Divider sx={{ mb: 2 }} />

      <Container maxWidth="md">
        {hasRefreshed && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            An API key has been refreshed, please copy it now as it will not be
            shown again.
          </Alert>
        )}
        <Alert severity="info">
          Use this page to generate API keys for your account. An API key can be
          used to authenticate against the Trimmr API. You are given two API
          keys to allow for the purposes of smooth key rotation.
        </Alert>

        <Paper sx={{ mt: 2, p: 4, pt: 3 }}>
          <Alert severity="info" sx={{ mb: 2 }}>
            API keys are only shown once, refresh an API key to get a new one.
          </Alert>

          <Box>
            <ApiKeyForm
              oneOrTwo="one"
              onRefresh={() => setHasRefreshed(true)}
              hasExistingKey={Boolean(currentUserProfile?.hasApiKeyOne)}
            />
          </Box>
          <Box>
            <ApiKeyForm
              oneOrTwo="two"
              onRefresh={() => setHasRefreshed(true)}
              hasExistingKey={Boolean(currentUserProfile?.hasApiKeyTwo)}
            />
          </Box>
        </Paper>
      </Container>
    </>
  )
}
