import * as React from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import { AxiosError } from "axios"

import {
  BaseVideoSize,
  BaseVideoVerticalAlignment,
  PresetRenderAttributes,
  prettyBaseVideoSize,
} from "@trimmr/trimmr-lib/types/Preset"

import LoadingButton from "@mui/lab/LoadingButton"
import {
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
} from "@mui/material"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

import createPreset from "../../api/presets/create"
import createPresetThumbnail from "../../lib/createPresetThumbnail"
import lightTheme, { darkTheme } from "../../lib/mdTheme"
import PresetPreview from "../../pages/Account/pages/PresetsManagement/PresetPreview"
import PresetRenderJobAssetsList from "../../pages/Account/pages/PresetsManagement/PresetRenderJobAssetsList"
import { useAppSelector } from "../../redux"
import { addPopup } from "../../redux/popups"
// import { addSnackbar } from "../../redux/snackbars"
import SmallAlert from "../SmallAlert"

// import PresetBaseVideoAlignmentPreview from "./PresetBaseVideoAlignmentPreview"

// import { useAppSelector } from "../../redux"

function NewPresetModal({
  open,
  onClose,
  onCreate,
  createPresetParams,
}: {
  open: boolean
  onClose: () => void
  onCreate: () => void
  createPresetParams?: PresetRenderAttributes
}) {
  const dispatch = useDispatch()

  const [name, setName] = React.useState("New preset")
  const [saving, setSaving] = React.useState(false)
  const [initialised, setInitialised] = React.useState(false)
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = React.useState("")
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  const theme = darkModeEnabled ? darkTheme : lightTheme
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)

  const [baseVideoVerticalAlignment, setBaseVideoVerticalAlignment] =
    React.useState<BaseVideoVerticalAlignment>("middle")
  const [baseVideoSize, setBaseVideoSize] = React.useState<BaseVideoSize>(
    "content-fit-vertical"
  )

  // const currentUserProfile = useAppSelector((state) => state.currentUserProfile)

  const [imgSrc, setImgSrc] = React.useState<string>("")
  const [selectedImage, setSelectedImage] = React.useState<Blob | null>(null)

  React.useEffect(() => {
    if (createPresetParams && open && !initialised) {
      setBaseVideoVerticalAlignment(
        createPresetParams.baseVideoVerticalAlignment
      )
      setBaseVideoSize(createPresetParams.baseVideoSize)
      setInitialised(true)
    }
    if (!open) {
      setInitialised(false)
    }
  }, [createPresetParams, initialised, open])

  React.useEffect(() => {
    if (selectedImage) {
      const reader = new FileReader()
      reader.readAsDataURL(selectedImage)
      reader.onloadend = () => setImgSrc(reader.result as string)
    }
  }, [selectedImage])

  const createThumbnail = React.useCallback(async () => {
    if (!createPresetParams) return

    const thumbnail = await createPresetThumbnail()

    thumbnail && setSelectedImage(thumbnail)
  }, [createPresetParams])

  React.useEffect(() => {
    if (!selectedImage) createThumbnail()
  }, [createThumbnail, selectedImage])

  const createPresetAction = React.useCallback(async () => {
    setSaving(true)

    if (!createPresetParams || !selectedImage) return

    try {
      await createPreset(
        {
          ...createPresetParams,
          name,
          baseVideoVerticalAlignment,
          baseVideoSize,
        },
        selectedImage
      )

      setErrorMessage("")
      setSaving(false)
      dispatch(
        addPopup({
          title: "Preset created",
          text: "This new preset wil now be visible in the preset library.",
        })
      )
      // dispatch(addSnackbar({ text: "Preset created." }))
      onCreate()
    } catch (err) {
      console.error("Error creating preset", err)
      setSaving(false)
      const axiosError = err as AxiosError
      setErrorMessage(axiosError?.response?.data?.error || axiosError.message)
    }
  }, [
    baseVideoSize,
    baseVideoVerticalAlignment,
    createPresetParams,
    dispatch,
    name,
    onCreate,
    selectedImage,
  ])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="new-preset-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="new-preset-dialog-title">New preset</DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        {errorMessage && (
          <Stack sx={{ width: "100%", mb: 2 }} spacing={2}>
            <Alert severity="error" onClose={() => setErrorMessage("")}>
              {errorMessage}
            </Alert>
          </Stack>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={2} sx={{ pb: 1 }}>
              <FormControl style={{ width: "100%" }} sx={{ pt: 1 }}>
                <TextField
                  name="name"
                  id="name"
                  value={name}
                  size="small"
                  label="Name"
                  fullWidth
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>

              <FormControl>
                <InputLabel size="small" id="baseVideoSize">
                  Base Video Size
                </InputLabel>
                <Select
                  fullWidth
                  size="small"
                  labelId="baseVideoSize"
                  label="Base Video Size"
                  value={baseVideoSize}
                  onChange={(e) =>
                    setBaseVideoSize(e.target.value as BaseVideoSize)
                  }
                >
                  {[
                    "content-fit-vertical",
                    "half-height-vertical",
                    "one-third-height-vertical",
                    "two-thirds-height-vertical",
                    "content-fit-horizontal",
                  ].map((baseVideoSizeId) => (
                    <MenuItem key={baseVideoSizeId} value={baseVideoSizeId}>
                      <Typography variant="body1">
                        {prettyBaseVideoSize(baseVideoSizeId as BaseVideoSize)}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl style={{ width: "100%" }}>
                <InputLabel size="small" id="textAnimation">
                  Base Video Vertical Alignment
                </InputLabel>
                <Select
                  fullWidth
                  disabled={baseVideoSize === "content-fit-vertical"}
                  size="small"
                  labelId="baseVideoVerticalAlignment"
                  label="Base Video Vertical Alignment"
                  value={baseVideoVerticalAlignment}
                  onChange={(e) =>
                    setBaseVideoVerticalAlignment(
                      e.target.value as BaseVideoVerticalAlignment
                    )
                  }
                >
                  <MenuItem value="top">
                    <Typography variant="body1">Top</Typography>
                  </MenuItem>
                  <MenuItem value="middle">
                    <Typography variant="body1">Middle</Typography>
                  </MenuItem>
                  <MenuItem value="bottom">
                    <Typography variant="body1">Bottom</Typography>
                  </MenuItem>
                </Select>
              </FormControl>

              <Box>
                <label htmlFor="contained-button-file">
                  <input
                    style={{ display: "none" }}
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    onChange={(e) =>
                      e.target.files && setSelectedImage(e.target.files[0])
                    }
                  />

                  <Button
                    variant="contained"
                    component="span"
                    color="secondary"
                    sx={{ mb: 2 }}
                  >
                    Upload Base Video Thumbnail
                  </Button>
                </label>
                {imgSrc && (
                  <Box maxWidth="600px">
                    <img
                      src={imgSrc}
                      height="auto"
                      width="auto"
                      alt="Preview"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "300px",
                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      }}
                    />
                  </Box>
                )}
              </Box>

              <Paper sx={{ p: 2 }}>
                <Typography variant="body1">
                  <b>Assets</b>
                </Typography>
                <PresetRenderJobAssetsList
                  renderJobAssets={createPresetParams?.renderJobAssets || []}
                  baseVideoZIndex={createPresetParams?.baseVideoZIndex || 0}
                />
              </Paper>

              {/* <Box>
                {Boolean(createPresetParams?.renderJobAssets?.length) && (
                  <>
                    <Typography variant="body1">
                      <b>Assets</b>
                    </Typography>
                    {createPresetParams?.renderJobAssets?.map(
                      (renderJobAsset) => (
                        <Box key={renderJobAsset.uuid}>
                          <Typography variant="body1">
                            {renderJobAsset.asset?.name}
                          </Typography>
                        </Box>
                      )
                    )}
                  </>
                )}
              </Box> */}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box sx={{ p: 2 }}>
              <PresetPreview
                showBorder
                showDefaultCaption
                preset={{
                  ...(createPresetParams as PresetRenderAttributes),
                  baseVideoVerticalAlignment,
                  baseVideoSize,
                  thumbnailUrl: imgSrc,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        {!currentUserProfile?.hasActiveStripeSubscription ? (
          <SmallAlert
            variant="outlined"
            severity="warning"
            color="primary"
            sx={{ py: 0.2, m: 0, mr: 2 }}
          >
            <Typography variant="subtitle2" textAlign="right">
              <Link
                href="/app/account/subscription"
                onClick={(e) => {
                  e.preventDefault()
                  navigate("/app/account/subscription")
                }}
                style={{ color: theme.palette.warning.main }}
              >
                Upgrade to render and create presets
              </Link>
            </Typography>
          </SmallAlert>
        ) : (
          <LoadingButton
            onClick={() => createPresetAction()}
            loading={saving}
            color="primary"
            variant="contained"
          >
            Create Preset
          </LoadingButton>
        )}

        <LoadingButton onClick={onClose}>Cancel</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default NewPresetModal
