// purpose of this module is to have a window object on serverside.
// serverside can't globally define window because MUI5 looks at it to find out
// whether to use useLayoutEffect or useEffect, and useLayoutEffect generates warnings
// import { WindowParams } from '../../types/types'

let windowProxy: Window

if (typeof window === "undefined") {
  windowProxy = {
    CSRF_TOKEN: "x",
    STRIPE_PUBLIC_KEY: "x",
    RECAPTCHA_SITE_KEY: "x",
    GOOGLE_ANALYTICS_ID: "x",

    preloadedReduxState: {},
  } as unknown as Window
} else {
  windowProxy = window
}

export default windowProxy
