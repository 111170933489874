export default function createVideoThumbnail(file: Blob): Promise<Blob> {
  const video = document.createElement("video")
  const canvas = document.createElement("canvas")
  const ctx = canvas.getContext("2d")

  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      const url = URL.createObjectURL(file)
      video.src = url

      video.currentTime = 1

      video.addEventListener("seeked", () => {
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight

        ctx && ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

        // const dataUrl = canvas.toDataURL("image/jpeg", 0.8)
        canvas.toBlob(
          (blob) => {
            if (!blob) return reject(new Error("Error generating thumbnail"))
            resolve(blob)
          },
          "image/jpeg",
          0.8
        )
      })

      video.addEventListener("error", () => {
        console.error("Error loading video for thumbnail", video.error)
        reject(new Error("Error loading video for thumbnail"))
      })
    }

    reader.readAsArrayBuffer(file)
  })
}
