import * as React from "react"
import { useNavigate } from "react-router-dom"

import { format } from "date-fns"

import EditIcon from "@mui/icons-material/Edit"
import VisibilityIcon from "@mui/icons-material/Visibility"
import LoadingButton from "@mui/lab/LoadingButton"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Link from "@mui/material/Link"
import Pagination from "@mui/material/Pagination"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"

import createBlogPost from "../../../../api/blogposts/create"
// import FilterContainer from '../../../../components/FilterContainer'
import searchBlogPosts from "../../../../api/blogposts/search"
// import TextField from '@mui/material/TextField'
import HeadingContainer from "../../../../components/HeadingContainer"
import { BlogPost } from "../../../../types/BlogPost"

// import { darkTheme, lightTheme } from '../../../../lib/mdTheme'

let queryTimeout

// const PER_PAGE = 10

export default function AdminBlogPostManagement() {
  const navigate = useNavigate()
  // const darkModeEnabled = useSelector(state => state.darkModeEnabled)
  // const mdTheme = darkModeEnabled ? darkTheme : lightTheme

  const [createLoading, setCreateLoading] = React.useState(false)
  const [blogPostsLoading, setBlogPostsLoading] = React.useState(true)
  const [blogPosts, setBlogPosts] = React.useState<BlogPost[] | null>(null)
  const [page, setPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(0)
  const [totalBlogPosts, setTotalBlogPosts] = React.useState(0)
  const [errorMessage, setErrorMessage] = React.useState("")

  const handleSearchBlogPosts = React.useCallback(() => {
    searchBlogPosts({
      // page,
      // perPage: PER_PAGE,
    })
      .then(({ blogPosts, totalPages, totalResults }) => {
        setBlogPosts(blogPosts)
        setTotalPages(totalPages)
        setTotalBlogPosts(totalResults)
        setBlogPostsLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setBlogPostsLoading(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message
        )
      })
  }, [])

  const handleCreateBlogPost = () => {
    setCreateLoading(true)
    createBlogPost({
      title: "No name",
      subtitle: "No subtitle",
    })
      .then((blogPost) => {
        setCreateLoading(false)
        navigate(`/app/admin/blogposts/${blogPost.uuid}`)
      })
      .catch((err) => {
        setCreateLoading(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message
        )
      })
  }

  React.useEffect(() => {
    setBlogPostsLoading(true)
    clearTimeout(queryTimeout)
    queryTimeout = setTimeout(() => {
      handleSearchBlogPosts()
    }, 300)
  }, [page, handleSearchBlogPosts])

  return (
    <div>
      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error" onClose={() => setErrorMessage("")}>
            {errorMessage}
          </Alert>
        </Stack>
      )}

      <HeadingContainer>
        <Typography component="h1" variant="h6" noWrap color="textPrimary">
          Blog Posts
        </Typography>

        <LoadingButton
          loading={createLoading}
          variant="contained"
          onClick={handleCreateBlogPost}
        >
          Create Post
        </LoadingButton>
      </HeadingContainer>

      <Divider sx={{ mb: 2 }} />

      {blogPostsLoading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress color="inherit" />
        </Box>
      )}

      {!blogPostsLoading && (
        <Container maxWidth="md">
          <Paper sx={{ mb: 2, p: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body1" color="textSecondary">
                {totalBlogPosts} blog posts
              </Typography>

              <Pagination
                count={totalPages}
                page={page}
                onChange={(_, v) => setPage(v)}
              />
            </Stack>
          </Paper>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <b>Created At</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Title</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Hidden</b>
                  </TableCell>
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                {blogPosts &&
                  blogPosts.map((blogPost) => (
                    <TableRow
                      key={blogPost.uuid}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">
                        {format(new Date(blogPost.createdAt), "Pp")}
                      </TableCell>
                      <TableCell align="left">
                        {blogPost.title || <i>None</i>}
                      </TableCell>
                      <TableCell align="left">
                        {blogPost.hidden ? "Hidden" : "Public"}
                      </TableCell>
                      <TableCell align="right">
                        <Box sx={{ pr: 2 }}>
                          <Tooltip title="View">
                            <Link
                              href={`/app/blog/${blogPost.uuid}`}
                              onClick={(e) => e.preventDefault()}
                            >
                              <IconButton
                                onClick={() =>
                                  navigate(`/app/blog/${blogPost.uuid}`)
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Link>
                          </Tooltip>

                          <Tooltip title="Edit">
                            <Link
                              href={`/app/admin/blogposts/${blogPost.uuid}`}
                              onClick={(e) => e.preventDefault()}
                            >
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/app/admin/blogposts/${blogPost.uuid}`
                                  )
                                }
                              >
                                <EditIcon />
                              </IconButton>
                            </Link>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      )}
    </div>
  )
}
