import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import AppBar from "../../components/AppBar"
import Footer from "../../components/Footer"
import FullPageContentContainer from "../../components/FullPageContentContainer"

export default function TermsOfUse() {
  return (
    <>
      <AppBar title="Trimmr" />

      <Toolbar />

      <FullPageContentContainer>
        <Typography
          component="h1"
          variant="h2"
          align="left"
          color="text.primary"
          gutterBottom
        >
          Terms of Use
        </Typography>

        <Typography color="text.primary" style={{ whiteSpace: "pre-wrap" }}>
          {`
TERMS OF USE

Last updated 0th October 2023

AGREEMENT TO OUR LEGAL TERMS

OUR SERVICES

INTELLECTUAL PROPERTY RIGHTS

USER REPRESENTATIONS

PROHIBITED ACTIVITIES

5. USER GENERATED CONTRIBUTIONS6. CONTRIBUTION LICENSE7. SERVICES MANAGEMENT

TERM AND TERMINATION
9. MODIFICATIONS AND INTERRUPTIONS10. GOVERNING LAW11. DISPUTE RESOLUTION12. CORRECTIONS13. DISCLAIMER14. LIMITATIONS OF LIABILITY15. INDEMNIFICATION16. USER DATA17. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES18. MISCELLANEOUS19. CONTACT US

PROHIBITED ACTIVITIES" section below, we grant you a non-exclusive, non-transferable, revocable license to:

access the Services; and
download or print a copy of any portion of the Content to which you have properly gained access.
solely for your personal, non-commercial use or internal business purpose.

Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.

If you wish to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere in our Legal Terms, please address your request to: support@trimmr.atlassian.net. If we ever grant you the permission to post, reproduce, or publicly display any part of our Services or Content, you must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is visible on posting, reproducing, or displaying our Content.

We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.

Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and your right to use our Services will terminate immediately.

Your submissions

Please review this section and the "PROHIBITED ACTIVITIES" section carefully prior to using our Services to understand the (a) rights you give us and (b) obligations you have when you post or upload any content through the Services.

Submissions: By directly sending us any question, comment, suggestion, idea, feedback, or other information about the Services ("Submissions"), you agree to assign to us all intellectual property rights in such Submission. You agree that we shall own this Submission and be entitled to its unrestricted use and dissemination for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.

You are responsible for what you post or upload: By sending us Submissions through any part of the Services you:

confirm that you have read and agree with our "PROHIBITED ACTIVITIES" and will not post, send, publish, upload, or transmit through the Services any Submission that is illegal, harassing, hateful, harmful, defamatory, obscene, bullying, abusive, discriminatory, threatening to any person or group, sexually explicit, false, inaccurate, deceitful, or misleading;
to the extent permissible by applicable law, waive any and all moral rights to any such Submission;
warrant that any such Submission are original to you or that you have the necessary rights and licenses to submit such Submissions and that you have full authority to grant us the above-mentioned rights in relation to your Submissions; and
warrant and represent that your Submissions do not constitute confidential information.
You are solely responsible for your Submissions and you expressly agree to reimburse us for any and all losses that we may suffer because of your breach of (a) this section, (b) any third party’s intellectual property rights, or (c) applicable law.
          `}
        </Typography>
      </FullPageContentContainer>

      <Footer />
    </>
  )
}
