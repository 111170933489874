import { useNavigate } from "react-router-dom"

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import Stack from "@mui/material/Stack"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import AppBar from "../../components/AppBar"
import Copyright from "../../components/Copyright"
import { useAppSelector } from "../../redux"

export default function CompleteConfirmEmail() {
  const navigate = useNavigate()
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)

  return (
    <>
      <AppBar title="Trimmr" />

      <Toolbar />

      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5" color="text.primary">
            A confirmation email has been sent. This may take a minute to
            arrive, and please check your junk mail.
          </Typography>
        </Box>

        <Stack
          sx={{ pt: 4 }}
          direction="row"
          spacing={2}
          justifyContent="center"
        >
          <Button
            onClick={() =>
              currentUserProfile
                ? navigate("/app/account/video-creator")
                : navigate("/app/signin")
            }
            variant="contained"
          >
            Enter Account{" "}
            <ArrowForwardIosIcon
              style={{ fontSize: "0.75rem" }}
              sx={{ ml: 1 }}
            />
          </Button>
        </Stack>

        <Copyright sx={{ mt: 5 }} />
      </Container>
    </>
  )
}
