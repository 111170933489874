import { API_BASE_URL } from "../../consts"
import axios from "../axios"

export default function updateImportJob(
  importJobUuid: string,
  params: {
    notifyOnComplete?: boolean
    dailyIdeaEmailEnabled?: boolean
    originalWidth?: number
    originalHeight?: number
  }
) {
  return axios
    .patch(`${API_BASE_URL}/import-jobs/${importJobUuid}`, params)
    .then((response) => response.data)
}
