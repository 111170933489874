import axios from "../axios"
import { API_BASE_URL } from "../../consts"

export default function sendContactEmail({
  name,
  email,
  device,
  appVersion,
  content,
  recaptchaToken,
}: {
  name?: string
  email?: string
  device?: string
  appVersion?: string
  content?: string
  recaptchaToken?: string
}) {
  return axios
    .post(`${API_BASE_URL}/contact/send-contact-email`, {
      name,
      email,
      device,
      appVersion,
      content,
      recaptchaToken,
    })
    .then((response) => response.data)
}
