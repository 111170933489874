import * as React from "react"
import { useDispatch } from "react-redux"

import { Asset } from "@trimmr/trimmr-lib/types/Asset"

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import DeleteIcon from "@mui/icons-material/Delete"
import GetAppIcon from "@mui/icons-material/GetApp"
import LoadingButton from "@mui/lab/LoadingButton"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import CircularProgress from "@mui/material/CircularProgress"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Grow from "@mui/material/Grow"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import Paper from "@mui/material/Paper"
import Popper from "@mui/material/Popper"

import deleteAsset from "../../../../api/assets/delete"
import { downloadURI } from "../../../../helpers/files/downloadURI"
import { addSnackbar } from "../../../../redux/snackbars"
import EditAssetModal from "./EditAssetModal"

export default function AssetJobButtonGroup({
  asset,
  onAssetChanged,
}: {
  asset: Asset
  onAssetChanged: () => void
}) {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const [deleteAssetLoading, setDeleteAssetLoading] = React.useState(false)
  const [areYouSureOpen, setAreYouSureOpen] = React.useState(false)
  const [editAssetOpen, setEditAssetOpen] = React.useState(false)

  const handleDeleteAsset = React.useCallback(async () => {
    try {
      setDeleteAssetLoading(true)
      await deleteAsset(asset.uuid)
      onAssetChanged()

      dispatch(
        addSnackbar({
          text: "Asset deleted.",
        })
      )
    } catch (err) {
      console.error("Failed to delete asset", err)

      dispatch(
        addSnackbar({
          text: "Failed to delete asset.",
        })
      )
      setDeleteAssetLoading(false)
    }
  }, [asset.uuid, dispatch, onAssetChanged])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  return (
    <React.Fragment>
      <Dialog
        open={areYouSureOpen}
        onClose={() => setAreYouSureOpen(false)}
        aria-labelledby="areyousure-asset-delete-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="areyousure-asset-delete-dialog-title">
          Delete asset?
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you wish to delete this asset?
          </Typography>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            onClick={() => handleDeleteAsset()}
            loading={deleteAssetLoading}
            color="primary"
            variant="contained"
          >
            Delete
          </LoadingButton>
          <LoadingButton onClick={() => setAreYouSureOpen(false)}>
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {editAssetOpen && (
        <EditAssetModal
          asset={asset}
          open={editAssetOpen}
          onSave={() => {
            setEditAssetOpen(false)
            onAssetChanged()
          }}
          onClose={() => {
            setEditAssetOpen(false)
          }}
        />
      )}
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        size="small"
        style={{ alignSelf: "start" }}
      >
        <Button onClick={() => setEditAssetOpen(true)}>Edit Asset</Button>
        <Button
          size="small"
          aria-controls={open ? "import-action-split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select import job action"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {asset.assetUrl && asset.assetFilepath && (
                    <MenuItem
                      onClick={() =>
                        asset.assetUrl &&
                        asset.assetFilepath &&
                        downloadURI(
                          asset.assetUrl,
                          asset.assetFilepath.split("/").slice(-1)[0]
                        )
                      }
                    >
                      <GetAppIcon sx={{ mr: 2 }} />
                      Download
                    </MenuItem>
                  )}
                  <MenuItem
                    disabled={deleteAssetLoading}
                    onClick={() => setAreYouSureOpen(true)}
                  >
                    {deleteAssetLoading ? (
                      <CircularProgress
                        size={20}
                        sx={{ mr: 2 }}
                        color="inherit"
                      />
                    ) : (
                      <DeleteIcon sx={{ mr: 2 }} />
                    )}
                    Delete Asset
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  )
}
