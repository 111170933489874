import { PresetRenderAttributes } from "@trimmr/trimmr-lib/types/Preset"
import { defaultRenderJobInitialParams } from "@trimmr/trimmr-lib/types/RenderJob"
import { SRTWithWordTimings } from "@trimmr/trimmr-lib/types/SRT/SRTWithWordTimings"

import { Box } from "@mui/material"

import AbsolutelyPositionedBaseVideo from "./AbsolutelyPositionedBaseVideo"
import { AbsolutelyPositionedPreviewText } from "./AbsolutelyPositionedPreviewText"
import { AbsolutelyPositionedRenderJobAsset } from "./AbsolutelyPositionedRenderJobAsset"

const PresetPreview: React.FC<{
  preset: PresetRenderAttributes & { thumbnailUrl?: string }
  showBorder?: boolean
  startSeconds?: number
  srtWithWordTimings?: SRTWithWordTimings[]
  showDefaultCaption?: boolean
}> = ({
  preset,
  showBorder = true,
  startSeconds,
  srtWithWordTimings = [],
  showDefaultCaption,
}) => {
  // const renderJobAssetsWithDefaults: RenderJobAsset[] = (
  //   preset.renderJobAssets || []
  // ).map((renderJobAsset) => {
  //   return {
  //     ...renderJobAsset,
  //     key: renderJobAsset.uuid,
  //     startTimeSeconds: renderJobAsset.startTimeSeconds || 0,
  //     endTimeSeconds: renderJobAsset.endTimeSeconds || 0,
  //     assetUrl:
  //       renderJobAsset.asset?.thumbnailUrl || renderJobAsset.asset?.assetUrl,
  //     xPosition: renderJobAsset.xPosition || 0,
  //     yPosition: renderJobAsset.yPosition || 0,
  //     xScale: renderJobAsset.xScale || 1,
  //     yScale: renderJobAsset.yScale || 1,
  //     videoResolutionWidth: preset.resolutionWidth,
  //     originalWidth: renderJobAsset.asset?.originalWidth || 1,
  //     originalHeight: renderJobAsset.asset?.originalHeight || 1,
  //   }
  // })

  // we'll be rendering base video at some index
  const renderJobAssetsFirst = (preset.renderJobAssets || []).slice(
    0,
    preset.baseVideoZIndex
  )
  const renderJobAssetsSecond = (preset.renderJobAssets || []).slice(
    preset.baseVideoZIndex
  )

  return (
    <Box
      style={{
        backgroundColor:
          preset.backgroundColor ||
          defaultRenderJobInitialParams.backgroundColor,
        aspectRatio: `${preset.resolutionWidth}/${preset.resolutionHeight}`,
        position: "relative",
        border: showBorder ? `3px solid grey` : "",
        overflow: "hidden",
      }}
    >
      <AbsolutelyPositionedPreviewText
        verticalCaptionAlignment={preset.verticalCaptionAlignment}
        horizontalCaptionAlignment={preset.horizontalCaptionAlignment}
        videoResolutionWidth={preset.resolutionWidth}
        videoResolutionHeight={preset.resolutionHeight}
        fontColor={preset.fontColor}
        strokeWidth={preset.strokeWidth}
        strokeColor={preset.strokeColor}
        fontName={preset.fontName}
        oneWordAtATime={preset.oneWordAtATime}
        textHighlightColor={preset.textHighlightColor}
        textShadow={preset.textShadow}
        textCapitalization={preset.textCapitalization}
        fontSizePx={preset.fontSizePx}
        paddingHorizontalPx={preset.paddingHorizontalPx}
        paddingVerticalPx={preset.paddingVerticalPx}
        startSeconds={startSeconds}
        srtWithWordTimings={srtWithWordTimings}
        showDefaultCaption={showDefaultCaption}
      />

      {renderJobAssetsFirst.map((renderJobAsset) => (
        <AbsolutelyPositionedRenderJobAsset
          key={renderJobAsset.uuid}
          renderJobAsset={renderJobAsset}
          videoResolutionWidth={preset.resolutionWidth}
        />
      ))}

      <AbsolutelyPositionedBaseVideo
        baseVideoVerticalAlignment={
          preset.baseVideoVerticalAlignment || "middle"
        }
        baseVideoSize={preset.baseVideoSize || "content-fit-vertical"}
        startSeconds={startSeconds}
        thumbnailUrl={preset.thumbnailUrl}
      />

      {renderJobAssetsSecond.map((renderJobAsset) => (
        <AbsolutelyPositionedRenderJobAsset
          key={renderJobAsset.uuid}
          renderJobAsset={renderJobAsset}
          videoResolutionWidth={preset.resolutionWidth}
        />
      ))}
    </Box>
  )
}
export default PresetPreview
