import * as React from "react"

import AddCircleIcon from "@mui/icons-material/AddCircle"
import { Stack, Tooltip } from "@mui/material"

import { useAppSelector } from "../../redux"
import BlackButton from "../BlackButton"

interface Props {
  onClick: () => void
  disabled?: boolean
}

export const AddWordButton: React.FC<Props> = ({ onClick, disabled }) => {
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  return (
    <Tooltip title="Add word">
      <BlackButton
        lightModeEnabled={!darkModeEnabled}
        disabled={disabled}
        alignSelf="stretch"
        width="1.5rem"
        onClick={onClick}
      >
        <Stack
          direction="column"
          alignSelf="stretch"
          justifyContent="center"
          alignItems="center"
        >
          <AddCircleIcon color="inherit" style={{ fontSize: "1rem" }} />
        </Stack>
      </BlackButton>
    </Tooltip>
  )
}
