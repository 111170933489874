import { PropsWithChildren } from "react"

import FilterListIcon from "@mui/icons-material/FilterList"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

import lightTheme, { darkTheme } from "../lib/mdTheme"
import { useAppSelector } from "../redux"

const FilterContainer = (
  props: PropsWithChildren<{ hideFilterLabel?: boolean }>
) => {
  const { hideFilterLabel } = props
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  const mdTheme = darkModeEnabled ? darkTheme : lightTheme

  return (
    <Paper sx={{ p: 1 }}>
      <Stack
        direction="row"
        alignItems="center"
        flexWrap="wrap"
        gap="0.5rem 1rem"
      >
        {!hideFilterLabel && (
          <Stack
            direction="row"
            alignItems="center"
            flexWrap="wrap"
            gap="0.5rem 1rem"
          >
            <FilterListIcon
              sx={{ color: mdTheme.palette.text.primary, fontSize: 22 }}
            />
            <Typography
              color="textPrimary"
              style={{
                fontSize: "13px",
                paddingLeft: "0.15rem",
              }}
            >
              Filter
            </Typography>
          </Stack>
        )}

        {props.children}
      </Stack>
    </Paper>
  )
}

export default FilterContainer
