import * as React from "react"
import InputMask from "react-input-mask"

import {
  FilledInputProps,
  InputProps,
  OutlinedInputProps,
  styled,
} from "@mui/material"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"

import durationToMSString from "../../helpers/soundFile/durationToMSString"

function parseTimeToSeconds(timeString) {
  const [minutes, seconds, hundredths] = timeString.split(/[:.]/).map(Number)
  return minutes * 60 + seconds + hundredths / 100
}

function formatSecondsToTime(seconds) {
  const min = Math.floor(seconds / 60)
  const sec = Math.floor(seconds) % 60
  const hundredths = Math.round((seconds % 1) * 100)
  return `${min.toString().padStart(2, "0")}:${sec
    .toString()
    .padStart(2, "0")}.${hundredths.toString().padStart(2, "0")}`
}

export interface Props {
  value: number
  label?: string
  placeholderValue?: number | null
  onChange: (value: number) => void
  disabled?: boolean
  size?: string
  name?: string
  id?: string
  style?: React.CSSProperties
  variant?: "filled" | "outlined"
  InputProps?:
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | Partial<InputProps>
    | undefined
  innerPadding?: string
}

export const PreviewField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "innerPadding",
})<{ innerPadding?: string }>(({ innerPadding }) => ({
  "& .MuiInputBase-inputSizeSmall": {
    padding: innerPadding,
  },
}))

const StartEndPickerTimeField: React.FC<Props> = ({
  value,
  label,
  placeholderValue,
  onChange,
  disabled,
  name,
  id,
  style = {},
  InputProps,
  variant = "filled",
  innerPadding,
}) => {
  const time = formatSecondsToTime(value)

  const mask = [/[0-9]/, /[0-9]/, ":", /[0-5]/, /[0-9]/, ".", /[0-9]/, /[0-9]/]

  function beforeMaskedStateChange({ nextState }) {
    const { value } = nextState
    return {
      ...nextState,
      value: value.replace("_", "0"),
    }
  }
  return (
    <InputMask
      beforeMaskedStateChange={beforeMaskedStateChange}
      mask={mask}
      value={time}
      disabled={disabled}
      onChange={(e) => {
        onChange(parseTimeToSeconds(e.target.value))
      }}
    >
      <PreviewField
        innerPadding={innerPadding}
        type="text"
        variant={variant}
        size="small"
        name={name}
        id={id}
        style={{ textTransform: "none", ...style }}
        InputLabelProps={{ shrink: true }}
        placeholder={
          placeholderValue !== null
            ? durationToMSString(placeholderValue || 0, false, false)
            : undefined
        }
        InputProps={{
          endAdornment: <InputAdornment position="end">m:ss</InputAdornment>,
          ...(InputProps || {}),
        }}
        label={label}
      />
    </InputMask>
  )
}

export default StartEndPickerTimeField
