import * as React from "react"
import { useDispatch } from "react-redux"

import { AxiosError } from "axios"

import LocalActivityIcon from "@mui/icons-material/LocalActivity"
import LoadingButton from "@mui/lab/LoadingButton"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

import fetchMe from "../../../../api/users/fetchMe"
import resendConfirmationEmail from "../../../../api/users/resendConfirmationEmail"
import updateUser from "../../../../api/users/update"
import HeadingContainer from "../../../../components/HeadingContainer"
import { useAppSelector } from "../../../../redux"
import { setCurrentUserProfile } from "../../../../redux/currentUserProfile"
import { addSnackbar } from "../../../../redux/snackbars"

export default function Profile() {
  const dispatch = useDispatch()
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)

  const [userLoading, setUserLoading] = React.useState(true)
  const [user, setUser] = React.useState(null)
  const [errorMessage, setErrorMessage] = React.useState("")

  const [firstname, setFirstname] = React.useState("")
  const [lastname, setLastname] = React.useState("")

  const [isDirty, setDirty] = React.useState(false)
  const [isSaving, setSaving] = React.useState(false)

  const [sendingConfirmationEmail, setSendingConfirmationEmail] =
    React.useState(false)

  const handleSendConfirmationEmail = () => {
    setSendingConfirmationEmail(true)
    resendConfirmationEmail()
      .then(() => {
        setSendingConfirmationEmail(false)
        setErrorMessage("")
        dispatch(
          addSnackbar({
            id: "confirmationemailsent",
            text: "Confirmation email sent. Please check your junk.",
          })
        )
      })
      .catch((err) => {
        console.error(err)
        setSendingConfirmationEmail(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message
        )
      })
  }

  const handleReceiveUserProfile = (user) => {
    setUser(user)
    setFirstname(user.firstname)
    setLastname(user.lastname)
  }

  React.useEffect(() => {
    const refreshProfile = async () => {
      try {
        if (!currentUserProfile) return
        const user = await fetchMe()
        setUserLoading(false)
        handleReceiveUserProfile(user)
      } catch (errO) {
        const err = errO as AxiosError
        console.error(err)
        setUserLoading(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message
        )
      }
    }

    refreshProfile()
  }, [currentUserProfile])

  const handleSave = () => {
    setSaving(true)
    currentUserProfile &&
      updateUser(currentUserProfile.uuid, {
        firstname,
        lastname,
      })
        .then((user) => {
          handleReceiveUserProfile(user)
          setSaving(false)
          dispatch(addSnackbar({ text: "User updated." }))
          dispatch(setCurrentUserProfile(user))
        })
        .catch((err) => {
          console.error(err)
          setSaving(false)
          setErrorMessage(
            (err.response && err.response.data && err.response.data.error) ||
              err.message
          )
        })
  }

  return (
    <div>
      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error" onClose={() => setErrorMessage("")}>
            {errorMessage}
          </Alert>
        </Stack>
      )}

      <HeadingContainer>
        <Typography component="h1" variant="h6" color="textPrimary" noWrap>
          Profile{currentUserProfile && ` - ${currentUserProfile.email}`}
        </Typography>
      </HeadingContainer>

      <Divider />

      <Container maxWidth="md">
        <Paper sx={{ mt: 2, p: 4 }}>
          {userLoading && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          )}
          {!userLoading && (
            <FormGroup>
              <Box component="form" noValidate>
                <Grid container columns={12} spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      value={firstname || ""}
                      label="First Name"
                      onChange={(e) => {
                        setDirty(true)
                        setFirstname(e.target.value)
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="family-name"
                      name="lastName"
                      required
                      fullWidth
                      id="lastName"
                      value={lastname || ""}
                      label="Last Name"
                      onChange={(e) => {
                        setDirty(true)
                        setLastname(e.target.value)
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled
                            checked={Boolean(
                              currentUserProfile &&
                                currentUserProfile.emailConfirmed
                            )}
                          />
                        }
                        label="Email confirmed?"
                      />
                    </FormGroup>

                    {(!currentUserProfile ||
                      !currentUserProfile.emailConfirmed) && (
                      <LoadingButton
                        onClick={handleSendConfirmationEmail}
                        loading={sendingConfirmationEmail}
                        variant="contained"
                        sx={{ mt: 1 }}
                        color="warning"
                      >
                        <LocalActivityIcon color="inherit" sx={{ mr: 1 }} />
                        Resend confirmation email
                      </LoadingButton>
                    )}
                  </Grid>

                  <Grid item xs={12} sx={{ mt: 1 }}>
                    <LoadingButton
                      fullWidth
                      onClick={handleSave}
                      disabled={!isDirty || !user}
                      loading={isSaving || userLoading}
                      variant="contained"
                    >
                      Save
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Box>
            </FormGroup>
          )}
        </Paper>
      </Container>
    </div>
  )
}
