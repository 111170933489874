import React, { PropsWithChildren } from 'react'

import window from '../lib/window'

const { localStorage } = window

const CouponCatcher = ({ children }: PropsWithChildren<unknown>) => {
  const urlSearchParams = new URLSearchParams(
    window.location && window.location.search
  )
  const params = Object.fromEntries(urlSearchParams.entries())

  // capture couponCode in local storage
  React.useEffect(() => {
    if (params.couponCode || params.couponCode === '')
      localStorage.setItem('couponCode', params.couponCode)
  })

  return <>{children}</>
}

export default CouponCatcher
