import { useCallback } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import Stack from "@mui/material/Stack"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import oauth2Decision from "../../api/oauth2/decision"
import AppBar from "../../components/AppBar"
import Copyright from "../../components/Copyright"
import { useAppDispatch, useAppSelector } from "../../redux"
import { addSnackbar } from "../../redux/snackbars"

export default function AuthorizeOauth2() {
  // const navigate = useNavigate()
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  const [searchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const acceptAuthorization = useCallback(async () => {
    try {
      // const code = searchParams.get("code")
      const transactionId = searchParams.get("transactionID")
      if (!transactionId) throw new Error("No transactionId")
      if (transactionId) {
        await oauth2Decision(transactionId)
        // navigate(`/app/oauth2/accept?code=${code}`)
      }
    } catch (err) {
      console.error("Error completing oauth2 decision", err)
      dispatch(addSnackbar({ text: "Error completing oauth2 decision." }))
    }
  }, [dispatch, searchParams])

  return (
    <>
      <AppBar title="Trimmr" />

      <Toolbar />

      <Container component="main" maxWidth="xs">
        <Stack
          sx={{ pt: 4 }}
          direction="column"
          spacing={4}
          justifyContent="center"
        >
          <Typography variant="h5" color="text.primary">
            Would you like to connect to Zapier?
          </Typography>
          <Typography variant="body1" color="text.primary">
            Zapier will have permission to import videos to your account and
            create content ideas and rendered videos on your behalf. These will
            count towards your usage account's limit, which is set by your
            subscription level.
          </Typography>

          {
            <img
              src={
                darkModeEnabled
                  ? "/img/zapier-logo_white.svg"
                  : "/img/zapier-logo_black.svg"
              }
            />
          }
          <Stack
            sx={{ pt: 4 }}
            direction="row"
            spacing={2}
            justifyContent="center"
          >
            <Button
              size="large"
              onClick={() => acceptAuthorization()}
              variant="contained"
            >
              Yes, grant permissions to Zapier
            </Button>
            <Button
              size="large"
              onClick={() => navigate("/app/account/video-creator")}
              variant="outlined"
            >
              Cancel
            </Button>
          </Stack>
        </Stack>

        <Copyright sx={{ mt: 5 }} />
      </Container>
    </>
  )
}
