/* eslint-disable indent */
import { v4 as uuidv4 } from 'uuid'

import {
  BaseVideoSize,
  BaseVideoVerticalAlignment,
  PresetBaseVideoParams,
  PresetRenderAttributes,
} from './Preset'
import {
  HorizontalAlignmentOption,
  RenderJobAsset,
  TextAnimationOption,
  VerticalAlignmentOption,
} from './RenderJobAsset'
import { SRTWithWordTimings } from './SRT/SRTWithWordTimings'
import { TextStyleAttributes } from './TextStyle'

type RenderJobBaseVideoParamKeys =
  | 'baseVideoXPosition'
  | 'baseVideoYPosition'
  | 'baseVideoXScale'
  | 'baseVideoYScale'

interface RenderJobBaseVideoParams {
  // render job initial params may be based on a render job or a preset
  // these properties will be populated if created from render job
  baseVideoXPosition?: number
  baseVideoYPosition?: number
  baseVideoXScale?: number
  baseVideoYScale?: number
}

export interface RenderJobInitialParams
  extends Omit<PresetRenderAttributes, PresetBaseVideoParams>,
    RenderJobBaseVideoParams {
  uuid: string

  usedContentAI?: boolean
  startSeconds?: number
  durationSeconds?: number

  contentAIExplanation?: string
  aiTextChoice?: string

  // these properties will be populated if created from preset
  baseVideoVerticalAlignment?: BaseVideoVerticalAlignment
  baseVideoSize?: BaseVideoSize

  srtWithWordTimings?: SRTWithWordTimings[]
  renderJobAssets?: RenderJobAsset[]
}

export interface RenderJob extends TextStyleAttributes {
  uuid: string
  completed: boolean
  startSeconds: number
  durationSeconds: number
  adjustStart: boolean
  adjustEnd: boolean
  usedContentAI: boolean
  thumbnailURL: string
  createdAt: string
  completedAt?: string
  importJobUuid: string
  failedReason?: string
  failed: boolean
  videoURL: string

  finalDurationSeconds?: number
  finalStartSeconds?: number

  contentAIExplanation?: string
  captionData?: string
  captionDataWithWordTimings?: string
  aiTextChoice?: string
  resolutionWidth?: number
  resolutionHeight?: number
  paddingVerticalPx?: number
  paddingHorizontalPx?: number

  importJobOriginalWidth?: number
  importJobOriginalHeight?: number

  srtWithWordTimings?: SRTWithWordTimings[]

  baseVideoXPosition: number
  baseVideoYPosition: number
  baseVideoXScale: number
  baseVideoYScale: number
  baseVideoZIndex: number

  notifyOnComplete?: boolean

  renderJobAssets?: RenderJobAsset[]

  user: {
    email?: string
  }
  importJob: {
    name?: string
    youtubeName?: string
    youtubeURL?: string
  }
}

export const renderJobToRenderJobInitialParams = (
  renderJob: RenderJob
): RenderJobInitialParams => ({
  uuid: renderJob.uuid,
  fontColor: renderJob.fontColor || defaultRenderJobInitialParams.fontColor,
  fontName: renderJob.fontName || defaultRenderJobInitialParams.fontName,
  strokeWidth:
    renderJob.strokeWidth || defaultRenderJobInitialParams.strokeWidth,
  strokeColor:
    renderJob.strokeColor || defaultRenderJobInitialParams.strokeColor,
  textHighlightColor:
    renderJob.textHighlightColor ||
    defaultRenderJobInitialParams.textHighlightColor,
  verticalCaptionAlignment:
    renderJob.verticalCaptionAlignment ||
    defaultRenderJobInitialParams.verticalCaptionAlignment,
  horizontalCaptionAlignment:
    renderJob.horizontalCaptionAlignment ||
    defaultRenderJobInitialParams.horizontalCaptionAlignment,
  paddingVerticalPx:
    renderJob.paddingVerticalPx ||
    defaultRenderJobInitialParams.paddingVerticalPx,
  paddingHorizontalPx:
    renderJob.paddingHorizontalPx ||
    defaultRenderJobInitialParams.paddingHorizontalPx,
  fontSizePx: renderJob.fontSizePx || defaultRenderJobInitialParams.fontSizePx,
  oneWordAtATime:
    renderJob.oneWordAtATime || defaultRenderJobInitialParams.oneWordAtATime,
  textShadow: renderJob.textShadow || defaultRenderJobInitialParams.textShadow,
  textAnimation:
    renderJob.textAnimation || defaultRenderJobInitialParams.textAnimation,
  textCapitalization:
    renderJob.textCapitalization ||
    defaultRenderJobInitialParams.textCapitalization,
  resolutionWidth:
    renderJob.resolutionWidth || defaultRenderJobInitialParams.resolutionWidth,
  resolutionHeight:
    renderJob.resolutionHeight ||
    defaultRenderJobInitialParams.resolutionHeight,
  backgroundColor:
    renderJob.backgroundColor || defaultRenderJobInitialParams.backgroundColor,

  startSeconds: renderJob.startSeconds,
  durationSeconds: renderJob.durationSeconds,

  // unused here
  baseVideoVerticalAlignment:
    defaultRenderJobInitialParams.baseVideoVerticalAlignment,
  baseVideoSize: defaultRenderJobInitialParams.baseVideoSize,

  baseVideoXPosition: renderJob.baseVideoXPosition,
  baseVideoYPosition: renderJob.baseVideoYPosition,
  baseVideoXScale: renderJob.baseVideoXScale,
  baseVideoYScale: renderJob.baseVideoYScale,
  baseVideoZIndex: renderJob.baseVideoZIndex,

  renderJobAssets:
    renderJob.renderJobAssets ||
    defaultRenderJobInitialParams.renderJobAssets ||
    [],
})

const blurShadow = (scale: number, color: string): string => {
  return Array(scale).fill(`0px 3px 30px ${color}`).join(',')
}
const reliefShadow = (scale: number, color: string): string => {
  return `-${scale}px ${scale}px 0px ${color}`
}

export const textShadowOptions = [
  {
    name: 'Black Blur Light',
    value: blurShadow(1, 'black'),
  },
  {
    name: 'Black Blur Medium',
    value: blurShadow(3, 'black'),
  },
  {
    name: 'Black Blur Strong',
    value: blurShadow(5, 'black'),
  },
  {
    name: 'White Blur Light',
    value: blurShadow(2, 'white'),
  },
  {
    name: 'White Blur Medium',
    value: blurShadow(4, 'white'),
  },
  {
    name: 'White Blur Strong',
    value: blurShadow(6, 'white'),
  },
  {
    name: 'Relief Black',
    value: reliefShadow(10, 'black'),
  },
  {
    name: 'Relief White',
    value: reliefShadow(10, 'white'),
  },
  {
    name: 'None',
    value: '',
  },
]

export const defaultRenderJobInitialParams: AllRequired<
  Omit<RenderJobInitialParams, RenderJobBaseVideoParamKeys>
> &
  RenderJobBaseVideoParams = {
  uuid: uuidv4(),
  usedContentAI: false,
  startSeconds: 0,
  durationSeconds: 10,

  fontColor: '#FFFFFF',
  fontName: 'AlfaSlabOne-Regular',
  strokeWidth: 20,
  textHighlightColor: '#5FFF93',
  strokeColor: '#000',
  backgroundColor: '#333',

  baseVideoVerticalAlignment: 'middle',
  baseVideoSize: 'content-fit-vertical',
  baseVideoZIndex: 0,

  srtWithWordTimings: [],
  renderJobAssets: [],
  contentAIExplanation: '',
  aiTextChoice: '',

  resolutionWidth: 1080,
  resolutionHeight: 1920,

  verticalCaptionAlignment: 'middle',
  horizontalCaptionAlignment: 'center',
  paddingVerticalPx: 220,
  paddingHorizontalPx: 160,
  fontSizePx: 80,
  oneWordAtATime: true,
  textShadow: textShadowOptions[0].value,
  textAnimation: 'none',
  textCapitalization: 'uppercase',
}

interface Resolution {
  id: string
  name: string
  height: number
  width: number
}

export const resolutionOptions: Resolution[] = [
  {
    id: '1080x1920',
    name: '1080x1920 (Youtube Short)',
    height: 1920,
    width: 1080,
  },
  { id: '1200x1800', name: '1200x1800 (Pinterest)', height: 1800, width: 1200 },
  { id: '1920x1080', name: '1920x1080', height: 1080, width: 1920 },
  { id: '500x500', name: '500x500', height: 500, width: 500 },
]

export interface VerticalAlignment {
  id: VerticalAlignmentOption
  name: string
}

export interface HorizontalAlignment {
  id: HorizontalAlignmentOption
  name: string
}
export interface TextAnimation {
  id: TextAnimationOption
  name: string
}

// rendered in order shown
export const verticalAlignmentOptions: VerticalAlignment[] = [
  { id: 'top', name: 'Top' },
  { id: 'middle', name: 'Middle' },
  { id: 'bottom', name: 'Bottom' },
]

export const verticalAlignmentOptionIdToName = (
  id: VerticalAlignmentOption
): string => {
  const option = verticalAlignmentOptions.find(
    (option) => option.id === id
  ) as VerticalAlignment
  return option.name || 'Unknown'
}

// rendered in order shown
export const horizontalAlignmentOptions: HorizontalAlignment[] = [
  { id: 'left', name: 'Left' },
  { id: 'center', name: 'Center' },
  { id: 'right', name: 'Right' },
]

export const horizontalAlignmentOptionIdToName = (
  id: HorizontalAlignmentOption
): string => {
  const option = horizontalAlignmentOptions.find(
    (option) => option.id === id
  ) as HorizontalAlignment
  return option.name || 'Unknown'
}

export const textAnimationOptions: TextAnimation[] = [
  { id: 'fade-in', name: 'Fade In' },
  { id: 'pop-in', name: 'Pop In' },
  { id: 'slide-in', name: 'Slide In' },
  { id: 'none', name: 'None' },
]

export const textAnimationIdToName = (id: TextAnimationOption): string => {
  const option = textAnimationOptions.find((option) => option.id === id)
  if (!option) return 'None'
  return option.name
}

export interface CreateRenderJobParams
  extends Omit<PresetRenderAttributes, PresetBaseVideoParams> {
  importJobUuid: string
  notifyOnComplete?: boolean
  startSeconds: number
  durationSeconds: number
  usedContentAI: boolean
  contentAIExplanation?: string
  aiTextChoice?: string
  captionsSRT?: string
  captionsSRTWithWordTimings?: string

  importJobOriginalWidth: number
  importJobOriginalHeight: number

  baseVideoXPosition: number
  baseVideoYPosition: number
  baseVideoXScale: number
  baseVideoYScale: number
}
