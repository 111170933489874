import { PresetRenderAttributes } from "@trimmr/trimmr-lib/types/Preset"

import { API_BASE_URL } from "../../consts"
import axios from "../axios"

export interface CreatePresetParams extends PresetRenderAttributes {
  name: string
}

export default function createPreset(
  params: CreatePresetParams,
  thumbnail: Blob
) {
  let body: CreatePresetParams | FormData = params
  if (thumbnail) {
    const formData = new FormData()

    if (params.name) formData.append("name", params.name)
    if (params.fontColor) formData.append("fontColor", params.fontColor)
    if (params.fontName) formData.append("fontName", params.fontName)
    if (params.strokeWidth)
      formData.append("strokeWidth", String(params.strokeWidth))
    if (params.strokeColor) formData.append("strokeColor", params.strokeColor)
    if (params.verticalCaptionAlignment)
      formData.append(
        "verticalCaptionAlignment",
        params.verticalCaptionAlignment
      )
    if (params.horizontalCaptionAlignment)
      formData.append(
        "horizontalCaptionAlignment",
        params.horizontalCaptionAlignment
      )
    if (params.paddingVerticalPx)
      formData.append("paddingVerticalPx", String(params.paddingVerticalPx))
    if (params.paddingHorizontalPx)
      formData.append("paddingHorizontalPx", String(params.paddingHorizontalPx))
    if (params.fontSizePx)
      formData.append("fontSizePx", String(params.fontSizePx))
    if (params.oneWordAtATime)
      formData.append("oneWordAtATime", String(params.oneWordAtATime))
    if (params.textHighlightColor)
      formData.append("textHighlightColor", params.textHighlightColor)
    if (params.textShadow) formData.append("textShadow", params.textShadow)
    if (params.textAnimation)
      formData.append("textAnimation", params.textAnimation)
    if (params.textCapitalization)
      formData.append("textCapitalization", params.textCapitalization)
    if (params.resolutionWidth)
      formData.append("resolutionWidth", String(params.resolutionWidth))
    if (params.resolutionHeight)
      formData.append("resolutionHeight", String(params.resolutionHeight))
    if (params.backgroundColor)
      formData.append("backgroundColor", params.backgroundColor)
    if (params.baseVideoVerticalAlignment)
      formData.append(
        "baseVideoVerticalAlignment",
        params.baseVideoVerticalAlignment
      )
    if (params.baseVideoSize)
      formData.append("baseVideoSize", params.baseVideoSize)
    if (params.baseVideoZIndex !== undefined)
      formData.append("baseVideoZIndex", String(params.baseVideoZIndex))
    if (params.renderJobAssets)
      formData.append("renderJobAssets", JSON.stringify(params.renderJobAssets))

    formData.append("file", thumbnail, "thumbnail.jpg")
    body = formData
  }
  return axios
    .post(`${API_BASE_URL}/presets`, body)
    .then((response) => response.data)
}
