import { AxiosResponse } from "axios"

import { Preset } from "@trimmr/trimmr-lib/types/Preset"

import { API_BASE_URL } from "../../consts"
import axios from "../axios"

export interface SearchPresetParams {
  page: number
  perPage: number
  userUuid?: string
  email?: string
  public?: boolean
  mine?: boolean
  name?: string
  asAdmin?: boolean
}

export default async function searchPresets(
  {
    page,
    perPage,
    userUuid,
    email,
    public: publicPresets,
    mine: mine,
    name: presetName,
    asAdmin,
  }: SearchPresetParams,
  signal?: AbortSignal
): Promise<{
  presets: Preset[]
  totalPages: number
  totalResults: number
}> {
  const params = new URLSearchParams()

  asAdmin && params.set("asAdmin", "true")
  page && params.set("page", String(page))
  perPage && params.set("perPage", String(perPage))
  userUuid && params.set("userUuid", String(userUuid))
  email && params.set("email", String(email))
  presetName && params.set("name", String(presetName))

  if (mine) {
    params.set("mine", "true")
  }

  if (publicPresets) {
    publicPresets && params.set("public", "true")
  }

  const response = await axios.get<void, AxiosResponse>(
    `${API_BASE_URL}/presets?${params.toString()}`,
    { signal }
  )

  return {
    presets: response.data as Preset[],
    totalPages: Number(response.headers["x-total-pages"]),
    totalResults: Number(response.headers["x-total-results"]),
  }
}
