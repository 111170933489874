import { Alert, styled } from "@mui/material"

const SmallAlert = styled(Alert)(() => ({
  "& .MuiAlert-message": {
    padding: 0,
  },
  "& .MuiAlert-icon": {
    padding: "3px 0",
  },
}))

export default SmallAlert
