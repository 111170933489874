import { SRTWithWordTimings } from "@trimmr/trimmr-lib/types/SRT/SRTWithWordTimings"

import { MAX_WORD_ANIMATION_DURATION } from "../constants"

const getPhraseProgressDecimalWithWordTimings = (
  oneWordAtATime: boolean,
  currentSRT: SRTWithWordTimings,
  startSeconds: number,
  endSeconds: number,
  currentSeconds: number
) => {
  if (oneWordAtATime) return 1

  const actualCurrentSeconds = currentSeconds + startSeconds

  const segmentStartSeconds = Math.max(
    startSeconds,
    startSeconds + (currentSRT.start || 0) // SRT is in times relative to start
  )
  const segmentEndSeconds = Math.min(
    endSeconds,
    startSeconds + (currentSRT.end || 0) // SRT is in times relative to start
  )

  const phraseDuration = segmentEndSeconds - segmentStartSeconds

  const phraseAnimationSeconds = Math.min(
    MAX_WORD_ANIMATION_DURATION,
    phraseDuration
  )

  const progressDecimal = Math.max(
    0,
    Math.min(
      1,
      (actualCurrentSeconds - segmentStartSeconds) /
        (segmentEndSeconds - segmentStartSeconds) /
        phraseAnimationSeconds
    )
  )

  return progressDecimal
}
export default getPhraseProgressDecimalWithWordTimings
