import * as React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

import EmailIcon from "@mui/icons-material/Email"
import Alert from "@mui/material/Alert"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import AppBar from "../../components/AppBar"
import Footer from "../../components/Footer"
import FullPageContentContainer from "../../components/FullPageContentContainer"
import window from "../../lib/window"
import ContactForm from "./components/ContactForm"

export default function Contact() {
  const [errorMessage, setErrorMessage] = React.useState("")

  return (
    <>
      <AppBar title="Trimmr" />

      <Toolbar />

      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error" onClose={() => setErrorMessage("")}>
            {errorMessage}
          </Alert>
        </Stack>
      )}

      <FullPageContentContainer maxWidth="xs">
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <EmailIcon />
          </Avatar>

          <Typography
            component="h1"
            variant="h5"
            color="text.primary"
            sx={{ mb: 2 }}
          >
            Contact
          </Typography>

          <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>
            Please reach out if you have any questions or need any support.
            Trimmr is constantly being updated and improved, and we'd love to
            hear your feedback!
          </Typography>
          <Typography
            variant="body1"
            color="text.primary"
            sx={{ mb: 2 }}
            width="100%"
          >
            You should hear back from us within a day.
          </Typography>

          <GoogleReCaptchaProvider reCaptchaKey={window.RECAPTCHA_SITE_KEY}>
            <ContactForm setErrorMessage={setErrorMessage} hideDevice />
          </GoogleReCaptchaProvider>
        </Box>
      </FullPageContentContainer>

      <Footer />
    </>
  )
}
