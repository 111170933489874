import { createSlice } from "@reduxjs/toolkit"

import { CurrentUserProfile } from "../../types/CurrentUserProfile"

export const currentUserProfileSlice = createSlice({
  name: "currentUserProfile",
  initialState: null as CurrentUserProfile | null,
  reducers: {
    setCurrentUserProfile: (_, action) => {
      return action.payload
        ? // serialize
          (JSON.parse(JSON.stringify(action.payload)) as CurrentUserProfile)
        : null
    },
  },
})

export const { setCurrentUserProfile } = currentUserProfileSlice.actions

export default currentUserProfileSlice.reducer
