import { AxiosResponse } from "axios"

import { Asset } from "@trimmr/trimmr-lib/types/Asset"

import { API_BASE_URL } from "../../consts"
import axios from "../axios"

export interface SearchAssetParams {
  page: number
  perPage: number
  userUuid?: string
  email?: string
  public?: boolean
  mine?: boolean
  name?: string
  asAdmin?: boolean
}

export default async function searchAssets(
  {
    page,
    perPage,
    userUuid,
    email,
    public: publicAssets,
    mine: mine,
    name: assetName,
    asAdmin,
  }: SearchAssetParams,
  signal?: AbortSignal
): Promise<{
  assets: Asset[]
  totalPages: number
  totalResults: number
}> {
  const params = new URLSearchParams()

  asAdmin && params.set("asAdmin", String("true"))
  page && params.set("page", String(page))
  perPage && params.set("perPage", String(perPage))
  userUuid && params.set("userUuid", String(userUuid))
  email && params.set("email", String(email))
  assetName && params.set("name", String(assetName))

  if (mine) {
    params.set("mine", "true")
  }

  if (publicAssets) {
    publicAssets && params.set("public", "true")
  }

  const response = await axios.get<void, AxiosResponse>(
    `${API_BASE_URL}/assets?${params.toString()}`,
    { signal }
  )

  return {
    assets: response.data as Asset[],
    totalPages: Number(response.headers["x-total-pages"]),
    totalResults: Number(response.headers["x-total-results"]),
  }
}
