import * as React from "react"

import { useElementSize } from "usehooks-ts"

import {
  HorizontalAlignmentOption,
  VerticalAlignmentOption,
} from "@trimmr/trimmr-lib/types/RenderJobAsset"
import { SRTWithWordTimings } from "@trimmr/trimmr-lib/types/SRT/SRTWithWordTimings"

import { Box } from "@mui/material"

import { horizontalAlignmentToFlex } from "../../../../components/CreateRenderJobModal/RemotionPreview/horizontalAlignmentToFlex"

const verticalAlignmentToFlex = {
  top: "flex-start",
  middle: "center",
  bottom: "flex-end",
}

export const AbsolutelyPositionedPreviewText: React.FC<{
  verticalCaptionAlignment: VerticalAlignmentOption
  horizontalCaptionAlignment: HorizontalAlignmentOption
  videoResolutionWidth: number
  videoResolutionHeight: number
  fontColor: string
  strokeWidth: number
  strokeColor: string
  fontName: string
  oneWordAtATime: boolean
  textHighlightColor: string
  textShadow: string
  textCapitalization: string
  fontSizePx: number
  paddingVerticalPx: number
  paddingHorizontalPx: number
  startSeconds?: number
  srtWithWordTimings?: SRTWithWordTimings[]
  showDefaultCaption?: boolean
}> = ({
  verticalCaptionAlignment,
  horizontalCaptionAlignment,
  videoResolutionWidth,
  videoResolutionHeight,
  fontColor,
  strokeWidth,
  strokeColor,
  fontName,
  oneWordAtATime,
  textHighlightColor,
  textShadow,
  textCapitalization,
  fontSizePx,
  paddingVerticalPx,
  paddingHorizontalPx,
  startSeconds = 0.5,
  srtWithWordTimings = [],
  showDefaultCaption,
}) => {
  const [ref, { width }] = useElementSize()

  const currentSRT = srtWithWordTimings?.find(
    (srt) => (srt.start || 0) <= startSeconds && (srt.end || 0) >= startSeconds
  )

  const currentRenderScale = width / videoResolutionWidth

  const text = currentSRT?.words.map((w) => w.text).join(" ")
  const words = (
    text || (showDefaultCaption ? "Preview caption style" : "")
  ).split(" ")
  const fontSize = fontSizePx

  return (
    <Box
      ref={ref}
      height="100%"
      style={{
        position: "absolute",
        inset: 0,
        zIndex: 1,
      }}
    >
      <Box
        style={{
          width: videoResolutionWidth,
          height: videoResolutionHeight,
          transform: `scale(${currentRenderScale}, ${currentRenderScale})`,
          transformOrigin: "top left",
        }}
      >
        <Box
          width="100%"
          height="100%"
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent={horizontalAlignmentToFlex[horizontalCaptionAlignment]}
          alignItems={verticalAlignmentToFlex[verticalCaptionAlignment]}
          style={{
            paddingTop: `${paddingVerticalPx}px`,
            paddingBottom: `${paddingVerticalPx}px`,
            paddingLeft: `${paddingHorizontalPx}px`,
            paddingRight: `${paddingHorizontalPx}px`,
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent={
              horizontalAlignmentToFlex[horizontalCaptionAlignment]
            }
            alignItems={verticalAlignmentToFlex[verticalCaptionAlignment]}
          >
            {words.map((word, index) => {
              const renderWord = () => (
                <span style={{ display: "block", width: "max-content" }}>
                  {word}
                  {/* for right align better to always have */}
                  {/* {index + 1 !== words.length && <>&nbsp;</>} */}
                  <>&nbsp;</>
                </span>
              )

              return (
                <span
                  key={index}
                  style={{
                    fontSize,
                    lineHeight: 1,
                    fontFamily: fontName,
                    wordBreak: "break-word",
                    position: "relative",
                    zIndex: 0,
                    color:
                      oneWordAtATime && index + 1 === words.length
                        ? textHighlightColor
                        : fontColor,
                    textTransform:
                      textCapitalization === "uppercase" ? "uppercase" : "none",
                  }}
                >
                  {/* invisible word controls width when all visual elements are absolutely positioned */}
                  {/* if not one word at a time, we want to shrink all elements together and not keep the big placeholder */}
                  <span
                    style={{
                      opacity: 0,
                      fontSize,
                    }}
                  >
                    {renderWord()}
                  </span>

                  <span
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      zIndex: 0,
                    }}
                  >
                    {renderWord()}
                  </span>

                  <span
                    style={{
                      position: "absolute",
                      zIndex: -1,
                      WebkitTextStrokeWidth: `${strokeWidth}px`,
                      WebkitTextStrokeColor: strokeColor,
                      left: 0,
                      top: 0,
                      bottom: 0,
                      right: 0,
                      fontSize,
                    }}
                  >
                    {renderWord()}
                  </span>

                  {/* on iOS stroke plus shadow is broken, so use a separate element for shadow */}
                  <span
                    style={{
                      position: "absolute",
                      zIndex: -2,
                      textShadow,

                      left: 0,
                      top: 0,
                      bottom: 0,
                      right: 0,
                      fontSize,
                    }}
                  >
                    {renderWord()}
                  </span>
                </span>
              )
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
