import * as React from "react"

import { AbsoluteFill, Experimental, useCurrentFrame } from "remotion"

import { RenderJobAsset } from "@trimmr/trimmr-lib/types/RenderJobAsset"
// import { SRT } from "@trimmr/trimmr-lib/types/SRT"
import { SRTWithWordTimings } from "@trimmr/trimmr-lib/types/SRT/SRTWithWordTimings"
import { addSecondsToSRTWithWordTimings } from "@trimmr/trimmr-lib/types/SRT/addSecondsToSRT"
import { TextStyleAttributes } from "@trimmr/trimmr-lib/types/TextStyle"

import { Box, CircularProgress, Typography } from "@mui/material"

import { BufferContext } from "./BufferManager"
import {
  DraggableRenderJobAsset,
  DraggableStyles,
} from "./DraggableRenderJobAsset"
import { SubtitleWithWordTimings } from "./SubtitleWithWordTimings"
import { FPS } from "./constants"
import "./load-fonts"

export type VideoCompositionProps = {
  videoURL: string
  thumbnailURL?: string
  startSeconds: number
  endSeconds: number

  renderWatermark?: boolean

  textStyleAttributes: TextStyleAttributes

  paddingVerticalPx: number
  paddingHorizontalPx: number
  importJobOriginalWidth: number
  importJobOriginalHeight: number

  baseVideoXPosition: number
  baseVideoYPosition: number
  baseVideoXScale: number
  baseVideoYScale: number
  setBaseVideoPosition?: (baseVideoPosition: {
    baseVideoXPosition: number
    baseVideoYPosition: number
    baseVideoXScale: number
    baseVideoYScale: number
  }) => void
  baseVideoZIndex: number

  resolutionWidth: number
  resolutionHeight: number

  // the draggable and resize components need to know
  // how much we've scaled down the UI
  currentRenderScale?: number

  renderJobAssets: RenderJobAsset[]
  handleRenderJobAssetMove?: (
    renderJobAssetUuid: string,
    newX: number,
    newY: number,
    newXScale: number,
    newYScale: number
  ) => void
  isInEditMode?: boolean

  srtWithWordTimings?: SRTWithWordTimings[]
  srtWithWordTimingsLoading?: boolean
  buffering?: boolean
}

const VideoComposition: React.FC<VideoCompositionProps> = ({
  videoURL,
  startSeconds,
  endSeconds,

  textStyleAttributes,

  paddingVerticalPx,
  paddingHorizontalPx,
  importJobOriginalWidth,
  importJobOriginalHeight,

  baseVideoXPosition,
  baseVideoYPosition,
  baseVideoXScale,
  baseVideoYScale,
  setBaseVideoPosition,
  baseVideoZIndex,

  resolutionWidth,
  resolutionHeight,

  currentRenderScale = 1,
  renderJobAssets,
  handleRenderJobAssetMove,
  isInEditMode,

  srtWithWordTimings,
  buffering,
  srtWithWordTimingsLoading,
}) => {
  const video = React.useRef<HTMLVideoElement | null>(null)
  const { canPlay, needsToBuffer } = React.useContext(BufferContext)

  const isPlayer = Experimental.useIsPlayer()
  const frame = useCurrentFrame()

  const id = videoURL

  React.useEffect(() => {
    const { current } = video

    if (!current) {
      return
    }

    const onPlay = () => {
      canPlay(id)
    }

    const onBuffer = () => {
      needsToBuffer(id)
    }

    current.addEventListener("canplay", onPlay)
    current.addEventListener("waiting", onBuffer)

    return () => {
      current.removeEventListener("canplay", onPlay)
      current.removeEventListener("waiting", onBuffer)
      // current.removeEventListener("onloadedmetadata", onLoadedMetaData)

      // If component is unmounted, unblock the buffer manager
      canPlay(id)
    }
  }, [
    canPlay,
    id,
    needsToBuffer,
    resolutionHeight,
    resolutionWidth,
    setBaseVideoPosition,
    videoURL,
  ])

  // we'll be rendering base video at some index
  const renderJobAssetsFirst = renderJobAssets.slice(0, baseVideoZIndex)
  const renderJobAssetsSecond = renderJobAssets.slice(baseVideoZIndex)

  const currentTime = frame / FPS

  const renderJobAssetsSubset = (renderJobAssetsSubset: RenderJobAsset[]) => {
    return renderJobAssetsSubset
      ?.filter((renderJobAsset) => {
        // protect against old data where these aren't set
        if (
          renderJobAsset.startTimeSeconds === null ||
          renderJobAsset.startTimeSeconds === undefined
        )
          return true
        if (
          renderJobAsset.endTimeSeconds === null ||
          renderJobAsset.endTimeSeconds === undefined
        )
          return true
        return (
          renderJobAsset.startTimeSeconds <= currentTime &&
          renderJobAsset.endTimeSeconds >= currentTime
        )
      })
      .map((renderJobAsset) => (
        <DraggableRenderJobAsset
          assetUrl={renderJobAsset.asset?.assetUrl}
          key={renderJobAsset.uuid}
          isInEditMode={Boolean(isInEditMode)}
          xPosition={renderJobAsset.xPosition || 0}
          yPosition={renderJobAsset.yPosition || 0}
          xScale={renderJobAsset.xScale || 1}
          yScale={renderJobAsset.yScale || 1}
          textStyle={renderJobAsset.asset?.textStyle}
          currentRenderScale={currentRenderScale}
          originalWidth={renderJobAsset.asset?.originalWidth || 1}
          originalHeight={renderJobAsset.asset?.originalHeight || 1}
          loadingMessage="Loading asset..."
          onPositionChange={(newX, newY, newXScale, newYScale) => {
            handleRenderJobAssetMove &&
              handleRenderJobAssetMove(
                renderJobAsset.uuid,
                newX,
                newY,
                newXScale,
                newYScale
              )
          }}
          muted={Boolean(renderJobAsset.muted)}
          isPlayer={isPlayer}
          originalDuration={renderJobAsset.asset?.originalDuration || 1}
          type={renderJobAsset.asset?.type || "IMAGE"}
          asset={renderJobAsset.asset}
        />
      ))
  }

  return (
    <AbsoluteFill
      style={{ backgroundColor: textStyleAttributes.backgroundColor }}
    >
      <DraggableStyles
        style={{
          position: "absolute",
          inset: 0,
          zIndex: 2,
        }}
        hideDraggable={!isInEditMode}
      >
        {renderJobAssetsSubset(renderJobAssetsFirst)}

        <DraggableRenderJobAsset
          assetUrl={videoURL}
          isInEditMode={Boolean(isInEditMode)}
          xPosition={baseVideoXPosition || 0}
          yPosition={baseVideoYPosition || 0}
          xScale={baseVideoXScale || 1}
          yScale={baseVideoYScale || 1}
          currentRenderScale={currentRenderScale}
          originalWidth={importJobOriginalWidth}
          originalHeight={importJobOriginalHeight}
          videoRef={video}
          startFrom={startSeconds * FPS}
          endAt={endSeconds * FPS}
          onPositionChange={(newX, newY, newXScale, newYScale) => {
            setBaseVideoPosition &&
              setBaseVideoPosition({
                baseVideoXPosition: newX,
                baseVideoYPosition: newY,
                baseVideoXScale: newXScale,
                baseVideoYScale: newYScale,
              })
          }}
          muted={false}
          isPlayer={isPlayer}
          originalDuration={endSeconds - startSeconds}
          type={"VIDEO"}
          loadingMessage="Loading imported video..."
        />

        {renderJobAssetsSubset(renderJobAssetsSecond)}
      </DraggableStyles>

      <AbsoluteFill style={{ zIndex: 3, pointerEvents: "none" }}>
        <SubtitleWithWordTimings
          frame={frame}
          srtWithWordTimings={
            srtWithWordTimings
              ? addSecondsToSRTWithWordTimings(srtWithWordTimings, startSeconds)
              : []
          }
          textStyleAttributes={textStyleAttributes}
          paddingVerticalPx={paddingVerticalPx}
          paddingHorizontalPx={paddingHorizontalPx}
          startSeconds={startSeconds}
          endSeconds={endSeconds}
        />
      </AbsoluteFill>

      {isPlayer && (buffering || !srtWithWordTimings) && (
        <AbsoluteFill
          style={{
            backgroundColor: "rgba(0,0,0,0.7)",
            zIndex: 4,
            pointerEvents: "none",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <CircularProgress size={100} style={{ color: "white" }} />
            <Typography
              variant="body1"
              sx={{ mt: 2, p: 2 }}
              fontSize="4rem"
              textAlign="center"
              style={{ color: "white" }}
            >
              {/* {buffering && "Buffering..."} */}
              {srtWithWordTimingsLoading && "Captions loading..."}
            </Typography>
          </Box>
        </AbsoluteFill>
      )}
    </AbsoluteFill>
  )
}

export default VideoComposition
