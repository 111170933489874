import { Dimensions } from "../../types/Dimensions"

export default function getContainedChildDimensions(
  container: Dimensions,
  child: Dimensions
): Dimensions {
  // Calculate the scale ratios for width and height
  const widthScale = container.width / child.width
  const heightScale = container.height / child.height

  // Use the smallest scale factor to ensure the child fits within the container
  const scale = Math.min(widthScale, heightScale)

  // Scale the child dimensions
  const newChildWidth = child.width * scale
  const newChildHeight = child.height * scale

  // Return the new child dimensions, ensuring they fit within the container
  return { width: newChildWidth, height: newChildHeight }
}
