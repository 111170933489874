import {
  BaseVideoSize,
  BaseVideoVerticalAlignment,
} from "@trimmr/trimmr-lib/types/Preset"

import { Box } from "@mui/material"

function AbsolutelyPositionedBaseVideo({
  baseVideoVerticalAlignment,
  thumbnailUrl,
  videoURL,
  baseVideoSize,
  startSeconds = 0,
}: {
  thumbnailUrl?: string
  videoURL?: string
  baseVideoVerticalAlignment: BaseVideoVerticalAlignment
  baseVideoSize: BaseVideoSize
  startSeconds?: number
}) {
  // const [ref, { width }] = useElementSize()
  // // const currentRenderScale = width / videoResolutionWidth
  // let childTop = baseVideoVerticalAlignment === "top" ? "0" : "auto"
  // if (baseVideoVerticalAlignment === "middle") childTop = "50%"

  // return (
  //   <Box style={{ position: "absolute", inset: 0 }} ref={ref}>

  //   </Box>
  // ))

  let flexVerticalAlignment = "center"
  switch (baseVideoVerticalAlignment) {
    case "top":
      flexVerticalAlignment = "flex-start"
      break
    case "bottom":
      flexVerticalAlignment = "flex-end"
      break
  }

  let height = "auto"
  switch (baseVideoSize) {
    case "content-fit-vertical":
      height = "100%"
      break
    case "half-height-vertical":
      height = "50%"
      break
    case "one-third-height-vertical":
      height = "34%"
      break
    case "two-thirds-height-vertical":
      height = "67%"
      break
  }

  const width = baseVideoSize === "content-fit-horizontal" ? "100%" : "auto"

  return (
    <Box
      style={{
        position: "absolute",
        inset: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: flexVerticalAlignment,
      }}
    >
      {videoURL && startSeconds !== undefined ? (
        <video
          style={{
            height,
            width,
          }}
          preload="metadata"
          poster="/img/media-loading.jpg"
        >
          <source src={`${videoURL}#t=${startSeconds}`} type="video/mp4" />
        </video>
      ) : (
        thumbnailUrl ? <img
          src={thumbnailUrl}
          className="idea-card-svg"
          style={{
            height,
            width: "100%",
          }}
        /> : <img
        className="idea-card-svg"
        style={{
          height,
          width: "100%",
        }}
      />
      )}
    </Box>
  )
}

export default AbsolutelyPositionedBaseVideo
