import * as React from "react"

import { v4 as uuidv4 } from "uuid"

import roundFloat from "@trimmr/trimmr-lib/helpers/math/roundFloat"
import {
  SRTWithWordTimings,
  WordWithTimings,
} from "@trimmr/trimmr-lib/types/SRT/SRTWithWordTimings"

import CancelIcon from "@mui/icons-material/Cancel"
import { Box, Grid, IconButton, Stack, Tooltip } from "@mui/material"
import TextField from "@mui/material/TextField"
import { styled } from "@mui/material/styles"

import { useAppSelector } from "../../redux"
import StartEndPickerTimeField from "../CreateRenderJobModal/StartEndPickerTimeField"
import { AddWordButton } from "./AddWordButton"

const innerPadding = "0.1rem 0.25rem 0.1rem 0.25rem"

export const SmallCaptionEditorWordField = styled(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    fontSize: "1rem",
    padding: 0,
  },
  "& .MuiInputBase-inputSizeSmall": {
    padding: innerPadding,
  },
  "& .MuiInputLabel-root": {
    top: "-4px",
  },
}))

export interface CaptionEditorRowWithWordTimingsProps {
  srtWithWordTimings: SRTWithWordTimings
  onSRTChange: (data: SRTWithWordTimings) => void
  onSRTRemove?: (index: number) => void
  disabled: boolean
}

export const CaptionEditorRowWithWordTimings: React.FC<
  CaptionEditorRowWithWordTimingsProps
> = ({ onSRTChange, onSRTRemove, disabled, srtWithWordTimings }) => {
  const { index, start, end, words } = srtWithWordTimings
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)

  const handleWordChange = React.useCallback(
    (wordIndex: number, start: number, text: string) => {
      const newWords = [...srtWithWordTimings.words]
      newWords[wordIndex].text = text
      newWords[wordIndex].start = start - (srtWithWordTimings.start || 0)

      onSRTChange({
        index,
        start: srtWithWordTimings.start,
        end: srtWithWordTimings.end,
        words: newWords,
      })
    },
    [
      index,
      onSRTChange,
      srtWithWordTimings.end,
      srtWithWordTimings.start,
      srtWithWordTimings.words,
    ]
  )

  const handleDeleteWord = React.useCallback(
    (wordIndex: number) => {
      const wordsArray = [...words]

      if (!wordsArray) return
      // SRT is 1-indexed
      wordsArray.splice(wordIndex, 1)

      onSRTChange({
        index,
        start: srtWithWordTimings.start,
        end: srtWithWordTimings.end,
        words: wordsArray.sort((a, b) => a.start - b.start),
      })
    },
    [
      index,
      onSRTChange,
      srtWithWordTimings.end,
      srtWithWordTimings.start,
      words,
    ]
  )

  const handleAddWord = React.useCallback(
    (startOrWordIndex: string | number) => {
      const wordsArray = [...words]
      let newWordsArray: WordWithTimings[] = wordsArray || []

      if (startOrWordIndex === newWordsArray.length) startOrWordIndex = "end"

      if (newWordsArray.length === 0) {
        newWordsArray = [
          {
            uuid: uuidv4(),
            text: "",
            start: 0,
          },
        ]
      } else if (startOrWordIndex === "start") {
        newWordsArray = [
          {
            uuid: uuidv4(),
            text: "",
            start: 0,
          },
          ...newWordsArray,
        ]
      } else if (startOrWordIndex === "end") {
        // const lastEl = newWordsArray[newWordsArray.length - 1]
        newWordsArray = [
          ...newWordsArray,
          {
            start: (end || 0) - (start || 0),
            uuid: uuidv4(),
            text: "",
          },
        ]
      } else if (typeof startOrWordIndex === "number") {
        // number will be position that new item is being inserted at

        const elementsBefore = newWordsArray.slice(0, startOrWordIndex)
        const elementsAfter = newWordsArray.slice(
          startOrWordIndex,
          newWordsArray.length
        )
        const nextElement = newWordsArray[startOrWordIndex + 1]
        const previousElement = newWordsArray[startOrWordIndex]
        const newElement = {
          uuid: uuidv4(),
          text: "",

          start: nextElement
            ? (previousElement.start + nextElement.start) / 2
            : previousElement.start + 0.3,
        }

        newWordsArray = [...elementsBefore, newElement, ...elementsAfter]
      }

      onSRTChange({
        index,
        start: srtWithWordTimings.start,
        end: srtWithWordTimings.end,
        words: newWordsArray.sort((a, b) => a.start - b.start),
      })

      // const newSrtObject = srtArrToObject(newWordsArray)
      // setSRTObject(newSrtObject)
      // setSRTArray && setSRTArray(Object.values(newSrtObject))
    },
    [
      end,
      index,
      onSRTChange,
      srtWithWordTimings.end,
      srtWithWordTimings.start,
      start,
      words,
    ]
  )

  const textFieldBackgroundColor = darkModeEnabled ? "rgba(0,0,0,0.1)" : "white"
  const buttonAreaBackgroundColor = darkModeEnabled
    ? "rgba(255,255,255,0.05)"
    : "rgba(0,0,0,0.05)"

  return (
    <Stack direction="row" alignItems="stretch" justifyContent="center">
      {onSRTRemove && !disabled && (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{
            backgroundColor: buttonAreaBackgroundColor,
            borderLeft: "1px solid grey",
            borderRight: "1px solid grey",
            zIndex: 4,
            maxWidth: "26px",
          }}
        >
          <Tooltip title="Delete phrase">
            <span>
              <IconButton
                onClick={() => onSRTRemove(index)}
                disabled={disabled}
                size="small"
              >
                <CancelIcon color="primary" />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
      )}

      <Box
        style={{
          width: "100%",
          overflow: "hidden",
        }}
        sx={{ pb: 0.5 }}
      >
        <Stack
          direction="column"
          alignItems="flex-start"
          justifySelf="stretch"
          sx={{ p: 1 }}
          style={{
            width: "100%",
            overflow: "hidden",
            // width: "100%",
            overflowX: "scroll",
          }}
          className="scrollable-row"
        >
          <Stack direction="row" gap="0.5rem">
            <StartEndPickerTimeField
              disabled={disabled}
              variant="outlined"
              size="small"
              innerPadding={innerPadding}
              name="start"
              id="start"
              style={{
                backgroundColor: textFieldBackgroundColor,
              }}
              value={roundFloat(start, 2)}
              label="Start"
              InputProps={{
                style: {
                  backgroundColor: textFieldBackgroundColor,
                  width: "150px",
                },
              }}
              onChange={(value) => {
                onSRTChange({
                  index,
                  start: value,
                  end,
                  words: words.map((word) => {
                    return {
                      ...word,
                      // words should keep their absolute start time
                      // so need to change this value, which is relative
                      // to start of the srt
                      start: word.start + (start || 0) - Number(value),
                    }
                  }),
                })
              }}
            />
            <StartEndPickerTimeField
              disabled={disabled}
              variant="outlined"
              size="small"
              innerPadding={innerPadding}
              name="end"
              id="end"
              value={roundFloat(end, 2)}
              label="End"
              InputProps={{
                style: {
                  backgroundColor: textFieldBackgroundColor,
                  width: "150px",
                },
              }}
              style={{
                backgroundColor: textFieldBackgroundColor,
              }}
              onChange={(value) => {
                onSRTChange({
                  index,
                  end: value,
                  start,
                  words,
                })
              }}
            />
          </Stack>

          <Stack
            direction="row"
            sx={{ pt: 2, pl: 0 }}
            alignItems="center"
            flexWrap="nowrap"
            gap="1rem 0.2rem"
          >
            {!disabled && (
              <AddWordButton
                onClick={() => handleAddWord("start")}
                disabled={disabled}
              />
            )}

            {words.map((word, wordIndex) => {
              return (
                <Stack
                  key={word.uuid}
                  direction="row"
                  alignItems="center"
                  flexWrap="nowrap"
                  gap="0.2rem"
                >
                  <Grid container columns={2}>
                    <Stack
                      direction="column"
                      alignItems="flex-start"
                      style={{ position: "relative", width: "85px" }}
                    >
                      <SmallCaptionEditorWordField
                        disabled={disabled}
                        size="small"
                        name="text"
                        id="text"
                        placeholder="word"
                        style={{
                          backgroundColor: textFieldBackgroundColor,
                        }}
                        value={word.text}
                        onChange={(e) => {
                          handleWordChange(
                            wordIndex,
                            (srtWithWordTimings.start || 0) + word.start,
                            e.target.value
                          )
                        }}
                      />
                      <StartEndPickerTimeField
                        disabled={disabled}
                        variant="outlined"
                        size="small"
                        innerPadding={innerPadding}
                        name="start"
                        style={{
                          marginTop: "-1px",
                          borderTop: "none",
                          backgroundColor: textFieldBackgroundColor,
                        }}
                        id="text"
                        InputProps={{
                          endAdornment: false,
                          style: { padding: "0" },
                        }}
                        value={roundFloat(
                          (srtWithWordTimings.start || 0) + word.start,
                          2
                        )}
                        onChange={(value) => {
                          handleWordChange(wordIndex, Number(value), word.text)
                        }}
                      />
                      {!disabled && (
                        <Box
                          style={{
                            position: "absolute",
                            top: "-0.9rem",
                            right: 0,
                            // transform: "translateX(0%) translateY(0%)",
                          }}
                        >
                          <Tooltip title="Delete word" placement="top">
                            <IconButton
                              onClick={() => handleDeleteWord(wordIndex)}
                              size="small"
                              disabled={disabled}
                            >
                              <CancelIcon
                                color="inherit"
                                style={{ fontSize: "1rem" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                    </Stack>
                  </Grid>

                  {!disabled && (
                    <AddWordButton
                      onClick={() => handleAddWord(wordIndex)}
                      disabled={disabled}
                    />
                  )}
                </Stack>
              )
            })}
          </Stack>
        </Stack>
      </Box>
    </Stack>
  )
}

export const MemoizedCaptionEditorRowWithWordTimings = React.memo(
  CaptionEditorRowWithWordTimings,
  (prevProps, nextProps) => {
    return (
      prevProps.disabled === nextProps.disabled &&
      prevProps.onSRTChange === nextProps.onSRTChange &&
      prevProps.onSRTRemove === nextProps.onSRTRemove &&
      JSON.stringify(prevProps.srtWithWordTimings) ===
        JSON.stringify(nextProps.srtWithWordTimings)
    )
  }
)
