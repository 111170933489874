import { format, isToday } from "date-fns"

import Chip from "@mui/material/Chip"
import Tooltip from "@mui/material/Tooltip"

function CreatedAtChip({
  createdAt,
  prefix = "Created",
}: {
  createdAt: string
  prefix?: string
}) {
  const wasToday = isToday(new Date(createdAt))
  return (
    <Tooltip title={format(new Date(createdAt), "Pp")}>
      <Chip
        size="small"
        label={`${prefix} ${
          wasToday
            ? format(new Date(createdAt), "p")
            : format(new Date(createdAt), "do LLL")
        }`}
      />
    </Tooltip>
  )
}

export default CreatedAtChip
