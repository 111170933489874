import * as React from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import { ImportJob } from "packages/frontend/src/types/ImportJob"

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ClosedCaptionIcon from "@mui/icons-material/ClosedCaption"
import GetAppIcon from "@mui/icons-material/GetApp"
// import MovieFilterIcon from "@mui/icons-material/MovieFilter"
import StorageIcon from "@mui/icons-material/Storage"
import VideoFileIcon from "@mui/icons-material/VideoFile"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import CircularProgress from "@mui/material/CircularProgress"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Grow from "@mui/material/Grow"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import Paper from "@mui/material/Paper"
import Popper from "@mui/material/Popper"

import createAsset from "../../../../api/assets/create"
import { downloadURI } from "../../../../helpers/files/downloadURI"
import { addSnackbar } from "../../../../redux/snackbars"

export default function ImportJobButtonGroup({
  importJob,
}: {
  importJob: ImportJob
}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const [createAssetLoading, setCreateAssetLoading] = React.useState(false)
  const [assetCreated, setAssetCreated] = React.useState(false)

  const handleCreateAsset = React.useCallback(async () => {
    try {
      setCreateAssetLoading(true)

      const video = document.createElement("video")
      video.preload = "metadata"
      video.onloadedmetadata = async () => {
        try {
          const formData = new FormData()
          formData.append("name", String(importJob.youtubeName))
          formData.append("originalHeight", String(video.videoHeight))
          formData.append("originalWidth", String(video.videoWidth))
          formData.append("originalDuration", String(video.duration))
          formData.append("importJobUuid", importJob.uuid)
          await createAsset(formData)
          setAssetCreated(true)
          dispatch(addSnackbar({ text: "Asset created." }))
        } catch (err) {
          dispatch(
            addSnackbar({
              id: "failedtocreateasset",
              text: "Failed to create asset.",
            })
          )
          console.error("Failed to create asset from import job", err)
          setCreateAssetLoading(false)
        }
      }
      video.src = importJob.videoURL
    } catch (err) {
      console.error("Failed to create asset from import job", err)
      setCreateAssetLoading(false)
    }
  }, [dispatch, importJob.uuid, importJob.videoURL, importJob.youtubeName])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        size="small"
      >
        <Button
          disabled={Boolean(importJob.fileRemoved)}
          onClick={() =>
            navigate(
              `/app/account/video-creator?importJobUuid=${importJob.uuid}`
            )
          }
        >
          {/* <MovieFilterIcon sx={{ mr: 2 }} /> */}
          Open In Creator
        </Button>
        <Button
          size="small"
          aria-controls={open ? "import-action-split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select import job action"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="import-action-split-button-menu" autoFocusItem>
                  {!importJob.fileRemoved && (
                    <MenuItem
                      onClick={() =>
                        downloadURI(
                          importJob.videoURL,
                          `video-${importJob.uuid}.mp4`
                        )
                      }
                    >
                      <GetAppIcon sx={{ mr: 2 }} />
                      Download Video (MP4)
                    </MenuItem>
                  )}

                  {/*<MenuItem
                    onClick={() =>
                      downloadURI(
                        importJob.videoURLWebm,
                        `video-${importJob.uuid}.webm`
                      )
                    }
                  >
                    <GetAppIcon sx={{ mr: 2 }} />
                    Download Video (WEBM)
                  </MenuItem> */}

                  {(importJob.completed || importJob.failed) && (
                    <MenuItem
                      onClick={() =>
                        downloadURI(
                          `/api/v1/files/import-jobs/${importJob.uuid}/captions.srt`,
                          `captions-${importJob.uuid}.srt`
                        )
                      }
                    >
                      <ClosedCaptionIcon sx={{ mr: 2 }} />
                      Download SRT Captions
                    </MenuItem>
                  )}
                  {(importJob.completed || importJob.failed) && (
                    <MenuItem
                      onClick={() =>
                        downloadURI(
                          `/api/v1/files/import-jobs/${importJob.uuid}/video.en.json3`,
                          `captions-${importJob.uuid}.en.json`
                        )
                      }
                    >
                      <ClosedCaptionIcon sx={{ mr: 2 }} />
                      Download JSON3 Captions
                    </MenuItem>
                  )}
                  {(importJob.completed || importJob.failed) && (
                    <MenuItem
                      onClick={() =>
                        downloadURI(
                          `/api/v1/files/import-jobs/${importJob.uuid}/logs.txt`,
                          `import-${importJob.uuid}-logs.txt`
                        )
                      }
                    >
                      <StorageIcon sx={{ mr: 2 }} />
                      Download Import Logs
                    </MenuItem>
                  )}
                  {!assetCreated &&
                    importJob.completed &&
                    !importJob.fileRemoved && (
                      <MenuItem
                        disabled={createAssetLoading}
                        onClick={() => handleCreateAsset()}
                      >
                        {createAssetLoading ? (
                          <CircularProgress
                            size={20}
                            sx={{ mr: 2 }}
                            color="inherit"
                          />
                        ) : (
                          <VideoFileIcon sx={{ mr: 2 }} />
                        )}
                        Create Asset
                      </MenuItem>
                    )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  )
}
