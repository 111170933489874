/* eslint-disable indent */
import {
  HorizontalAlignmentOption,
  TextAnimationOption,
  VerticalAlignmentOption,
} from './RenderJobAsset'
import { RenderJobAsset } from './RenderJobAsset'

export type PresetBaseVideoParams =
  | 'baseVideoVerticalAlignment'
  | 'baseVideoSize'

export type BaseVideoVerticalAlignment = 'top' | 'middle' | 'bottom'
export type BaseVideoSize =
  | 'content-fit-vertical'
  | 'half-height-vertical'
  | 'one-third-height-vertical'
  | 'two-thirds-height-vertical'
  | 'content-fit-horizontal'

export const prettyBaseVideoSize = (
  baseVideoSize: BaseVideoSize,
  shorter?: boolean
) => {
  switch (baseVideoSize) {
    case 'content-fit-vertical':
      return 'Full Height'
    case 'half-height-vertical':
      return shorter ? 'Half' : 'Half Height'
    case 'one-third-height-vertical':
      return shorter ? 'Third' : '1/3 Height'
    case 'two-thirds-height-vertical':
      return shorter ? 'Two Thirds' : '2/3 Height'
    case 'content-fit-horizontal':
      return 'Full Width'
  }
}

export const prettyBaseVideoVerticalAlignment = (
  baseVideoVerticalAlignment: BaseVideoVerticalAlignment
) => {
  switch (baseVideoVerticalAlignment) {
    case 'top':
      return 'Top'
    case 'middle':
      return 'Middle'
    case 'bottom':
      return 'Bottom'
  }
}

export interface PresetRenderAttributes {
  fontColor: string
  fontName: string
  strokeWidth: number
  strokeColor: string
  textHighlightColor: string
  verticalCaptionAlignment: VerticalAlignmentOption
  horizontalCaptionAlignment: HorizontalAlignmentOption
  paddingVerticalPx: number
  paddingHorizontalPx: number
  fontSizePx: number
  oneWordAtATime: boolean
  textShadow: string
  textAnimation: TextAnimationOption
  textCapitalization: string
  resolutionWidth: number
  resolutionHeight: number
  backgroundColor: string
  baseVideoVerticalAlignment: BaseVideoVerticalAlignment
  baseVideoSize: BaseVideoSize
  baseVideoZIndex: number

  renderJobAssets?: RenderJobAsset[]
}

export const presetToStyleAttributes = (
  preset: Preset
): PresetRenderAttributes => ({
  fontColor: preset.fontColor,
  fontName: preset.fontName,
  strokeWidth: preset.strokeWidth,
  strokeColor: preset.strokeColor,
  textHighlightColor: preset.textHighlightColor,
  verticalCaptionAlignment: preset.verticalCaptionAlignment,
  horizontalCaptionAlignment: preset.horizontalCaptionAlignment,
  paddingVerticalPx: preset.paddingVerticalPx,
  paddingHorizontalPx: preset.paddingHorizontalPx,
  fontSizePx: preset.fontSizePx,
  oneWordAtATime: preset.oneWordAtATime,
  textShadow: preset.textShadow,
  textAnimation: preset.textAnimation,
  textCapitalization: preset.textCapitalization,
  resolutionWidth: preset.resolutionWidth,
  resolutionHeight: preset.resolutionHeight,
  backgroundColor: preset.backgroundColor,
  baseVideoVerticalAlignment: preset.baseVideoVerticalAlignment,
  baseVideoSize: preset.baseVideoSize,
  baseVideoZIndex: preset.baseVideoZIndex,
  renderJobAssets: preset.renderJobAssets,
})

export interface Preset extends PresetRenderAttributes {
  uuid: string
  createdAt: string
  updatedAt: string

  userUuid: string
  presetUuid?: string

  name: string
  description?: string

  thumbnailFilepath: string
  thumbnailUrl: string

  public: boolean

  user?: {
    email?: string
  }
}
