export const MINUTE_IN_MS = 1000 * 60
export const HOUR_IN_MS = 60 * MINUTE_IN_MS
export const DAY_IN_MS = 24 * HOUR_IN_MS
export const MONTH_IN_MS = 30 * DAY_IN_MS

export const FREE_SECONDS_IMPORT = 10 * (MINUTE_IN_MS / 1000)
export const FREE_SECONDS_RENDER = 0
export const FREE_IDEAS_GENERATED = 20

// starter and trial share the same limits
export const STARTER_SECONDS_IMPORT = 5 * (HOUR_IN_MS / 1000)
export const STARTER_SECONDS_RENDER = 10 * (MINUTE_IN_MS / 1000)
export const STARTER_IDEAS_GENERATED = 1000

export const PREMIUM_SECONDS_IMPORT = 20 * (HOUR_IN_MS / 1000)
export const PREMIUM_SECONDS_RENDER = 5 * (HOUR_IN_MS / 1000)
export const PREMIUM_IDEAS_GENERATED = 3000

export const UNLIMITED_SECONDS_IMPORT = 200 * (HOUR_IN_MS / 1000)
export const UNLIMITED_SECONDS_RENDER = 50 * (HOUR_IN_MS / 1000)
export const UNLIMITED_IDEAS_GENERATED = 10000

export const STARTER_PLAN_MONTHLY_COST = 7
export const PREMIUM_PLAN_MONTHLY_COST = 19
export const PREMIUM_PLAN_ANNUAL_COST = 149
export const UNLIMITED_PLAN_MONTHLY_COST = 149
export const UNLIMITED_PLAN_ANNUAL_COST = 849
