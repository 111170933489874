import { SRTWithWordTimings } from "@trimmr/trimmr-lib/types/SRT/SRTWithWordTimings"

import { MAX_WORD_ANIMATION_DURATION } from "../constants"

const getWordProgressDecimalWithWordTimings = (
  wordIndex: number,
  oneWordAtATime: boolean,
  currentSRT: SRTWithWordTimings,
  _startSeconds: number,
  endSeconds: number,
  currentSeconds: number
) => {
  if (!oneWordAtATime) return { wordProgressDecimal: 1 }

  const actualCurrentSeconds = currentSeconds

  const words = currentSRT.words
  const wordStartTime = words[wordIndex].start + (currentSRT.start || 0)

  const nextWord = words[wordIndex + 1]

  const nextWordStartTime = nextWord
    ? nextWord.start + (currentSRT.start || 0)
    : Infinity

  // don't animate beyond end of SRT if user has configured
  // last word time very close to end of subtitle
  const wordEndTime = Math.min(
    wordStartTime + MAX_WORD_ANIMATION_DURATION,
    currentSRT.end || 0,
    endSeconds
  )

  // when it's a really long word, max fade in 1 second
  const wordAnimationSeconds = wordEndTime - wordStartTime

  // crop to [0,1], opacity is equal to the percentage the word has been
  // most recent for
  const wordProgressDecimal = Math.max(
    0,
    Math.min(
      1,
      (actualCurrentSeconds - wordStartTime) /
        (wordEndTime - wordStartTime) /
        wordAnimationSeconds
    )
  )

  const shouldRenderHighlight =
    actualCurrentSeconds >= wordStartTime &&
    actualCurrentSeconds < nextWordStartTime

  return { wordProgressDecimal, wordAnimationSeconds, shouldRenderHighlight }
}

export default getWordProgressDecimalWithWordTimings
