import { continueRender, delayRender, staticFile } from "remotion"

// import window from '../../../lib/window'
import { fonts } from "../fonts"

const waitForFont = delayRender("Fonts")

// don't preload serverside
try {
  Promise.all(
    fonts.map((fontObject) => {
      const remotionFont = new FontFace(
        fontObject.browserName,
        `url('${staticFile(
          `fonts/${fontObject.browserName}.ttf`
        )}') format('truetype')`
      )

      return remotionFont.load().then(() => {
        document.fonts.add(remotionFont)
      })
    })
  )
    .then(() => continueRender(waitForFont))
    .catch((err) => console.error("Error loading font", err))
} catch (err) {
  // console.error('Error', err)
}
