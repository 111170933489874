import * as React from "react"
import { useDispatch } from "react-redux"

import { Asset } from "@trimmr/trimmr-lib/types/Asset"

import LoadingButton from "@mui/lab/LoadingButton"
import {
  Alert,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
} from "@mui/material"

import updateAsset from "../../../../api/assets/update"
import { useAppSelector } from "../../../../redux"
import { addSnackbar } from "../../../../redux/snackbars"

function EditAssetModal({
  asset,
  open,
  onSave,
  onClose,
}: {
  asset: Asset
  open: boolean
  onSave: () => void
  onClose: () => void
}) {
  const dispatch = useDispatch()
  const [saving, setSaving] = React.useState(false)
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)

  const [errorMessage, setErrorMessage] = React.useState("")
  const [assetName, setAssetName] = React.useState("")
  const [assetPublic, setAssetPublic] = React.useState(false)

  const handleReceiveAsset = (asset: Asset) => {
    setAssetName(asset.name)
    setAssetPublic(asset.public)
  }

  React.useEffect(() => {
    handleReceiveAsset(asset)
  }, [asset])

  const handleSave = () => {
    setSaving(true)
    updateAsset(asset.uuid, {
      public: assetPublic,
      name: assetName,
    })
      .then(() => {
        onSave()
        setSaving(false)
        dispatch(addSnackbar({ text: "Asset updated." }))
      })
      .catch((err) => {
        console.error(err)
        setSaving(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message
        )
      })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="edit-asset-title"
      aria-describedby="edit-asset-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="edit-asset-dialog-title">Edit asset</DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        {errorMessage && (
          <Stack sx={{ width: "100%", mb: 2 }} spacing={2}>
            <Alert severity="error" onClose={() => setErrorMessage("")}>
              {errorMessage}
            </Alert>
          </Stack>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="assetName"
              required
              fullWidth
              id="assetName"
              value={assetName || ""}
              label="Asset Name"
              sx={{ mt: 1 }}
              onChange={(e) => {
                setAssetName(e.target.value)
              }}
            />
          </Grid>

          {currentUserProfile && currentUserProfile.isAdmin && (
            <Grid item xs={12}>
              <FormControlLabel
                label="Is Public?"
                sx={{ color: "text.primary" }}
                control={
                  <Checkbox
                    checked={assetPublic}
                    onChange={(e) => {
                      setAssetPublic(e.target.checked)
                    }}
                  />
                }
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          onClick={() => handleSave()}
          loading={saving}
          disabled={!assetName}
          color="primary"
          variant="contained"
        >
          Save
        </LoadingButton>
        <LoadingButton onClick={onClose}>Cancel</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default EditAssetModal
