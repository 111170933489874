import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"

import ConditionalInfoAlert from "../../../../components/ConditionalInfoAlert"
import HeadingContainer from "../../../../components/HeadingContainer"
import { useAppSelector } from "../../../../redux"
import UserUsage from "./UserUsage"

export default function AccountUsage() {
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)

  const currentUserUuid = currentUserProfile && currentUserProfile.uuid

  if (!currentUserUuid) return null

  return (
    <Box>
      <HeadingContainer>
        <Typography component="h1" variant="h6" color="textPrimary" noWrap>
          Usage
        </Typography>
      </HeadingContainer>

      <Divider sx={{ mb: 2 }} />

      <Container maxWidth="md">
        <ConditionalInfoAlert>
          Monitor your usage to avoid hitting any limits. For increased limits,
          upgrade your account.
        </ConditionalInfoAlert>

        <Paper sx={{ p: 4, mb: 2 }}>
          <UserUsage userUuid={currentUserUuid} />
        </Paper>
      </Container>
    </Box>
  )
}
