import * as React from "react"
import { useDispatch } from "react-redux"

import { AxiosError } from "axios"

import {
  FREE_IDEAS_GENERATED,
  FREE_SECONDS_IMPORT,
  FREE_SECONDS_RENDER,
  PREMIUM_IDEAS_GENERATED,
  PREMIUM_SECONDS_IMPORT,
  PREMIUM_SECONDS_RENDER,
  STARTER_IDEAS_GENERATED,
  STARTER_SECONDS_IMPORT,
  STARTER_SECONDS_RENDER,
  UNLIMITED_IDEAS_GENERATED,
  UNLIMITED_SECONDS_IMPORT,
  UNLIMITED_SECONDS_RENDER,
} from "@trimmr/trimmr-lib/constants"

import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Stack from "@mui/material/Stack"

import fetchUser from "../../../../api/users/fetch"
import getUsage from "../../../../api/users/getUsage"
import { useAppSelector } from "../../../../redux"
import { addSnackbar } from "../../../../redux/snackbars"
import { CurrentUserProfile } from "../../../../types/CurrentUserProfile"
import { User } from "../../../../types/User"
// import SubscriptionBenefits from "./SubscriptionBenefits"
import UsageSection from "./UsageSections"

export default function AccountUsage({ userUuid }: { userUuid: string }) {
  const dispatch = useDispatch()
  const [user, setUser] = React.useState<(User | CurrentUserProfile) | null>(
    null
  )
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)
  const [usage, setUsage] = React.useState<{
    renderUsage: number
    importUsage: number
    contentIdeasUsage: number
  } | null>(null)

  React.useEffect(() => {
    const refreshUsage = async () => {
      try {
        if (!currentUserProfile) return
        const usage = await getUsage(userUuid)

        let user
        if (currentUserProfile.uuid !== userUuid) {
          user = await fetchUser(userUuid)
        } else {
          user = currentUserProfile
        }
        setUsage(usage)
        setUser(user)
      } catch (errO) {
        const err = errO as AxiosError
        console.error("Error creating checkout session", err)
        const text =
          (err.response && err.response.data && err.response.data.error) ||
          "Failed to find usage."
        dispatch(addSnackbar({ text }))
      }
    }

    refreshUsage()
  }, [currentUserProfile, dispatch, userUuid])

  if (!user) return null

  const useStarterLimits =
    user.stripeSubscriptionType === "STARTER" ||
    user.stripeSubscriptionStatus === "trialing"

  let importLimit = user.hasActiveStripeSubscription
    ? useStarterLimits
      ? STARTER_SECONDS_IMPORT
      : PREMIUM_SECONDS_IMPORT
    : FREE_SECONDS_IMPORT
  let renderLimit = user.hasActiveStripeSubscription
    ? useStarterLimits
      ? STARTER_SECONDS_RENDER
      : PREMIUM_SECONDS_RENDER
    : FREE_SECONDS_RENDER
  let contentIdeasLimit = user.hasActiveStripeSubscription
    ? useStarterLimits
      ? STARTER_IDEAS_GENERATED
      : PREMIUM_IDEAS_GENERATED
    : FREE_IDEAS_GENERATED

  if (user.stripeSubscriptionType === "UNLIMITED") {
    importLimit = UNLIMITED_SECONDS_IMPORT
    renderLimit = UNLIMITED_SECONDS_RENDER
    contentIdeasLimit = UNLIMITED_IDEAS_GENERATED
  }

  return (
    <Box>
      {!usage && (
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          <CircularProgress color="inherit" />
        </Box>
      )}

      {usage && (
        <Stack spacing={2}>
          <UsageSection
            title="Videos imported"
            usageSeconds={usage.importUsage}
            limitString={
              importLimit < 60 * 60
                ? `${importLimit / 60} minutes`
                : `${importLimit / 60 / 60} hours`
            }
            limit={importLimit}
            exceededMessage={
              "You have exceeded your import limit. Please upgrade your account to import more videos."
            }
            zeroMessage={"Upgrade your account to import videos."}
          />

          <UsageSection
            title="Ideas generated"
            usageCount={usage.contentIdeasUsage}
            limitString={`${contentIdeasLimit} ideas`}
            limit={contentIdeasLimit}
            exceededMessage="You have exceeded your content idea limit. Please upgrade your account to generate more ideas."
            zeroMessage={"Upgrade your account to generate ideas."}
          />

          <UsageSection
            title="Videos rendered"
            usageSeconds={usage.renderUsage}
            limitString={
              renderLimit > 60 * 60
                ? `${renderLimit / 60 / 60} hours`
                : `${renderLimit / 60} minutes`
            }
            limit={renderLimit}
            exceededMessage={
              "You have exceeded your render limit. Please upgrade your account to render more videos."
            }
            zeroMessage={"Upgrade your account to render shorts."}
          />
        </Stack>
      )}
    </Box>
  )
}
