import { API_BASE_URL } from "../../consts"
import axios from "../axios"

export default function updateUser(
  userUuid: string,
  params: {
    isAdmin?: boolean
    emailConfirmed?: boolean
    firstname?: string
    lastname?: string
    stripeSubscriptionEndsAt?: string
    oldPassword?: string
    newPassword?: string
    stripeSubscriptionType?: string
    stripeSubscriptionStatus?: string
    dailyIdeaEmailEnabled?: boolean
    dailyIdeaEmailTime?: Date

    // must provide password when refreshing API key
    password?: string
    refreshApiKeyOne?: boolean
    refreshApiKeyTwo?: boolean
  }
) {
  return axios
    .patch(`${API_BASE_URL}/users/${userUuid}`, params)
    .then((response) => response.data)
}
