import * as React from "react"

import { Player, PlayerRef } from "@remotion/player"
import { useElementSize } from "usehooks-ts"

import {
  BaseVideoSize,
  BaseVideoVerticalAlignment,
} from "@trimmr/trimmr-lib/types/Preset"
import { defaultRenderJobInitialParams } from "@trimmr/trimmr-lib/types/RenderJob"
import { TextStyleAttributes } from "@trimmr/trimmr-lib/types/TextStyle"

import { Alert } from "@mui/material"

import getContainedChildDimensions from "../../../helpers/sizes/getContainedChildDimensions"
import { useAppSelector } from "../../../redux"
import MockVideoComposition from "./MockVideoComposition"
import { RemotionPreviewSwitcherContext } from "./RemotionPreviewSwitcherManager"
import VideoComposition, { VideoCompositionProps } from "./VideoComposition"
import { FPS } from "./constants"

const RemotionPreview: React.FC<
  {
    id: string
    playerRef?: React.RefObject<PlayerRef>
    isInEditMode?: boolean
    hidePlayerControls?: boolean
    baseVideoVerticalAlignment?: BaseVideoVerticalAlignment
    baseVideoSize?: BaseVideoSize
    textStyleAttributes: TextStyleAttributes
    containerSize?: {
      width: number
      height: number
    }
  } & VideoCompositionProps & { thumbnailURL?: string }
> = ({
  id,

  playerRef,

  videoURL,
  // thumbnailURL,
  startSeconds,
  endSeconds,

  textStyleAttributes,

  resolutionWidth,
  resolutionHeight,
  paddingVerticalPx,
  paddingHorizontalPx,

  importJobOriginalWidth,
  importJobOriginalHeight,

  baseVideoXPosition,
  baseVideoYPosition,
  baseVideoXScale,
  baseVideoYScale,
  setBaseVideoPosition,
  baseVideoZIndex,

  renderJobAssets,
  handleRenderJobAssetMove,

  srtWithWordTimings,

  isInEditMode,
  hidePlayerControls,

  containerSize,

  baseVideoVerticalAlignment,
  baseVideoSize,
}) => {
  // const [buffering, setBuffering] = React.useState(false)
  // // const [currentFrame, setCurrentFrame] = React.useState<number | undefined>()
  // const pausedBecauseOfBuffering = React.useRef(false)

  const renderWatermark = useAppSelector(
    (state) => !state.currentUserProfile?.hasActiveStripeSubscription
  )

  const [elementSizeRef, { width }] = useElementSize()
  const currentRenderScale = width / resolutionWidth

  const { currentlyOpenPreviewId, setCurrentlyOpenPreviewId } =
    React.useContext(RemotionPreviewSwitcherContext)

  const durationInFrames = (endSeconds - startSeconds) * FPS
  const startEndInvalid =
    typeof durationInFrames !== "number" || durationInFrames <= 0
  if (startEndInvalid)
    return (
      <Alert severity="warning" sx={{ m: 1 }} variant="outlined">
        Start/end times invalid.
      </Alert>
    )

  // if (id !== currentlyOpenPreviewId) {
  //   return (
  //     <VideoCompositionPlaceholder
  //       id={id}
  //       thumbnailURL={thumbnailURL}
  //       resolutionWidth={resolutionWidth}
  //       resolutionHeight={resolutionHeight}
  //       setCurrentlyOpenPreviewId={setCurrentlyOpenPreviewId}
  //     />
  //   )
  // }

  if (id !== currentlyOpenPreviewId) {
    return (
      <MockVideoComposition
        id={id}
        // videoURL={videoURL}
        // thumbnailURL={thumbnailURL}
        startSeconds={startSeconds}
        resolutionWidth={resolutionWidth}
        resolutionHeight={resolutionHeight}
        setCurrentlyOpenPreviewId={setCurrentlyOpenPreviewId}
        srtWithWordTimings={srtWithWordTimings || []}
        presetRenderAttributes={{
          ...textStyleAttributes,

          paddingVerticalPx,
          paddingHorizontalPx,
          resolutionWidth,
          resolutionHeight,
          baseVideoVerticalAlignment:
            baseVideoVerticalAlignment ||
            defaultRenderJobInitialParams.baseVideoVerticalAlignment,
          baseVideoSize:
            baseVideoSize || defaultRenderJobInitialParams.baseVideoSize,
          baseVideoZIndex,
          renderJobAssets,
        }}
      />
    )
  }

  let style: React.CSSProperties = { width: "100%" }

  if (containerSize) {
    style = getContainedChildDimensions(containerSize, {
      width: resolutionWidth,
      height: resolutionHeight,
    })
  }

  return (
    <div ref={elementSizeRef}>
      <Player
        ref={playerRef}
        component={VideoComposition}
        durationInFrames={Math.round(durationInFrames)}
        compositionWidth={resolutionWidth}
        compositionHeight={resolutionHeight}
        clickToPlay={false}
        fps={FPS}
        style={{ ...style, border: "1px solid #444" }}
        controls={!hidePlayerControls}
        inputProps={{
          srtWithWordTimings,

          videoURL,
          startSeconds,
          endSeconds,

          renderWatermark,

          textStyleAttributes,

          paddingVerticalPx,
          paddingHorizontalPx,

          importJobOriginalWidth,
          importJobOriginalHeight,

          baseVideoXPosition,
          baseVideoYPosition,
          baseVideoXScale,
          baseVideoYScale,
          setBaseVideoPosition,
          baseVideoZIndex,

          resolutionWidth,
          resolutionHeight,

          currentRenderScale,

          renderJobAssets,
          handleRenderJobAssetMove,

          isInEditMode,
        }}
      />
    </div>
  )
}

// without this, during playing it's very laggy. this is because of
// the callbacks we have on timeupdate event for video
// const MemoizedRemotionPreview = React.memo(
//   RemotionPreview,
//   (prevProps, nextProps) => {
//     const wasMemo =
//       prevProps.videoURL === nextProps.videoURL &&
//       prevProps.startSeconds === nextProps.startSeconds &&
//       prevProps.textAnimation === nextProps.textAnimation &&
//       prevProps.endSeconds === nextProps.endSeconds &&
//       prevProps.fontColor === nextProps.fontColor &&
//       prevProps.strokeWidth === nextProps.strokeWidth &&
//       prevProps.strokeColor === nextProps.strokeColor &&
//       prevProps.fontName === nextProps.fontName &&
//       prevProps.resolutionHeight === nextProps.resolutionHeight &&
//       prevProps.resolutionWidth === nextProps.resolutionWidth &&
//       prevProps.verticalCaptionAlignment ===
//         nextProps.verticalCaptionAlignment &&
//       prevProps.horizontalCaptionAlignment ===
//         nextProps.horizontalCaptionAlignment &&
//       prevProps.paddingVerticalPx === nextProps.paddingVerticalPx &&
//       prevProps.paddingHorizontalPx === nextProps.paddingHorizontalPx &&
//       prevProps.fontSizePx === nextProps.fontSizePx &&
//       prevProps.oneWordAtATime === nextProps.oneWordAtATime &&
//       prevProps.textHighlightColor === nextProps.textHighlightColor &&
//       prevProps.textShadow === nextProps.textShadow &&
//       prevProps.textCapitalization === nextProps.textCapitalization &&
//       prevProps.backgroundColor === nextProps.backgroundColor &&
//       prevProps.baseVideoXPosition === nextProps.baseVideoXPosition &&
//       prevProps.baseVideoYPosition === nextProps.baseVideoYPosition &&
//       prevProps.baseVideoXScale === nextProps.baseVideoXScale &&
//       prevProps.baseVideoYScale === nextProps.baseVideoYScale &&
//       prevProps.baseVideoZIndex === nextProps.baseVideoZIndex &&
//       prevProps.isInEditMode === nextProps.isInEditMode &&
//       prevProps.importJobOriginalWidth === nextProps.importJobOriginalWidth &&
//       prevProps.importJobOriginalHeight === nextProps.importJobOriginalHeight &&
//       JSON.stringify(prevProps.renderJobAssets) ===
//         JSON.stringify(nextProps.renderJobAssets) &&
//       JSON.stringify(prevProps.srtWithWordTimings) ===
//         JSON.stringify(nextProps.srtWithWordTimings)

//     return wasMemo
//   }
// )

const MemoizedRemotionPreview = RemotionPreview

export default MemoizedRemotionPreview
