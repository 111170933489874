import { ReactNode } from "react"

import { Alert } from "@mui/material"

import { useAppSelector } from "../redux"

const ConditionalInfoAlert = ({ children }: { children: ReactNode }) => {
  const infoAlertsEnabled = useAppSelector((state) => state.infoAlertsEnabled)

  if (!infoAlertsEnabled) return null

  return (
    <Alert
      icon={false}
      // icon={<HelpOutlineIcon />}
      color="info"
      sx={{ px: 2, py: 1, mb: 2 }}
    >
      {children}
    </Alert>
  )
}

export default ConditionalInfoAlert
