import { Button, Paper, styled } from "@mui/material"

const BlackButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== "lightModeEnabled" && prop !== "alignSelf",
})<{
  lightModeEnabled?: boolean
  alignSelf?: string
  width?: string
}>(({ lightModeEnabled, disabled, alignSelf, width }) => ({
  borderRadius: 0,
  backgroundColor: !lightModeEnabled ? "black" : "white",
  fontSize: "2rem",
  cursor: "pointer",
  textTransform: "lowercase",
  // border: "1px solid black",
  alignSelf,
  minWidth: width,
  maxWidth: width,
  border: !lightModeEnabled ? "1px solid #333" : "1px solid #ccc",
  "&:hover": disabled
    ? {}
    : {
        cursor: "pointer",
        border: !lightModeEnabled ? "1px solid white" : "1px solid black",
        backgroundColor: !lightModeEnabled ? "black" : "white",
        "& .MuiButtonBase-root": {
          color: !lightModeEnabled ? "white" : "black",
        },
      },
}))

export const BlackPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "lightModeEnabled",
})<{ lightModeEnabled?: boolean }>(({ lightModeEnabled }) => ({
  borderRadius: 0,
  backgroundColor: !lightModeEnabled ? "black" : "white",
  fontSize: "2rem",
  cursor: "pointer",
  textTransform: "lowercase",
  border: !lightModeEnabled ? "1px solid #333" : "1px solid #ccc",
}))

export default BlackButton
