import axios from "../axios"
import { API_BASE_URL } from "../../consts"

export default function confirmEmail(
  email: string,
  emailConfirmationToken: string
) {
  return axios
    .post(`${API_BASE_URL}/auth/confirm-email`, {
      email,
      emailConfirmationToken,
    })
    .then((response) => response.data)
}
