export default function durationToMSString(
  duration = 0,
  round = false,
  showHours = false
) {
  if (duration < 0.005) duration = 0
  const hours = Math.floor(duration / 3600)
  const minutes = Math.floor((duration % 3600) / 60)
  const seconds =
    (round ? Math.round(duration % 60) : (duration % 60).toFixed(1)) + "s"

  if (showHours) {
    if (hours === 0) {
      if (minutes === 0) return seconds
      return `${minutes}m ${seconds}`
    }
    return `${hours}h ${minutes}m ${seconds}`
  } else {
    if (minutes === 0) return seconds
    return `${minutes}m ${seconds}`
  }
}
