// import React from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { Helmet, HelmetProvider } from "react-helmet-async"

import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

import CssBaseline from "@mui/material/CssBaseline"
import GlobalStyles from "@mui/material/GlobalStyles"

import styleCss from "./index.css?inline"

// import "plyr-react/plyr.css"
import Router from "./Router"
import { globalStyles } from "./lib/mdTheme"

export function App() {
  return (
    <>
      {/* <React.StrictMode> */}
      <style dangerouslySetInnerHTML={{ __html: styleCss }} />

      <HelmetProvider>
        <Helmet defaultTitle="Trimmr" titleTemplate="%s | Trimmr" />

        <GlobalStyles styles={globalStyles} />

        <QueryClientProvider
          client={
            new QueryClient({
              defaultOptions: {
                queries: {
                  refetchOnWindowFocus: false,
                },
              },
            })
          }
        >
          <DndProvider backend={HTML5Backend}>
            <Router />
          </DndProvider>
        </QueryClientProvider>
      </HelmetProvider>

      <CssBaseline />
    </>
    // </React.StrictMode>
  )
}
