import { useDispatch } from "react-redux"

import { Stack, Tooltip, Typography } from "@mui/material"

import { useAppSelector } from "../../redux"
import { setInfoAlertsEnabled } from "../../redux/infoAlertsEnabled"
import AntSwitch from "../AntSwitch"

const InfoAlertsToggle = ({
  hideLabel,
  labelLeftPadding,
}: {
  hideLabel?: boolean
  labelLeftPadding?: string
}) => {
  const dispatch = useDispatch()
  const infoAlertsEnabled = useAppSelector((state) => state.infoAlertsEnabled)

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Tooltip
        title={
          infoAlertsEnabled
            ? "Disable blue tutorial messages"
            : "Enable blue tutorial messages"
        }
      >
        <AntSwitch
          color="info"
          checked={infoAlertsEnabled}
          onChange={(_, value) => dispatch(setInfoAlertsEnabled(value))}
          inputProps={{ "aria-label": "ant design" }}
          id="info-alerts-toggle"
        />
      </Tooltip>
      {!hideLabel && (
        <label
          htmlFor="info-alerts-toggle"
          style={{ cursor: "pointer", paddingLeft: labelLeftPadding }}
        >
          <Typography
            color={infoAlertsEnabled ? "text.primary" : "text.secondary"}
            style={{ textAlign: "right" }}
          >
            Show help?
          </Typography>
        </label>
      )}
    </Stack>
  )
}

export default InfoAlertsToggle
