import * as React from "react"
import { useNavigate } from "react-router-dom"

import { RenderJob } from "@trimmr/trimmr-lib/types/RenderJob"

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import GetAppIcon from "@mui/icons-material/GetApp"
import ImageIcon from "@mui/icons-material/Image"
// import MovieFilterIcon from "@mui/icons-material/MovieFilter"
import StorageIcon from "@mui/icons-material/Storage"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Grow from "@mui/material/Grow"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import Paper from "@mui/material/Paper"
import Popper from "@mui/material/Popper"

import { downloadURI } from "../../../../helpers/files/downloadURI"

export default function RenderJobButtonGroup({
  renderJob,
}: {
  renderJob: RenderJob
}) {
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        size="small"
      >
        <Button
          onClick={() =>
            navigate(
              `/app/account/video-creator?importJobUuid=${renderJob.importJobUuid}`
            )
          }
        >
          {/* <MovieFilterIcon sx={{ mr: 2 }} /> */}
          Open In Creator
        </Button>
        <Button
          size="small"
          aria-controls={open ? "import-action-split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select import job action"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="import-action-split-button-menu" autoFocusItem>
                  {(renderJob.completed || renderJob.failed) && (
                    <MenuItem
                      onClick={() =>
                        downloadURI(renderJob.videoURL, "video.mp4")
                      }
                    >
                      <GetAppIcon sx={{ mr: 2 }} />
                      Download Video (MP4)
                    </MenuItem>
                  )}

                  {(renderJob.completed || renderJob.failed) && (
                    <MenuItem
                      onClick={() =>
                        downloadURI(
                          renderJob.thumbnailURL,
                          `render-${renderJob.uuid}-thumbnail.jpg`
                        )
                      }
                    >
                      <ImageIcon sx={{ mr: 2 }} />
                      Download Thumbnail (JPG)
                    </MenuItem>
                  )}

                  {(renderJob.completed || renderJob.failed) && (
                    <MenuItem
                      onClick={() =>
                        downloadURI(
                          `/api/v1/files/render-jobs/${renderJob.uuid}/logs.txt`,
                          `render-${renderJob.uuid}-logs.txt`
                        )
                      }
                    >
                      <StorageIcon sx={{ mr: 2 }} />
                      Download Render Logs (TXT)
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  )
}
