import { Paper, ThemeProvider } from "@mui/material"

import lightTheme, { darkTheme, zapOrange } from "../../lib/mdTheme"
import { useAppSelector } from "../../redux"

export default function HomeCard(props: {
  children: React.ReactNode
  containerClassName?: string
  variant?: "black" | "zapier" | undefined
}) {
  const { variant = "gradient", children, containerClassName } = props
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)

  let theme = darkModeEnabled ? darkTheme : lightTheme
  if (variant === "black") theme = lightTheme
  if (variant === "zapier") theme = darkTheme

  return (
    <ThemeProvider theme={theme}>
      <Paper
        sx={{
          padding: 2,
          height: "100%",
          borderRadius: "20px",
          minHeight: "50vh",
          ...(variant == "black"
            ? { borderColor: darkModeEnabled ? "white" : "black" }
            : {}),
        }}
        style={{
          backgroundColor: variant === "zapier" ? zapOrange : undefined,
        }}
        className={containerClassName}
      >
        {children}
      </Paper>
    </ThemeProvider>
  )
}
