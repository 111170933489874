import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { v4 as uuidv4 } from "uuid"

export interface Popup {
  id: string
  title: string
  text: string
}

export interface AddPopupAction {
  id?: string
  title: string
  text: string
}

export const Popups = createSlice({
  name: "popups",
  initialState: [] as Popup[],
  reducers: {
    addPopup: (state, action: PayloadAction<AddPopupAction>) => {
      const newPopup = {
        id: `${action.payload.id || uuidv4()}${Date.now()}`,
        text: action.payload.text,
        title: action.payload.title,
      }
      return [newPopup, ...state]
    },
    removePopup: (state, action) => {
      return state.filter((s) => s.id !== action.payload)
    },
  },
})

export const { addPopup, removePopup } = Popups.actions

export default Popups.reducer
