import * as React from "react"

import { calculateBaseVideoPositionAndScale } from "@trimmr/trimmr-lib/helpers/size/calculateBaseVideoPositionAndScale"
import { defaultRenderJobInitialParams } from "@trimmr/trimmr-lib/types/RenderJob"

import AlarmOnIcon from "@mui/icons-material/AlarmOn"
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh"
import LightbulbIcon from "@mui/icons-material/Lightbulb"
import { Button, Card, Chip, Tooltip } from "@mui/material"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

import getVideoDimensionsAndSyncToImportJob from "../../helpers/importJobs/getVideoDimensionsAndSyncToImportJob"
import durationToColonString from "../../helpers/soundFile/durationToColonString"
import durationToMSString from "../../helpers/soundFile/durationToMSString"
import { ContentIdea } from "../../types/ContentIdea"
import { ImportJob } from "../../types/ImportJob"
import CaptionChip from "../CaptionChip"
import RemotionPreview from "../CreateRenderJobModal/RemotionPreview"
import CreatedAtChip from "../CreatedAtChip"

const IdeaCard: React.FC<{
  idea: ContentIdea
  importJob?: ImportJob
  onEditIdea?: (idea: ContentIdea) => void
  height?: string
}> = ({ idea, importJob, onEditIdea, height }) => {
  const duration = idea.endTime - idea.startTime
  const preset = idea.preset || defaultRenderJobInitialParams

  const [baseVideoWidth, setBaseVideoWidth] = React.useState<number>(1920)
  const [baseVideoHeight, setBaseVideoHeight] = React.useState<number>(1080)

  const videoURL = importJob && importJob.videoURL

  const setBaseDimensions = React.useCallback(async () => {
    if (!videoURL) return
    const { originalWidth, originalHeight } =
      await getVideoDimensionsAndSyncToImportJob(importJob)
    setBaseVideoWidth(originalWidth)
    setBaseVideoHeight(originalHeight)
  }, [importJob, videoURL])

  React.useEffect(() => {
    !baseVideoHeight && setBaseDimensions()
  }, [baseVideoHeight, setBaseDimensions])

  const {
    baseVideoXPosition,
    baseVideoYPosition,
    baseVideoXScale,
    baseVideoYScale,
  } = calculateBaseVideoPositionAndScale(
    preset.baseVideoSize,
    preset.baseVideoVerticalAlignment,
    preset.resolutionWidth,
    preset.resolutionHeight,
    { width: baseVideoWidth, height: baseVideoHeight }
  )

  return (
    <Card
      key={idea.explanation}
      style={{
        overflowY: "scroll",
        maxHeight: "100%",
        height: height || "inherit",
        width: "inherit",
        // backgroundColor: darkModeEnabled ? "" : "#222",

        display: "flex",
        flexDirection: "column",
      }}
      className="hide-scrollbar"
    >
      {importJob && (
        <RemotionPreview
          id={idea.uuid}
          videoURL={importJob.videoURL}
          thumbnailURL={importJob.thumbnailURL}
          startSeconds={idea.startTime}
          endSeconds={idea.endTime}
          textStyleAttributes={{
            fontColor: preset.fontColor,
            fontName: preset.fontName,
            strokeWidth: preset.strokeWidth,
            strokeColor: preset.strokeColor,
            verticalCaptionAlignment: preset.verticalCaptionAlignment,
            horizontalCaptionAlignment: preset.horizontalCaptionAlignment,
            fontSizePx: preset.fontSizePx,
            textHighlightColor: preset.textHighlightColor,
            oneWordAtATime: preset.oneWordAtATime,
            textShadow: preset.textShadow,
            textAnimation: preset.textAnimation,
            textCapitalization: preset.textCapitalization,
            backgroundColor: preset.backgroundColor,
          }}
          paddingVerticalPx={preset.paddingVerticalPx}
          paddingHorizontalPx={preset.paddingHorizontalPx}
          resolutionWidth={preset.resolutionWidth}
          resolutionHeight={preset.resolutionHeight}
          baseVideoXPosition={baseVideoXPosition}
          baseVideoYPosition={baseVideoYPosition}
          baseVideoXScale={baseVideoXScale}
          baseVideoYScale={baseVideoYScale}
          baseVideoZIndex={preset.baseVideoZIndex}
          renderJobAssets={preset.renderJobAssets || []}
          srtWithWordTimings={idea.srtWithWordTimings}
          srtWithWordTimingsLoading={false}
          importJobOriginalWidth={baseVideoWidth}
          importJobOriginalHeight={baseVideoHeight}
          baseVideoVerticalAlignment={preset.baseVideoVerticalAlignment}
          baseVideoSize={preset.baseVideoSize}
        />
      )}

      <Stack
        direction="column"
        style={{
          flexGrow: 1,
          // backgroundColor: darkModeEnabled ? "" : "rgba(0,0,0,0.8)",
        }}
      >
        <Stack
          direction="row"
          sx={{ p: 1.5, pl: 0.5, pb: 0 }}
          spacing={1}
          style={{ flexGrow: 1 }}
        >
          <LightbulbIcon sx={{ pt: 0.5 }} />

          <Typography
            variant="body1"
            color="inherit"
            data-content-idea-uuid={idea.uuid}
          >
            {idea.explanation}
          </Typography>
        </Stack>

        <Box
          // style={{ backgroundColor: "rgba(0,0,0,0.8)" }}
          sx={{ p: 1.5 }}
        >
          {duration < 10 && (
            <Stack sx={{ width: "100%", mb: 1 }} spacing={2}>
              <Alert variant="outlined" severity="warning">
                Looks like the AI has returned a video that's too short,
                consider trying again.
              </Alert>
            </Stack>
          )}

          <Stack direction="row" flexWrap="wrap" gap="0.5rem">
            <CreatedAtChip createdAt={idea.createdAt} />
            {idea.preset?.name && (
              <Tooltip
                title={
                  <span>
                    <b>Preset name:</b> {idea.preset?.name}
                  </span>
                }
              >
                <Chip
                  size="small"
                  color="info"
                  icon={<AutoFixHighIcon />}
                  label="Using Preset"
                  variant="filled"
                />
              </Tooltip>
            )}
            <Tooltip title="Start - End">
              <Chip
                size="small"
                label={`${durationToColonString(
                  idea.startTime
                )} - ${durationToColonString(idea.endTime)}`}
                variant="outlined"
              />
            </Tooltip>
            <Tooltip title="Duration">
              <Chip
                size="small"
                icon={<AlarmOnIcon />}
                label={`${durationToMSString(duration, false)}`}
                variant="outlined"
              />
            </Tooltip>
            <span>
              <CaptionChip
                contentAIExplanation={idea.explanation}
                aiTextChoice={idea.text}
                captionDataWithWordTimings={idea.srtWithWordTimings}
              />
            </span>
          </Stack>

          <Box
            display="flex"
            justifyContent="flex-start"
            width="100%"
            gap="0.5rem"
            sx={{ mt: 1 }}
          >
            {onEditIdea && (
              <Button
                onClick={() => onEditIdea(idea)}
                // startIcon={<EditIcon />}
                // startIcon={<AddIcon />}
                color="primary"
                size="small"
                variant="contained"
              >
                Refine and render
              </Button>
            )}
          </Box>
        </Box>
      </Stack>
    </Card>
  )
}

export default IdeaCard
