import { SRT } from '.'
import { SRTWithWordTimings } from './SRTWithWordTimings'
import timecodeFromSeconds from './timecodeFromSeconds'
import timecodeToSeconds from './timecodeToSeconds'

export default function addSecondsToSRT(
  srt: SRT[],
  secondsToAdd: number
): SRT[] {
  const newSRT = srt.map((entry) => {
    const { start, end } = entry
    const newStartTimecode = timecodeFromSeconds(
      Math.max(timecodeToSeconds(start, false) - secondsToAdd, 0)
    )
    const newEndTimecode = timecodeFromSeconds(
      Math.max(timecodeToSeconds(end, false) - secondsToAdd, 0)
    )

    const newCaption = {
      ...entry,
      start: newStartTimecode,
      end: newEndTimecode,
    }

    return newCaption
  })
  return newSRT
}

export function addSecondsToSRTWithWordTimings(
  srt: SRTWithWordTimings[],
  secondsToAdd: number
): SRTWithWordTimings[] {
  const newSRT = srt.map((entry) => {
    const { start, end } = entry
    const newStartMayBeNegative = (start || 0) - secondsToAdd
    let startDiff = 0
    if (newStartMayBeNegative < 0) startDiff = Math.abs(newStartMayBeNegative)
    const newStart = Math.max(newStartMayBeNegative, 0)

    const newEnd = Math.max((end || 0) - secondsToAdd, 0)

    const newCaption = {
      ...entry,
      words: entry.words.map((word) => ({
        ...word,
        start: word.start - startDiff,
      })),
      start: newStart,
      end: newEnd,
    }

    return newCaption
  })
  return newSRT
}
