import * as React from "react"
import { useNavigate, useParams } from "react-router-dom"

import dayjs, { Dayjs } from "dayjs"

import CloseIcon from "@mui/icons-material/Close"
import LoadingButton from "@mui/lab/LoadingButton"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Checkbox from "@mui/material/Checkbox"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Divider from "@mui/material/Divider"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import Paper from "@mui/material/Paper"
import Skeleton from "@mui/material/Skeleton"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DateTimeField } from "@mui/x-date-pickers/DateTimeField"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"

import deleteUser from "../../../../api/users/delete"
import fetchUser from "../../../../api/users/fetch"
import updateUser from "../../../../api/users/update"
import HeadingContainer from "../../../../components/HeadingContainer"
import { useAppDispatch, useAppSelector } from "../../../../redux"
import { setCurrentUserProfile } from "../../../../redux/currentUserProfile"
import { addSnackbar } from "../../../../redux/snackbars"
import { User } from "../../../../types/User"
import UserUsage from "../../../Account/pages/AccountUsage/UserUsage"

export default function AdminEditUser() {
  const { userUuid } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)

  const [userLoading, setUserLoading] = React.useState(true)
  const [user, setUser] = React.useState<User | null>(null)
  const [errorMessage, setErrorMessage] = React.useState("")

  const [stripeSubscriptionEndsAt, setStripeSubscriptionEndsAt] =
    React.useState<Dayjs | null>(dayjs("2022-00-00T00:00"))

  const [isAdmin, setIsAdmin] = React.useState(false)
  const [emailConfirmed, setEmailConfirmed] = React.useState(false)
  const [firstname, setFirstname] = React.useState("")
  const [lastname, setLastname] = React.useState("")
  const [stripeSubscriptionType, setStripeSubscriptionType] = React.useState("")
  const [stripeSubscriptionStatus, setStripeSubscriptionStatus] =
    React.useState("")

  const [isDirty, setDirty] = React.useState(false)
  const [isSaving, setSaving] = React.useState(false)

  const [areYouSureDeleteOpen, setAreYouSureDeleteOpen] = React.useState(false)
  const [isDeleting, setIsDeleting] = React.useState(false)

  const handleReceiveUserProfile = (user: User) => {
    setUser(user)
    setFirstname(user.firstname)
    setLastname(user.lastname)
    setStripeSubscriptionType(user.stripeSubscriptionType || "")
    setIsAdmin(user.isAdmin || false)
    setEmailConfirmed(user.emailConfirmed || false)
    setStripeSubscriptionStatus(user.stripeSubscriptionStatus || "")
    setStripeSubscriptionEndsAt(dayjs(user.stripeSubscriptionEndsAt))
  }

  React.useEffect(() => {
    fetchUser(userUuid || "")
      .then((user) => {
        setUserLoading(false)
        handleReceiveUserProfile(user)
      })
      .catch((err) => {
        console.error(err)
        setUserLoading(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message
        )
      })
  }, [userUuid])

  const deleteUserCallback = React.useCallback(() => {
    setIsDeleting(true)
    return deleteUser(userUuid || "")
      .then(() => {
        setIsDeleting(false)
        navigate("/app/admin/users")
        dispatch(addSnackbar({ text: "User deleted." }))
      })
      .catch((err) => {
        setIsDeleting(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message ||
            "Failed to delete user."
        )
      })
  }, [dispatch, navigate, userUuid])

  const handleSave = () => {
    setSaving(true)
    updateUser(userUuid || "", {
      isAdmin,
      emailConfirmed,
      firstname,
      lastname,
      stripeSubscriptionType,
      stripeSubscriptionEndsAt: stripeSubscriptionEndsAt?.toJSON(),
      stripeSubscriptionStatus,
    })
      .then((user) => {
        handleReceiveUserProfile(user)
        setSaving(false)
        dispatch(addSnackbar({ text: "User updated." }))
        if (currentUserProfile && user.uuid === currentUserProfile.uuid)
          dispatch(setCurrentUserProfile(user))
      })
      .catch((err) => {
        console.error(err)
        setSaving(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message
        )
      })
  }

  return (
    <div>
      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error" onClose={() => setErrorMessage("")}>
            {errorMessage}
          </Alert>
        </Stack>
      )}

      <Dialog
        open={areYouSureDeleteOpen}
        onClose={() => setAreYouSureDeleteOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">Delete user?</DialogTitle>

        <DialogContent>
          <Typography>This action cannot be undone.</Typography>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            onClick={() => deleteUserCallback()}
            loading={isDeleting}
            color="warning"
            variant="contained"
          >
            Delete
          </LoadingButton>
          <LoadingButton onClick={() => setAreYouSureDeleteOpen(false)}>
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <HeadingContainer>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            onClick={(e) => {
              e.preventDefault()
              navigate("/app/admin/users")
            }}
            underline="hover"
            color="inherit"
            href="/app/admin/users"
          >
            Users
          </Link>

          <Typography color="text.primary">
            {user && user.email}
            {userLoading && (
              <Skeleton animation="wave" style={{ minWidth: "200px" }} />
            )}
            {!userLoading && !user && "Unknown"}
          </Typography>
        </Breadcrumbs>
      </HeadingContainer>

      <Divider sx={{ mb: 2 }} />

      {userLoading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress color="inherit" />
        </Box>
      )}

      {!userLoading && (
        <Container maxWidth="sm">
          <Paper sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  value={firstname || ""}
                  label="First Name"
                  onChange={(e) => {
                    setDirty(true)
                    setFirstname(e.target.value)
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  autoComplete="family-name"
                  name="lastName"
                  required
                  fullWidth
                  id="lastName"
                  value={lastname || ""}
                  label="Last Name"
                  onChange={(e) => {
                    setDirty(true)
                    setLastname(e.target.value)
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  label="Is Admin?"
                  sx={{ color: "text.primary" }}
                  control={
                    <Checkbox
                      checked={isAdmin}
                      onChange={(e) => {
                        setDirty(true)
                        setIsAdmin(e.target.checked)
                      }}
                    />
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  label="Email Confirmed?"
                  sx={{ color: "text.primary" }}
                  control={
                    <Checkbox
                      checked={emailConfirmed}
                      onChange={(e) => {
                        setDirty(true)
                        setEmailConfirmed(e.target.checked)
                      }}
                    />
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel shrink size="small" id="importJobs">
                    Stripe Subscription Type
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="stripeSubscriptionType"
                    label="Stripe Subscription Type"
                    value={stripeSubscriptionType}
                    placeholder="None"
                    onChange={(e) => {
                      setDirty(true)
                      setStripeSubscriptionType(e.target.value)
                    }}
                  >
                    <MenuItem value="">
                      <Typography variant="body1">None</Typography>
                    </MenuItem>
                    <MenuItem value="STARTER">
                      <Typography variant="body1">STARTER</Typography>
                    </MenuItem>
                    <MenuItem value="PREMIUM">
                      <Typography variant="body1">PREMIUM</Typography>
                    </MenuItem>
                    <MenuItem value="UNLIMITED">
                      <Typography variant="body1">UNLIMITED</Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel shrink size="small" id="importJobs">
                    Stripe Subscription Status
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="stripeSubscriptionStatus"
                    label="Stripe Subscription Status"
                    value={stripeSubscriptionStatus}
                    placeholder="None"
                    onChange={(e) => {
                      setDirty(true)
                      setStripeSubscriptionStatus(e.target.value)
                    }}
                  >
                    {[
                      "",
                      "active",
                      "canceled",
                      "incomplete",
                      "incomplete_expired",
                      "past_due",
                      "trialing",
                      "unpaid",
                    ].map((status) => (
                      <MenuItem value={status} key={status}>
                        <Typography
                          variant="body1"
                          style={{ textTransform: "capitalize" }}
                        >
                          {status === "" ? "None" : status}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimeField
                    label="Subscription ends at"
                    fullWidth
                    value={stripeSubscriptionEndsAt}
                    onChange={(newValue) => {
                      setDirty(true)
                      setStripeSubscriptionEndsAt(newValue)
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              {userUuid && (
                <Grid item xs={12}>
                  <Box sx={{ p: 2 }}>
                    <UserUsage userUuid={userUuid} />
                  </Box>
                </Grid>
              )}

              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  onClick={() => setAreYouSureDeleteOpen(true)}
                  loading={isDeleting}
                  variant="contained"
                  color="warning"
                >
                  <CloseIcon fontSize="small" sx={{ mr: 1 }} /> Delete User
                </LoadingButton>
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  onClick={handleSave}
                  loading={isSaving}
                  disabled={!isDirty || userLoading || !user}
                  variant="contained"
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      )}
    </div>
  )
}
