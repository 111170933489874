import * as React from "react"
import { useNavigate } from "react-router-dom"

import { format } from "date-fns"

import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import AutoModeIcon from "@mui/icons-material/AutoMode"
import CheckIcon from "@mui/icons-material/Check"
import EditIcon from "@mui/icons-material/Edit"
import EmailIcon from "@mui/icons-material/Email"
import LocalAtmIcon from "@mui/icons-material/LocalAtm"
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import Chip from "@mui/material/Chip"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import Pagination from "@mui/material/Pagination"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"

import searchUsers from "../../../../api/users/search"
import FilterContainer from "../../../../components/FilterContainer"
import HeadingContainer from "../../../../components/HeadingContainer"
import nameForUser from "../../../../helpers/strings/nameForUser"
import { User } from "../../../../types/User"

let queryTimeout: NodeJS.Timeout

const PER_PAGE = 20

export default function AdminUserManagement() {
  const navigate = useNavigate()

  const [usersLoading, setUsersLoading] = React.useState(true)
  const [users, setUsers] = React.useState<User[] | null>(null)
  const [page, setPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(0)
  const [errorMessage, setErrorMessage] = React.useState("")
  const [subscribed, setSubscribed] = React.useState(false)
  const [totalUsers, setTotalUsers] = React.useState(0)

  const [email, setEmail] = React.useState("")
  const [name, setName] = React.useState("")

  const handleSearchUsers = React.useCallback(() => {
    searchUsers({
      email,
      name,
      page,
      perPage: PER_PAGE,
      subscribed,
    })
      .then(({ users, totalPages, totalResults }) => {
        if (totalPages < page) setPage(totalPages)
        setUsers(users)
        setTotalPages(totalPages)
        setTotalUsers(totalResults)
        setUsersLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setUsersLoading(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message
        )
      })
  }, [email, name, page, subscribed])

  React.useEffect(() => {
    setUsersLoading(true)
    clearTimeout(queryTimeout)
    queryTimeout = setTimeout(() => {
      handleSearchUsers()
    }, 300)
  }, [name, email, page, handleSearchUsers])

  return (
    <div>
      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error" onClose={() => setErrorMessage("")}>
            {errorMessage}
          </Alert>
        </Stack>
      )}

      <HeadingContainer>
        <Typography component="h1" variant="h6" noWrap color="textPrimary">
          Users
        </Typography>
      </HeadingContainer>

      <FilterContainer>
        <TextField
          size="small"
          value={email || ""}
          onChange={(e) => setEmail(e.target.value)}
          label="Email"
          variant="outlined"
        />

        <TextField
          size="small"
          value={name || ""}
          onChange={(e) => setName(e.target.value)}
          label="Name"
          variant="outlined"
        />

        <FormControlLabel
          // color="textPrimary"
          // sx={{
          //   color: "inherit",
          // }}
          control={
            <Checkbox
              checked={subscribed}
              onChange={(e) => setSubscribed(e.target.checked)}
            />
          }
          label="Subscribed"
        />
      </FilterContainer>

      <Divider sx={{ mb: 2 }} />

      {usersLoading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress color="inherit" />
        </Box>
      )}

      {!usersLoading && (
        <Container maxWidth="md">
          <Paper sx={{ mb: 2, p: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body1" color="textSecondary">
                {totalUsers} users
              </Typography>

              <Pagination
                count={totalPages}
                page={page}
                onChange={(_, v) => setPage(v)}
              />
            </Stack>
          </Paper>

          {users &&
            users.map((user) => (
              <Paper sx={{ mb: 2 }} key={user.uuid}>
                <Grid container columns={12} sx={{ p: 1 }}>
                  <Grid item xs={12} sm={6} sx={{ p: 1 }}>
                    <Stack direction="column">
                      <Typography variant="body1" data-user-uuid={user.uuid}>
                        {user.email}{" "}
                        {nameForUser(user) && `(${nameForUser(user)})`}
                      </Typography>
                      {user.whoAmI && (
                        <Typography variant="body2">{user.whoAmI}</Typography>
                      )}
                      {user.tellUsAboutYourself && (
                        <Typography variant="body2">
                          {user.tellUsAboutYourself}
                        </Typography>
                      )}
                      <Typography variant="subtitle2" color="text.secondary">
                        Created {format(new Date(user.createdAt), "Pp")}
                      </Typography>
                      {user.lastLoggedInAt && (
                        <Typography variant="subtitle2" color="text.secondary">
                          Signed in{" "}
                          {format(new Date(user.lastLoggedInAt), "Pp")}
                        </Typography>
                      )}
                      <Stack direction="row">
                        {Boolean(user.importJobCount) && (
                          <Chip
                            size="small"
                            sx={{ my: 0.5, mr: 1 }}
                            label={`${user.importJobCount} Import Jobs`}
                            clickable
                            onClick={() => {
                              navigate(
                                `/app/admin/import-jobs?userEmail=${encodeURIComponent(
                                  user.email
                                )}`
                              )
                            }}
                          />
                        )}
                        {Boolean(user.renderJobCount) && (
                          <Chip
                            clickable
                            onClick={() => {
                              navigate(
                                `/app/admin/render-jobs?userEmail=${encodeURIComponent(
                                  user.email
                                )}`
                              )
                            }}
                            size="small"
                            sx={{ my: 0.5, mr: 1 }}
                            label={`${user.renderJobCount} Render Jobs`}
                          />
                        )}
                        {Boolean(user.contentIdeaCount) && (
                          <Chip
                            size="small"
                            sx={{ my: 0.5, mr: 1 }}
                            label={`${user.contentIdeaCount} Content Ideas`}
                          />
                        )}
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ p: 1 }}>
                    <Stack direction="row" justifyContent="flex-end">
                      {user.emailConfirmed && (
                        <Tooltip title="Email confirmed">
                          <IconButton>
                            <CheckIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {user.subscribedToNewsletter && (
                        <Tooltip title="Subscribed to newsletter">
                          <IconButton>
                            <MarkEmailReadIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {user.stripeSubscriptionEndsAt && (
                        <Tooltip
                          title={
                            `Paid subscription (${user.stripeSubscriptionType})` +
                            (user.stripeSubscriptionStatus
                              ? ` (${user.stripeSubscriptionStatus})`
                              : "") +
                            ` (period ends ${format(
                              new Date(user.stripeSubscriptionEndsAt),
                              "dd/MM/yyyy"
                            )})` +
                            (user.stripeSubscriptionCancelled
                              ? " (cancelled)"
                              : "")
                          }
                        >
                          <IconButton>
                            <LocalAtmIcon
                              color={
                                user.hasActiveStripeSubscription
                                  ? user.stripeSubscriptionCancelled
                                    ? "warning"
                                    : "success"
                                  : "error"
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      {user.isAdmin && (
                        <Tooltip title="Admin">
                          <IconButton>
                            <AdminPanelSettingsIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {user.dailyIdeaEmailEnabled && (
                        <Tooltip
                          title={
                            user.dailyIdeaEmailTime
                              ? `Daily emails set for ${format(
                                  new Date(user.dailyIdeaEmailTime),
                                  "p"
                                )}`
                              : "Daily email set but no time"
                          }
                        >
                          <IconButton>
                            <AutoModeIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {user.dailyIdeaLastSentAt && (
                        <Tooltip
                          title={`Daily idea sent at ${format(
                            new Date(user.dailyIdeaLastSentAt),
                            "Pp"
                          )}`}
                        >
                          <IconButton>
                            <EmailIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Edit">
                        <IconButton
                          onClick={() =>
                            navigate(`/app/admin/users/${user.uuid}`)
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>
            ))}
        </Container>
      )}
    </div>
  )
}
