// fontName is ubuntu style, browserName is what the browser uses
export const fonts = [
  { browserName: "AbrilFatface-Regular", fontName: "Abril Fatface" },
  { browserName: "AlfaSlabOne-Regular", fontName: "Alfa Slab One" },
  { browserName: "Anton-Regular", fontName: "Anton" },
  { browserName: "Arvo-Bold", fontName: "Arvo Bold" },
  { browserName: "Dosis-Bold", fontName: "Dosis Bold" },
  { browserName: "Dosis-ExtraBold", fontName: "Dosis ExtraBold" },
  { browserName: "FjallaOne-Regular", fontName: "Fjalla One Bold" },
  { browserName: "IBMPlexMono-Bold", fontName: "IBM Plex Mono Bold" },
  { browserName: "IBMPlexMono-SemiBold", fontName: "IBM Plex Mono SemiBold" },
  { browserName: "Inter-Black", fontName: "Inter Black" },
  { browserName: "Inter-Bold", fontName: "Inter Bold" },
  { browserName: "Inter-ExtraBold", fontName: "Inter ExtraBold" },
  {
    browserName: "LibreBaskerville-Bold",
    fontName: "Libre Baskerville Bold",
  },
  { browserName: "Montserrat-Black", fontName: "Montserrat Black" },
  { browserName: "Montserrat-Bold", fontName: "Montserrat Bold" },
  { browserName: "Montserrat-ExtraBold", fontName: "Montserrat ExtraBold" },
  {
    browserName: "PlayfairDisplay-Black",
    fontName: "Playfair Display Black",
  },
  { browserName: "PlayfairDisplay-Bold", fontName: "Playfair Display Bold" },
  {
    browserName: "PlayfairDisplay-ExtraBold",
    fontName: "Playfair Display ExtraBold",
  },
  { browserName: "Rokkitt-Black", fontName: "Rokkitt Black" },
  { browserName: "Rokkitt-Bold", fontName: "Rokkitt Bold" },
  { browserName: "Rokkitt-ExtraBold", fontName: "Rokkitt ExtraBold" },
  { browserName: "Rubik-Black", fontName: "Rubik Black" },
  { browserName: "Rubik-ExtraBold", fontName: "Rubik ExtraBold" },
  { browserName: "Slabo27px-Regular", fontName: "Slabo 27px" },
  { browserName: "Sora-Bold", fontName: "Sora Bold" },
  { browserName: "Sora-ExtraBold", fontName: "Sora ExtraBold" },
  { browserName: "ZillaSlab-Bold", fontName: "Zilla Slab Bold" },
  { browserName: "Socake", fontName: "Socake" },
]
