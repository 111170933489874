import axios from "axios"

// import rough from "roughjs"

// import { basecolor } from "./mdTheme"

// const WIDTH = 1920
// const HEIGHT = 1080

async function loadImageAsBlob(url) {
  try {
    const response = await axios.get(url, {
      responseType: "blob", // this is essential to get it as a blob
    })

    return response.data
  } catch (error) {
    console.error("Error loading image:", error)
    throw error
  }
}

export default async function createPresetThumbnail(): Promise<
  Blob | undefined
> {
  // const canvas = document.createElement("canvas")
  // const rc = rough.canvas(canvas)

  // canvas.width = WIDTH
  // canvas.height = HEIGHT

  // return new Promise((resolve, reject) => {
  // rc.rectangle(0, 0, WIDTH, HEIGHT, { fill: basecolor, fillStyle: "solid" })
  // rc.circle(
  //   Math.random() * WIDTH,
  //   Math.random() * HEIGHT,
  //   Math.random() * HEIGHT,
  //   {
  //     fill: textHighlightColor,
  //     // fillStyle: "dots",
  //     fillWeight: 30,
  //     strokeWidth: 0,
  //   }
  // )

  // rc.circle(
  //   Math.random() * WIDTH,
  //   Math.random() * HEIGHT,
  //   Math.random() * HEIGHT,
  //   {
  //     fill: strokeColor,
  //     fillWeight: 30,
  //     strokeWidth: 0,
  //   }
  // )

  try {
    const imageURL = "/img/home-bg-thumb.jpg"
    return await loadImageAsBlob(imageURL)
  } catch (err) {
    console.error("Error loading default image:", err)
  }

  // canvas.toBlob(
  //   (blob) => {
  //     if (!blob) return reject(new Error("Error generating thumbnail"))
  //     resolve(blob)
  //   },
  //   "image/jpeg",
  //   0.3
  // )
}
