import * as React from "react"

import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"

import ConditionalInfoAlert from "../../../../components/ConditionalInfoAlert"
import HeadingContainer from "../../../../components/HeadingContainer"
import DailyIdeaSettings from "./DailyIdeaSettings"

const DailyIdeasManagement: React.FC = () => {
  return (
    <div>
      <HeadingContainer>
        <Typography component="h1" variant="h6" color="textPrimary" noWrap>
          Daily Ideas
        </Typography>
      </HeadingContainer>

      <Divider />

      <Container maxWidth="md" sx={{ pt: 2 }}>
        <ConditionalInfoAlert>
          Trimmr can automatically send a regular feed of content ideas to your
          inbox. Control which videos are used to generate ideas from the
          imported videos page.
          <br />
          <br />
          You will not receive more than one email in 24 hours. Ideas will
          arrive roughly at the chosen time. If an email doesn't come through on
          a given day, it may be because the AI failed to generate an idea, or
          your video was missing captions.
        </ConditionalInfoAlert>

        <DailyIdeaSettings />
      </Container>
    </div>
  )
}

export default DailyIdeasManagement
