import * as React from "react"
import { useDispatch } from "react-redux"

import { Preset } from "@trimmr/trimmr-lib/types/Preset"

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import DeleteIcon from "@mui/icons-material/Delete"
// import EditIcon from "@mui/icons-material/Edit"
import LoadingButton from "@mui/lab/LoadingButton"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import CircularProgress from "@mui/material/CircularProgress"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Grow from "@mui/material/Grow"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import Paper from "@mui/material/Paper"
import Popper from "@mui/material/Popper"

import deletePreset from "../../../../api/presets/delete"
import { addSnackbar } from "../../../../redux/snackbars"
import EditPresetModal from "./EditPresetModal"

export default function PresetButtonGroup({
  preset,
  onPresetChanged,
}: {
  preset: Preset
  onPresetChanged: () => void
}) {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const [deletePresetLoading, setDeletePresetLoading] = React.useState(false)
  const [areYouSureOpen, setAreYouSureOpen] = React.useState(false)
  const [editPresetOpen, setEditPresetOpen] = React.useState(false)

  const handleDeletePreset = React.useCallback(async () => {
    try {
      setDeletePresetLoading(true)
      await deletePreset(preset.uuid)
      onPresetChanged()

      dispatch(
        addSnackbar({
          text: "Preset deleted.",
        })
      )
    } catch (err) {
      console.error("Failed to delete preset", err)

      dispatch(
        addSnackbar({
          text: "Failed to delete preset.",
        })
      )
      setDeletePresetLoading(false)
    }
  }, [preset.uuid, dispatch, onPresetChanged])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  return (
    <React.Fragment>
      <Dialog
        open={areYouSureOpen}
        onClose={() => setAreYouSureOpen(false)}
        aria-labelledby="areyousure-preset-delete-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="areyousure-preset-delete-dialog-title">
          Delete preset?
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you wish to delete this preset?
          </Typography>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            onClick={() => handleDeletePreset()}
            loading={deletePresetLoading}
            color="primary"
            variant="contained"
          >
            Delete
          </LoadingButton>
          <LoadingButton onClick={() => setAreYouSureOpen(false)}>
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {editPresetOpen && (
        <EditPresetModal
          preset={preset}
          open={editPresetOpen}
          onSave={() => {
            setEditPresetOpen(false)
            onPresetChanged()
          }}
          onClose={() => {
            setEditPresetOpen(false)
          }}
        />
      )}

      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        size="small"
        style={{ alignSelf: "start" }}
      >
        <Button onClick={() => setEditPresetOpen(true)}>
          {/* <EditIcon sx={{ mr: 2 }} /> */}
          Edit Preset
        </Button>

        <Button
          size="small"
          aria-controls={open ? "import-action-split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select import job action"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <MenuItem
                    disabled={deletePresetLoading}
                    onClick={() => setAreYouSureOpen(true)}
                  >
                    {deletePresetLoading ? (
                      <CircularProgress
                        size={20}
                        sx={{ mr: 2 }}
                        color="inherit"
                      />
                    ) : (
                      <DeleteIcon sx={{ mr: 2 }} />
                    )}
                    Delete Preset
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  )
}
