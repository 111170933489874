import * as React from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

// import PersonIcon from '@mui/icons-material/Person'
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark"
import EmailIcon from "@mui/icons-material/Email"
import HomeIcon from "@mui/icons-material/Home"
import LogoutIcon from "@mui/icons-material/Logout"
import MenuIcon from "@mui/icons-material/Menu"
import MovieFilterIcon from "@mui/icons-material/MovieFilter"
import PersonIcon from "@mui/icons-material/Person"
import { Typography } from "@mui/material"
import Badge from "@mui/material/Badge"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Stack from "@mui/material/Stack"
import { ThemeProvider } from "@mui/material/styles"

import logout from "../../api/auth/logout"
import { darkTheme, lightTheme } from "../../lib/mdTheme"
import { useAppSelector } from "../../redux"
import { setCurrentUserProfile } from "../../redux/currentUserProfile"
import { addSnackbar } from "../../redux/snackbars"

export default function AppBarItems() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & HTMLButtonElement) | null
  >(null)

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogoutClick = () => {
    logout()
      .then(() => {
        handleClose()
        navigate("/app")
        dispatch(setCurrentUserProfile(null))
        dispatch(addSnackbar({ text: "Log out successful." }))
      })
      .catch((err) => {
        console.error(err)
        handleClose()
        navigate("/app")
        dispatch(setCurrentUserProfile(null))
      })
  }

  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)

  const theme = darkModeEnabled ? darkTheme : lightTheme

  return (
    <ThemeProvider theme={theme}>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => navigate("/app")}>
          <Stack direction="row" alignItems="center" spacing={3}>
            <HomeIcon />
            <Typography>Home</Typography>
          </Stack>
        </MenuItem>
        {currentUserProfile && (
          <MenuItem onClick={() => navigate("/app/account/video-creator")}>
            <Stack direction="row" alignItems="center" spacing={3}>
              <MovieFilterIcon />
              <Typography>Creator</Typography>
            </Stack>
          </MenuItem>
        )}
        {currentUserProfile && (
          <MenuItem onClick={() => navigate("/app/account/subscription")}>
            <Stack direction="row" alignItems="center" spacing={3}>
              <PersonIcon />
              <Typography>Account</Typography>
            </Stack>
          </MenuItem>
        )}
        <MenuItem onClick={() => navigate("/app/blog")}>
          <Stack direction="row" alignItems="center" spacing={3}>
            <CollectionsBookmarkIcon />
            <Typography>Guides</Typography>
          </Stack>
        </MenuItem>

        <MenuItem onClick={() => navigate("/app/contact")}>
          <Stack direction="row" alignItems="center" spacing={3}>
            <EmailIcon />
            <Typography>Contact</Typography>
          </Stack>
        </MenuItem>
        {currentUserProfile && (
          <MenuItem onClick={handleLogoutClick}>
            <Stack direction="row" alignItems="center" spacing={3}>
              <LogoutIcon />
              <Typography>Logout</Typography>
            </Stack>
          </MenuItem>
        )}

        {currentUserProfile && currentUserProfile.isAdmin && (
          <MenuItem onClick={() => navigate("/app/admin/users")}>
            <Stack direction="row" alignItems="center" spacing={3}>
              <AdminPanelSettingsIcon color="info" />
              <Typography sx={{ color: theme.palette.info.main }}>
                Admin
              </Typography>
            </Stack>
          </MenuItem>
        )}
      </Menu>

      {!currentUserProfile && (
        <Button color="inherit" onClick={() => navigate("/app/signin")}>
          Sign In
        </Button>
      )}
      {!currentUserProfile && (
        <Button color="inherit" onClick={() => navigate("/app/signup")}>
          Sign Up
        </Button>
      )}

      <IconButton onClick={(e) => handleClick(e)} color="inherit">
        <Badge color="secondary">
          <MenuIcon color="inherit" />
        </Badge>
      </IconButton>
    </ThemeProvider>
  )
}
