import * as React from "react"
import Draggable from "react-draggable"
import { ResizableBox } from "react-resizable"

import { Loop, OffthreadVideo, Video } from "remotion"

import { Asset } from "@trimmr/trimmr-lib/types/Asset"
// import { SRT } from "@trimmr/trimmr-lib/types/SRT"
import { TextStyleAttributes } from "@trimmr/trimmr-lib/types/TextStyle"

import CloseIcon from "@mui/icons-material/Close"
import { Stack, styled } from "@mui/material"
import { Box, CircularProgress, Typography } from "@mui/material"

import { SubtitleWithWordTimings } from "./SubtitleWithWordTimings"
import { FPS } from "./constants"
import { horizontalAlignmentToFlex } from "./horizontalAlignmentToFlex"

export const DraggableStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== "hideDraggable",
})(({ hideDraggable }: { hideDraggable?: boolean }) => ({
  ".react-resizable-handle": {
    display: hideDraggable ? "none" : undefined,
  },
}))

export const DraggableRenderJobAsset: React.FC<{
  isInEditMode: boolean
  xPosition: number
  yPosition: number
  xScale: number
  yScale: number
  currentRenderScale: number
  originalWidth: number
  originalHeight: number
  assetUrl?: string
  textStyle?: TextStyleAttributes
  onPositionChange?: (newX, newY, newXScale, newYScale) => void
  type: string
  isPlayer: boolean
  muted: boolean
  originalDuration: number
  videoRef?: React.MutableRefObject<HTMLVideoElement | null>
  startFrom?: number
  endAt?: number
  loadingMessage?: string
  asset?: Asset
}> = ({
  isInEditMode,
  xPosition,
  yPosition,
  xScale,
  yScale,
  currentRenderScale,
  originalWidth,
  originalHeight,
  assetUrl,
  textStyle,
  onPositionChange,
  type,
  isPlayer,
  muted,
  originalDuration,
  videoRef,
  startFrom,
  endAt,
  loadingMessage,
  asset,
}) => {
  const text = (asset && asset.name) || "<none>"
  return (
    <div style={{ position: "absolute" }}>
      <Draggable
        axis="both"
        handle=".handle"
        disabled={!isInEditMode}
        position={{
          x: xPosition,
          y: yPosition,
        }}
        grid={[1, 1]}
        scale={currentRenderScale}
        onStop={(_, ui) => {
          const { lastX, lastY } = ui as unknown as {
            lastX: number
            lastY: number
          }

          const newX = lastX
          const newY = lastY

          onPositionChange && onPositionChange(newX, newY, xScale, yScale)
        }}
      >
        {type === "TEXT" && asset && textStyle ? (
          <Box
            className="handle"
            style={{
              position: "relative",
              width: "100%",
              height: "100%",

              userSelect: "none",

              cursor: isInEditMode ? "pointer" : "default",
              zIndex: 1,

              border: isInEditMode
                ? "5px solid red"
                : "0px solid rgba(0,0,0,0)",
              // : "5px outset rgba(0,0,0,0)",
            }}
          >
            <Stack
              direction="column"
              alignItems="stretch"
              justifyContent={
                horizontalAlignmentToFlex[textStyle.horizontalCaptionAlignment]
              }
            >
              {text.split("\n").map((lineText, i) => (
                <SubtitleWithWordTimings
                  key={`${lineText}${i}`}
                  frame={0}
                  noContainer
                  useTextPreformattedSizing
                  srtWithWordTimings={[
                    {
                      index: 0,
                      start: 0,
                      end: 1,
                      words: lineText.split(" ").map((word, index) => ({
                        uuid: `${word}${index}`,
                        text: word,
                        start: 0,
                      })),
                    },
                  ]}
                  textStyleAttributes={textStyle}
                  paddingVerticalPx={0}
                  paddingHorizontalPx={0}
                  startSeconds={1}
                  endSeconds={1}
                  backgroundColor={textStyle.backgroundColor}
                />
              ))}
            </Stack>
          </Box>
        ) : (
          <ResizableBox
            width={originalWidth * xScale}
            height={originalHeight * yScale}
            lockAspectRatio
            minConstraints={[5, 5]}
            disabled={!isInEditMode}
            style={{
              zIndex: 1,
              overflow: "hidden",
            }}
            transformScale={currentRenderScale}
            onResizeStop={(_, { size: newSize }) => {
              onPositionChange &&
                onPositionChange(
                  xPosition,
                  yPosition,
                  newSize.width / (originalWidth || 1),
                  newSize.height / (originalHeight || 1)
                )
            }}
          >
            <Box
              className="handle"
              style={{
                position: "relative",
                width: "100%",
                height: "100%",

                cursor: isInEditMode ? "pointer" : "default",

                border: isInEditMode
                  ? "5px solid red"
                  : "0px solid rgba(0,0,0,0)",
                // : "5px outset rgba(0,0,0,0)",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  position: "absolute",
                  inset: 0,
                  overflow: "hidden",
                  backgroundColor:
                    !assetUrl || isInEditMode
                      ? "rgba(255,255,255,0.75)"
                      : "transparent",
                }}
              >
                {assetUrl ? (
                  isInEditMode && (
                    <>
                      <CircularProgress
                        sx={{ mb: 2 }}
                        style={{ color: "red" }}
                        size={Math.min(
                          100,
                          0.2 *
                            Math.min(
                              xScale * originalWidth,
                              yScale * originalHeight
                            )
                        )}
                      />
                      {loadingMessage && (
                        <Typography
                          variant="subtitle2"
                          style={{ color: "red" }}
                          fontSize="3rem"
                          textAlign="center"
                        >
                          {loadingMessage}
                        </Typography>
                      )}
                    </>
                  )
                ) : (
                  <>
                    <CloseIcon
                      sx={{ mb: 2 }}
                      style={{ width: "50%", height: "50%", color: "red" }}
                    />

                    <Typography
                      variant="subtitle2"
                      style={{ color: "red" }}
                      fontSize="5rem"
                    >
                      Failed to load asset
                    </Typography>
                  </>
                )}
              </Box>

              {assetUrl && (
                <>
                  {type === "IMAGE" && (
                    <img
                      src={assetUrl || ""}
                      style={{
                        inset: 0,
                        pointerEvents: "none",
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  )}
                  {type === "VIDEO" && (
                    <>
                      {isPlayer && (
                        <Video
                          src={assetUrl}
                          loop
                          ref={videoRef}
                          muted={muted}
                          poster="/img/media-loading.jpg"
                          startFrom={startFrom}
                          endAt={endAt}
                          style={{
                            position: "relative",
                            height: "100%",
                            objectFit: "cover",
                            objectPosition: "center",
                          }}
                        />
                      )}
                      {!isPlayer && (
                        <Loop
                          durationInFrames={Math.floor(FPS * originalDuration)}
                        >
                          <OffthreadVideo
                            src={assetUrl}
                            muted={muted}
                            startFrom={startFrom}
                            endAt={endAt}
                            style={{
                              position: "relative",
                              height: "100%",
                              objectFit: "cover",
                              objectPosition: "center",
                            }}
                          />
                        </Loop>
                      )}
                    </>
                  )}
                </>
              )}
            </Box>
          </ResizableBox>
        )}
      </Draggable>
    </div>
  )
}
