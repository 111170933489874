import * as React from "react"

import { format } from "date-fns"

import { RenderJob } from "@trimmr/trimmr-lib/types/RenderJob"

import AlarmOnIcon from "@mui/icons-material/AlarmOn"
import CheckIcon from "@mui/icons-material/Check"
import DownloadIcon from "@mui/icons-material/Download"
import EditIcon from "@mui/icons-material/Edit"
import ErrorIcon from "@mui/icons-material/Error"
import { Button } from "@mui/material"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import Chip from "@mui/material/Chip"
import CircularProgress from "@mui/material/CircularProgress"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"

import updateRenderJob from "../../api/renderJobs/update"
import { downloadURI } from "../../helpers/files/downloadURI"
// import { downloadURI } from "../../helpers/files/downloadURI"
import durationToColonString from "../../helpers/soundFile/durationToColonString"
import durationToMSString from "../../helpers/soundFile/durationToMSString"
// import { darkTheme } from "../../lib/mdTheme"
// import { useAppSelector } from "../../redux"
import CaptionChip from "../CaptionChip"
import CreatedAtChip from "../CreatedAtChip"

export const RenderJobChips = ({ renderJob }: { renderJob: RenderJob }) => {
  const endTime =
    (renderJob.finalStartSeconds || renderJob.startSeconds) +
    (renderJob.finalDurationSeconds || renderJob.durationSeconds)

  return (
    <Stack direction="row" flexWrap="wrap" gap="0.5rem">
      <CreatedAtChip createdAt={renderJob.createdAt} />

      {renderJob.completedAt && (
        <Tooltip
          title={`Completed ${format(new Date(renderJob.completedAt), "Pp")}`}
        >
          <Chip
            size="small"
            label={`Took ${durationToMSString(
              (new Date(renderJob.completedAt).getTime() -
                new Date(renderJob.createdAt).getTime()) /
                1000
            )}`}
          />
        </Tooltip>
      )}

      {renderJob.failed && (
        <Tooltip title={renderJob.failedReason}>
          <Chip
            size="small"
            icon={<ErrorIcon />}
            label="Failed"
            color="error"
            variant="filled"
          />
        </Tooltip>
      )}
      <Tooltip title="Start - End">
        <Chip
          size="small"
          label={`${durationToColonString(
            renderJob.finalStartSeconds || renderJob.startSeconds
          )} - ${durationToColonString(endTime)}`}
          variant="outlined"
        />
      </Tooltip>
      <Tooltip title="Duration">
        <Chip
          size="small"
          icon={<AlarmOnIcon />}
          label={`${durationToMSString(
            renderJob.finalDurationSeconds || renderJob.durationSeconds,
            false
          )}`}
          variant="outlined"
        />
      </Tooltip>
      {/* {renderJob.contentAIExplanation && (
          <Tooltip title={renderJob.contentAIExplanation}>
            <Chip
              size="small"
              icon={<LightbulbIcon />}
              sx={{ my: 0.5, mr: 1 }}
              variant="outlined"
              label="Explanation"
            />
          </Tooltip>
        )} */}
      {renderJob.srtWithWordTimings && (
        <CaptionChip
          contentAIExplanation={renderJob.contentAIExplanation}
          aiTextChoice={renderJob.aiTextChoice}
          captionDataWithWordTimings={renderJob.srtWithWordTimings}
        />
      )}
    </Stack>
  )
}

export default function RenderJobCard({
  renderJob,
  onUpdateRenderJob,
  openNewJobModalFromRenderJob,
}: {
  renderJob: RenderJob
  onUpdateRenderJob: (renderJob: RenderJob) => void
  openNewJobModalFromRenderJob?: (renderJob: RenderJob | null) => void
}) {
  // const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  const [toggleEmailNotificationLoading, setToggleEmailNotificationLoading] =
    React.useState(false)

  const handleToggleEmailNotification = React.useCallback(async () => {
    if (!renderJob) return
    setToggleEmailNotificationLoading(true)

    try {
      const newRenderJob = await updateRenderJob(renderJob.uuid, {
        notifyOnComplete: !renderJob.notifyOnComplete,
      })

      onUpdateRenderJob(newRenderJob)
      setToggleEmailNotificationLoading(false)
    } catch (err) {
      console.error("Error updating render job notifyOnComplete", err)
      setToggleEmailNotificationLoading(false)
    }
  }, [onUpdateRenderJob, renderJob])

  // note aspect ratio will not be respected if content overflows
  const aspectRatio = renderJob.completed
    ? `${renderJob.resolutionWidth}/${renderJob.resolutionHeight}`
    : undefined

  return (
    <Paper>
      <Box
        style={{
          aspectRatio,
          width: "100%",
          // backgroundColor: darkModeEnabled ? "#222" : "#444",
          position: "relative",
        }}
      >
        {renderJob.completed && (
          <video
            playsInline
            controls
            poster={renderJob.thumbnailURL}
            style={{
              aspectRatio,
              width: "100%",
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
            preload="none"
          >
            <source src={renderJob.videoURL} type="video/mp4" />
          </video>
        )}

        {!renderJob.completed && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{ py: 2 }}
          >
            {!renderJob.failed && (
              <>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ mt: 2, mb: 1, px: 2.5 }}
                  alignItems="center"
                  style={{ width: "100%" }}
                >
                  <CircularProgress
                    size={20}
                    style={{ color: "inherit", minWidth: "22px" }}
                    sx={{ mr: 1 }}
                  />

                  <Typography
                    variant="subtitle1"
                    textAlign="left"
                    style={{ lineHeight: 1.2, color: "inherit" }}
                  >
                    Rendering is in progress, check back in a few minutes.
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ mt: 2, mb: 1, px: 1 }}
                  alignItems="center"
                  style={{ width: "100%" }}
                >
                  <Checkbox
                    disabled={toggleEmailNotificationLoading}
                    checked={Boolean(renderJob.notifyOnComplete)}
                    onChange={() => handleToggleEmailNotification()}
                    color="primary"
                    id="notifyOnCompleteRender"
                  />

                  <Typography
                    variant="subtitle1"
                    textAlign="left"
                    color="inherit"
                    style={{ lineHeight: 1.2 }}
                  >
                    <label htmlFor="notifyOnCompleteRender">
                      Email me when rendering completes?
                    </label>
                  </Typography>
                </Stack>

                {renderJob.notifyOnComplete && (
                  <Alert
                    severity="success"
                    icon={<CheckIcon />}
                    sx={{ m: 2, mb: 0 }}
                  >
                    You will be notified by email when this render is complete,
                    feel free to close your browser.
                  </Alert>
                )}
              </>
            )}
            {renderJob.failed && (
              <>
                <ErrorIcon sx={{ mb: 2, mt: 2 }} style={{ color: "inherit" }} />
                <Typography
                  variant="subtitle2"
                  style={{ color: "inherit" }}
                  sx={{ mb: 2 }}
                >
                  {renderJob.failedReason}
                </Typography>
              </>
            )}
          </Box>
        )}
      </Box>

      <Box style={{ zIndex: 1 }} sx={{ p: 1.5 }}>
        <RenderJobChips renderJob={renderJob} />
        <Box
          display="flex"
          justifyContent="flex-start"
          width="100%"
          gap="0.5rem"
          sx={{ mt: 1 }}
        >
          {openNewJobModalFromRenderJob && (
            <Button
              onClick={() => openNewJobModalFromRenderJob(renderJob)}
              color="primary"
              size="small"
              variant="contained"
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          )}
          {renderJob.completed && (
            <Button
              onClick={() =>
                downloadURI(
                  `/api/v1/files/render-jobs/${renderJob.uuid}/video.mp4`,
                  "video.mp4"
                )
              }
              color="secondary"
              size="small"
              variant="contained"
              startIcon={<DownloadIcon />}
            >
              Download
            </Button>
          )}
        </Box>
      </Box>
    </Paper>
  )
}
