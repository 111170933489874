import * as React from "react"

import { QueryFunction, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { v4 as uuidv4 } from "uuid"

import { RenderJobInitialParams } from "@trimmr/trimmr-lib/types/RenderJob"
import { SRTWithWordTimings } from "@trimmr/trimmr-lib/types/SRT/SRTWithWordTimings"

import LoadingButton from "@mui/lab/LoadingButton"
import { Button, TablePagination, Tooltip } from "@mui/material"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Stack from "@mui/material/Stack"

import getCaptionsWithWordTimings from "../../api/importJobs/getCaptionsWithWordTimings"
import usePrevious from "../../lib/usePrevious"
import { useAppDispatch, useAppSelector } from "../../redux"
import { addSnackbar } from "../../redux/snackbars"
import { ImportJob } from "../../types/ImportJob"
import { LanguageOption } from "../NewImportJobForm/LanguageSelector"
import { TablePaginationActions } from "../TablePaginationActions"
import { AddCaptionSectionButton } from "./AddCaptionSectionButton"
import { MemoizedCaptionEditorRowWithWordTimings } from "./CaptionEditorRowWithWordTimings"

const PER_PAGE = 10

export interface Props {
  setSrtWithWordTimings?: (data: SRTWithWordTimings[] | undefined) => void
  srtWithWordTimings?: SRTWithWordTimings[]
  importJob?: ImportJob
  filterStartTime?: number
  filterEndTime?: number
  renderStaticCaptionData?: SRTWithWordTimings[]
  startAndEndTimes?: {
    startTime: number
    endTime: number
  }
  initialParams?: RenderJobInitialParams | null
  setCaptionsStale?: (captionsStale: boolean) => void
}

const CaptionEditorWithWordTimings: React.FC<Props> = ({
  importJob,
  srtWithWordTimings,
  setSrtWithWordTimings,
  filterStartTime,
  filterEndTime,

  renderStaticCaptionData,

  startAndEndTimes,
  initialParams,
  setCaptionsStale,
}) => {
  const [errorMessage, setErrorMessage] = React.useState("")
  const prevImportJobUuid = usePrevious(importJob?.uuid || "")
  const [language, setLanguage] = React.useState<LanguageOption | null>({
    id: "en",
    name: "English",
    autogenerated: true,
  })
  const [page, setPage] = React.useState(0)
  const prevInitialParamsUuid = usePrevious(initialParams?.uuid || "")

  const dispatch = useAppDispatch()

  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)

  const importJobUuid = importJob ? importJob.uuid : "unknown"
  const rows = renderStaticCaptionData || srtWithWordTimings

  const initialParamsSRT = initialParams?.srtWithWordTimings

  const initialParamsUuid = initialParams?.uuid
  const languageId = language?.id

  React.useEffect(() => {
    if (prevImportJobUuid !== importJob?.uuid && setSrtWithWordTimings)
      setSrtWithWordTimings(undefined)
  }, [importJob?.uuid, prevImportJobUuid, setSrtWithWordTimings])

  const fetchCaptions: QueryFunction<SRTWithWordTimings[]> = async (): Promise<
    SRTWithWordTimings[]
  > => {
    try {
      dispatch(addSnackbar({ text: "Fetching captions..." }))
      setCaptionsStale && setCaptionsStale(false)
      setErrorMessage("")

      let srt: SRTWithWordTimings[] = srtWithWordTimings || []

      let newCaptionData
      if (renderStaticCaptionData) {
        newCaptionData = renderStaticCaptionData
      } else if (startAndEndTimes) {
        // user-uploaded video is different
        if (importJob && importJob.videoFileOriginalName) {
          newCaptionData = await getCaptionsWithWordTimings(
            importJobUuid as string,
            {
              startTime: startAndEndTimes.startTime,
              endTime: startAndEndTimes.endTime,
              filename: `en.trcaptions`,
            }
          )
        } else {
          newCaptionData = await getCaptionsWithWordTimings(
            importJobUuid as string,
            {
              startTime: startAndEndTimes.startTime,
              endTime: startAndEndTimes.endTime,
              filename: `video.${languageId}.json3`,
            }
          )
        }
      }

      if (!newCaptionData) {
        setErrorMessage("No captions found.")
        srt = []
      } else {
        srt = newCaptionData
      }

      return srt
    } catch (err) {
      const axiosError = err as AxiosError
      console.error(err)
      throw new Error(axiosError.response?.data?.error || axiosError.message)
    }
  }

  const {
    data: initialCaptions,
    error: fetchCaptionsError,
    isFetching: loading,
    refetch: refetchCaptions,
  } = useQuery<SRTWithWordTimings[], AxiosError | Error>(
    ["srtWithWordTimings", startAndEndTimes, importJobUuid, languageId],
    fetchCaptions,
    { retry: false }
  )

  React.useEffect(() => {
    fetchCaptionsError && setErrorMessage(fetchCaptionsError.message)
  }, [fetchCaptionsError])

  const previousInitialCaptions = usePrevious(initialCaptions)
  React.useEffect(() => {
    if (previousInitialCaptions !== initialCaptions)
      setSrtWithWordTimings && setSrtWithWordTimings(initialCaptions)
  }, [initialCaptions, previousInitialCaptions, setSrtWithWordTimings])

  React.useEffect(() => {
    if (!prevInitialParamsUuid && initialParamsUuid) {
      initialParamsSRT &&
        setSrtWithWordTimings &&
        setSrtWithWordTimings(initialParamsSRT)
    }
  }, [
    initialParamsSRT,
    initialParamsUuid,
    prevInitialParamsUuid,
    setSrtWithWordTimings,
  ])

  const handleSRTChange = React.useCallback(
    (data: SRTWithWordTimings) => {
      const srtObject: SRTObject = {}
      if (!srtWithWordTimings) return
      const arrIndex = data.index

      // const oldStart = srtArrToObject(srtWithWordTimings)[arrIndex]?.start || 0

      rows?.forEach((entry) => {
        if (
          typeof filterStartTime === "number" &&
          typeof filterEndTime === "number"
        ) {
          if ((entry.end || 0) < filterStartTime) return
          if ((entry.start || 0) > filterEndTime) return
        }

        // if (entry.start !== oldStart)
        //   entry.words = entry.words.map((word) => ({
        //     ...word,
        //     start: word.start + ((entry.start || oldStart) - oldStart),
        //   }))

        srtObject[entry.index] = entry
      })

      if (!srtObject) return
      const newObj = { ...srtObject }

      // are we accidentally directly mutating state?
      newObj[arrIndex].start = data.start
      newObj[arrIndex].end = data.end
      newObj[arrIndex].words = data.words.sort((a, b) => a.start - b.start)

      setSrtWithWordTimings && setSrtWithWordTimings(Object.values(newObj))
    },
    [
      filterEndTime,
      filterStartTime,
      rows,
      setSrtWithWordTimings,
      srtWithWordTimings,
    ]
  )

  const handleSRTClear = () => {
    // setCaptionsStale && setCaptionsStale(false)
    setSrtWithWordTimings && setSrtWithWordTimings([])
  }

  const handleSRTRemove = React.useCallback(
    (srtIndex: number) => {
      const arr = [...(srtWithWordTimings || [])]
      if (!arr) return

      // SRT is 1-indexed
      arr.splice(srtIndex, 1)

      const newSrtObject = srtArrToObject(arr)
      setSrtWithWordTimings &&
        setSrtWithWordTimings(Object.values(newSrtObject))
    },
    [setSrtWithWordTimings, srtWithWordTimings]
  )
  const handleAddCaptionSection = React.useCallback(
    (startOrSRTIndex: "start" | "end" | number) => {
      const arr = [...(srtWithWordTimings || [])]
      let newSRTArray: SRTWithWordTimings[] = arr || []

      if (startOrSRTIndex === newSRTArray.length) startOrSRTIndex = "end"

      if (newSRTArray.length === 0) {
        const newStart = startAndEndTimes?.startTime || 0
        newSRTArray = [
          {
            index: 1,
            start: newStart,
            end: 5,
            words: [
              {
                uuid: uuidv4(),
                text: "",
                start: 0,
              },
            ],
          },
        ]
      } else if (startOrSRTIndex === "start") {
        const newStart = startAndEndTimes?.startTime || 0
        newSRTArray = [
          {
            index: 1,
            start: newStart,
            end: newSRTArray[0].start,
            words: [
              {
                uuid: uuidv4(),
                text: "",
                start: 0,
              },
            ],
          },
          ...newSRTArray,
        ]
      } else if (startOrSRTIndex === "end") {
        const lastEl = newSRTArray[newSRTArray.length - 1]
        const newStart = lastEl.end || 0
        const newEnd = newStart + 5
        newSRTArray = [
          ...newSRTArray,
          {
            index: 1,
            start: newStart,
            // if last caption's endTime goes past trimmed start/end,
            // avoid creating negative duration caption
            end: newEnd,
            words: [
              {
                uuid: uuidv4(),
                text: "",
                start: 0,
              },
            ],
          },
        ]
      } else if (typeof startOrSRTIndex === "number") {
        // number will be position that new item is being inserted at

        const elementsBefore = newSRTArray.slice(0, startOrSRTIndex)
        const elementsAfter = newSRTArray.slice(
          startOrSRTIndex,
          newSRTArray.length
        )

        const newStart = newSRTArray[startOrSRTIndex - 1].end || 0
        const nextEl = newSRTArray[startOrSRTIndex]
        const newEnd = nextEl ? nextEl.start : newStart + 5

        const newElement = {
          index: startOrSRTIndex,
          start: newStart,
          end: newEnd || 0,
          words: [
            {
              uuid: uuidv4(),
              text: "",
              start: 0,
            },
          ],
        }

        newSRTArray = [...elementsBefore, newElement, ...elementsAfter]
      }

      const newSrtObject = srtArrToObject(newSRTArray)
      setSrtWithWordTimings &&
        setSrtWithWordTimings(Object.values(newSrtObject))
    },
    [setSrtWithWordTimings, srtWithWordTimings, startAndEndTimes?.startTime]
  )

  const disabled = !setSrtWithWordTimings

  const oddRowBackgroundColor = darkModeEnabled
    ? "rgba(255,255,255,0.15)"
    : "rgba(0,0,0,0.05)"

  return (
    <>
      {errorMessage && (
        <Stack sx={{ width: "100%", mb: 2 }} spacing={2}>
          <Alert
            variant="outlined"
            severity="error"
            onClose={() => setErrorMessage("")}
          >
            {errorMessage}
          </Alert>
        </Stack>
      )}

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{ mb: 2 }}
      >
        {setSrtWithWordTimings && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            gap="0.5rem"
          >
            {/* <Box>
              <ImportedVideoLanguagePicker
                importJobUuid={importJobUuid}
                value={language}
                onChange={(newLanguage) => setLanguage(newLanguage)}
              />
            </Box> */}

            {language && (
              <Tooltip title="Fetches captions for the selected time range while undoing any manual changes">
                <LoadingButton
                  loading={loading}
                  type="submit"
                  color="warning"
                  variant="contained"
                  size="small"
                  id="refresh-captions"
                  onClick={() => refetchCaptions()}
                  loadingIndicator={
                    <CircularProgress color="inherit" size={16} />
                  }
                >
                  Refresh
                </LoadingButton>
              </Tooltip>
            )}

            <Button
              variant="contained"
              size="small"
              onClick={() => handleSRTClear()}
            >
              Clear
            </Button>
          </Stack>
        )}

        {rows && rows.length > 0 && (
          <TablePagination
            style={{ width: "100%" }}
            count={rows.length}
            page={page}
            onPageChange={(_, v) => setPage(v)}
            rowsPerPage={10}
            component={"div"}
            rowsPerPageOptions={[10]}
            ActionsComponent={TablePaginationActions}
          />
        )}
      </Stack>

      <Stack direction="column" alignItems="stretch" sx={{ pb: 2 }}>
        {!loading &&
          rows
            ?.slice(page * PER_PAGE, (page + 1) * PER_PAGE)
            .map((entry, i) => {
              return (
                <Box
                  key={entry.index}
                  style={{
                    backgroundColor: i % 2 === 0 ? oddRowBackgroundColor : "",
                  }}
                >
                  {!disabled && (
                    <AddCaptionSectionButton
                      disabled={loading || disabled}
                      onClick={() =>
                        handleAddCaptionSection(page * PER_PAGE + i || "start")
                      }
                    />
                  )}
                  <MemoizedCaptionEditorRowWithWordTimings
                    srtWithWordTimings={entry}
                    onSRTChange={handleSRTChange}
                    onSRTRemove={handleSRTRemove}
                    disabled={loading || disabled}
                  />
                </Box>
              )
            })}

        {!disabled && !loading && page === 0 && (
          <AddCaptionSectionButton
            onClick={() => handleAddCaptionSection("end")}
            disabled={loading || disabled}
          />
        )}
      </Stack>

      {loading && !setSrtWithWordTimings && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
    </>
  )
}
function propsAreEqual(prevProps: Props, nextProps: Props) {
  if (
    prevProps?.startAndEndTimes?.startTime !==
    nextProps?.startAndEndTimes?.startTime
  )
    return false
  if (
    prevProps?.startAndEndTimes?.endTime !==
    nextProps?.startAndEndTimes?.endTime
  )
    return false
  if (prevProps.initialParams?.uuid !== nextProps.initialParams?.uuid) {
    return false
  }
  if (prevProps.setSrtWithWordTimings !== nextProps.setSrtWithWordTimings) {
    return false
  }
  if (nextProps.renderStaticCaptionData && !prevProps.renderStaticCaptionData) {
    return false
  }
  if (
    JSON.stringify(nextProps.srtWithWordTimings) !==
    JSON.stringify(prevProps.srtWithWordTimings)
  ) {
    return false
  }
  return prevProps.importJob?.uuid === nextProps.importJob?.uuid
}

const MemoizedCaptionEditorWithWordTimings = React.memo(
  CaptionEditorWithWordTimings,
  propsAreEqual
)

export default MemoizedCaptionEditorWithWordTimings

interface SRTObject {
  [key: string]: SRTWithWordTimings
}

const srtArrToObject = (
  srtArr: SRTWithWordTimings[],
  filterStartTime?: number,
  filterEndTime?: number
): SRTObject => {
  // HACK: factor out conversion, copy of above
  const newSrtObject: SRTObject = {}
  srtArr.forEach((entry, i) => {
    if (
      typeof filterStartTime === "number" &&
      typeof filterEndTime === "number"
    ) {
      if ((entry.end || 0) < filterStartTime) return
      if ((entry.start || 0) > filterEndTime) return
    }
    const srtIndex = i
    entry.index = srtIndex
    newSrtObject[srtIndex] = entry
  })

  return newSrtObject
}
