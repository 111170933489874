import React, { PropsWithChildren } from "react"
import { useLocation } from "react-router-dom"

import googleAnalytics from "../lib/googleAnalytics"
import window from "../lib/window"
import { useAppSelector } from "../redux"

const FetchUserProfile = ({ children }: PropsWithChildren<unknown>) => {
  const location = useLocation()
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)

  const [lastLoggedPage, setLastLoggedPage] = React.useState<string | null>(
    null
  )

  React.useEffect(() => {
    currentUserProfile &&
      googleAnalytics.set({ user_id: currentUserProfile.uuid })
    if (lastLoggedPage !== window.location.pathname) {
      googleAnalytics.send({
        hitType: "pageview",
        page: window.location.pathname,
      })
      setLastLoggedPage(window.location.pathname)
    }
  }, [lastLoggedPage, location, currentUserProfile])

  return <>{children}</>
}

export default FetchUserProfile
