import React from "react"

import { SRTWithWordTimings } from "@trimmr/trimmr-lib/types/SRT/SRTWithWordTimings"
import { TextStyleAttributes } from "@trimmr/trimmr-lib/types/TextStyle"

import { Box } from "@mui/material"

import { FPS, SLIDE_WIDTH_PX } from "./constants"
import { horizontalAlignmentToFlex } from "./horizontalAlignmentToFlex"
import getPhraseProgressDecimalWithWordTimings from "./lib/getPhraseProgressDecimalWithWordTimings"
import getWordProgressDecimalWithWordTimings from "./lib/getWordProgressDecimalWithWordTimings"

const verticalAlignmentToFlex = {
  top: "flex-start",
  middle: "center",
  bottom: "flex-end",
}

export const SubtitleWithWordTimings: React.FC<{
  frame: number
  useTextPreformattedSizing?: boolean // will add support for text with whitespace, but doesn't support animations
  srtWithWordTimings?: SRTWithWordTimings[]
  textStyleAttributes: TextStyleAttributes

  paddingVerticalPx: number
  paddingHorizontalPx: number

  startSeconds: number
  endSeconds: number

  noContainer?: boolean
  backgroundColor?: string
}> = ({
  frame,
  // useTextPreformattedSizing,
  srtWithWordTimings = [],

  textStyleAttributes,

  paddingVerticalPx,
  paddingHorizontalPx,

  startSeconds,
  endSeconds,

  noContainer,
  backgroundColor,
}) => {
  const {
    fontColor,
    strokeWidth,
    strokeColor,
    fontName,
    verticalCaptionAlignment,
    horizontalCaptionAlignment,
    fontSizePx,
    oneWordAtATime,
    textHighlightColor,
    textShadow,
    textAnimation,
    textCapitalization,
  } = textStyleAttributes

  // const [wrappedTextSizeRef, { width }] = useElementSize()

  if (!srtWithWordTimings) return null

  // const opacity = interpolate(frame, [0, 30], [0, 1])
  const currentSeconds = frame / FPS

  const currentSRT = srtWithWordTimings?.find(
    (srt) =>
      (srt.start || 0) <= currentSeconds && (srt.end || 0) >= currentSeconds
  )

  if (!currentSRT) return <></>

  const words = currentSRT.words

  // const text = textFromCurrentSRT(currentSRT)

  const phraseProgressDecimal = getPhraseProgressDecimalWithWordTimings(
    oneWordAtATime,
    currentSRT,
    startSeconds,
    endSeconds,
    currentSeconds
  )

  const scaleFactor = textAnimation == "pop-in" ? phraseProgressDecimal : 1

  const textSpans = (hasBackground = false) => {
    // if (!oneWordAtATime) return <span>{text}</span>

    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        className={"text-spans " + (hasBackground ? "has-background" : "")}
        justifyContent={horizontalAlignmentToFlex[horizontalCaptionAlignment]}
        alignItems={verticalAlignmentToFlex[verticalCaptionAlignment]}
        flexWrap="wrap"
        style={{
          transform: `scale(${scaleFactor}, ${scaleFactor})`,
        }}
      >
        {words.map((word, index) => {
          const { wordProgressDecimal, shouldRenderHighlight } =
            getWordProgressDecimalWithWordTimings(
              index,
              oneWordAtATime,
              currentSRT,
              startSeconds,
              endSeconds,
              currentSeconds
            )

          const progressDecimal = phraseProgressDecimal * wordProgressDecimal
          const wordScaleFactor =
            textAnimation == "pop-in" ? wordProgressDecimal : 1

          const fontSize = `${fontSizePx}px`

          const slideInFactor =
            textAnimation == "slide-in" ? progressDecimal : 1
          const left = SLIDE_WIDTH_PX - slideInFactor * SLIDE_WIDTH_PX

          let opacity = !oneWordAtATime || wordProgressDecimal > 0 ? 1 : 0
          if (textAnimation == "fade-in") opacity = progressDecimal

          const renderWord = () => (
            <span
              style={{ display: "block", width: "max-content" }}
              className="word-span"
            >
              {word.text}
              {/* for right align better to always have */}
              {/* {index + 1 !== words.length && <>&nbsp;</>} */}
              <>&nbsp;</>
            </span>
          )

          return (
            <span
              key={index}
              className="span-styles-container"
              style={{
                fontSize,
                opacity,
                lineHeight: 1,
                fontFamily: fontName,
                textAlign: horizontalCaptionAlignment,
                wordBreak: "break-word",
                position: "relative",
                zIndex: 1,
                color: shouldRenderHighlight ? textHighlightColor : fontColor,
                transform: `scale(${wordScaleFactor}, ${wordScaleFactor})`,
                textTransform:
                  textCapitalization === "uppercase" ? "uppercase" : "none",
              }}
            >
              {/* invisible word controls width when all visual elements are absolutely positioned */}
              {/* if not one word at a time, we want to shrink all elements together and not keep the big placeholder */}
              <span
                style={{
                  opacity: 0,
                  fontSize: `${oneWordAtATime ? fontSizePx : fontSize}px`,
                }}
              >
                {renderWord()}
              </span>
              <span
                className="visible-text-span"
                style={{
                  position: "absolute",
                  top: 0,
                  left,
                  bottom: 0,
                  right: 0,
                }}
              >
                {renderWord()}
              </span>

              <span
                className="stroke-span"
                style={{
                  position: "absolute",
                  zIndex: -1,
                  WebkitTextStrokeWidth: `${strokeWidth}px`,
                  WebkitTextStrokeColor: strokeColor,
                  left,
                  top: 0,
                  bottom: 0,
                  right: 0,
                  opacity,
                  fontSize,
                }}
              >
                {renderWord()}
              </span>

              {/* on iOS stroke plus shadow is broken, so use a separate element for shadow */}
              <span
                className="shadow-span"
                style={{
                  position: "absolute",
                  zIndex: -2,
                  textShadow,
                  left,
                  top: 0,
                  bottom: 0,
                  right: 0,
                  opacity,
                  fontSize,
                }}
              >
                {renderWord()}
              </span>

              {hasBackground && (
                <span
                  className="background-span-container"
                  style={{
                    position: "absolute",
                    zIndex: -3,
                    textShadow,
                    left: left - fontSizePx / 3,
                    top: -(fontSizePx / 3.5),
                    bottom: -(fontSizePx / 4),
                    right: -5,
                    opacity,
                    fontSize,

                    backgroundColor,
                    borderRadius: 10,
                  }}
                >
                  <span className="background-span-text" style={{ opacity: 0 }}>
                    {renderWord()}
                  </span>
                </span>
              )}
            </span>
          )
        })}
      </Box>
    )
  }

  if (noContainer) return textSpans()

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems={horizontalAlignmentToFlex[horizontalCaptionAlignment]}
        justifyContent={verticalAlignmentToFlex[verticalCaptionAlignment]}
        height="100%"
        style={{
          paddingTop: `${paddingVerticalPx}px`,
          paddingBottom: `${paddingVerticalPx}px`,
          paddingLeft: `${paddingHorizontalPx}px`,
          paddingRight: `${paddingHorizontalPx}px`,
        }}
      >
        {textSpans()}
      </Box>
    </>
  )
}
