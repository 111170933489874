import * as React from "react"

import { format } from "date-fns"
import dayjs, { Dayjs } from "dayjs"

import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead"
import WarningIcon from "@mui/icons-material/Warning"
import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { TimePicker } from "@mui/x-date-pickers/TimePicker"

import updateUser from "../../../../api/users/update"
import SmallAlert from "../../../../components/SmallAlert"
import { useAppDispatch, useAppSelector } from "../../../../redux"
import { addSnackbar } from "../../../../redux/snackbars"

interface Props {
  asAdmin?: boolean
}

const DailyIdeaSettings: React.FC<Props> = () => {
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)
  const dispatch = useAppDispatch()

  const [loading, setLoading] = React.useState(false)
  const [dailyIdeaEmailTime, setDailyIdeaEmailTime] =
    React.useState<Dayjs | null>(
      currentUserProfile?.dailyIdeaEmailTime
        ? dayjs(new Date(currentUserProfile?.dailyIdeaEmailTime))
        : null
    )
  const [dailyIdeaEmailEnabled, setDailyIdeaEmailEnabled] =
    React.useState<boolean>(currentUserProfile?.dailyIdeaEmailEnabled || false)

  const updateUserAction = React.useCallback(
    async ({
      dailyIdeaEmailEnabled,
      dailyIdeaEmailTime,
    }: {
      dailyIdeaEmailEnabled: boolean | null
      dailyIdeaEmailTime: Dayjs | null
    }) => {
      // time is in local timezone

      if (!currentUserProfile) return
      setLoading(true)
      if (dailyIdeaEmailTime !== null) setDailyIdeaEmailTime(dailyIdeaEmailTime)
      if (dailyIdeaEmailEnabled !== null)
        setDailyIdeaEmailEnabled(dailyIdeaEmailEnabled)

      try {
        await updateUser(currentUserProfile.uuid, {
          dailyIdeaEmailEnabled:
            dailyIdeaEmailEnabled === true || dailyIdeaEmailEnabled === false
              ? dailyIdeaEmailEnabled
              : undefined,
          dailyIdeaEmailTime: dailyIdeaEmailTime
            ? dailyIdeaEmailTime.toDate()
            : undefined,
        })
      } catch (err) {
        console.error("Error updating user daily idea setting", err)

        dispatch(
          addSnackbar({
            id: "failedtoupdateuserdailyleamil",
            text: "Failed to update user daily email.",
          })
        )
      }

      setLoading(false)
    },
    [currentUserProfile, dispatch]
  )

  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={2}>
        {currentUserProfile && currentUserProfile.isAdmin && (
          <Alert color="warning" variant="outlined" icon={<WarningIcon />}>
            As an admin, when you toggle your daily emails setting off or on, it
            will reset all your "last sent at" times. This is for testing
            purposes, so you can trigger extra emails.
          </Alert>
        )}

        <Typography variant="body1">
          Choose when to receive daily content ideas to your inbox. These will
          be fully-edited videos based on the best parts of your imported
          content. They will not be rendered automatically but you will be able
          to preview them.
        </Typography>

        <Stack direction="row" gap="1rem" flexWrap="wrap" alignItems="center">
          <SmallAlert variant="outlined" color="secondary" icon={false}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={loading}
                    checked={dailyIdeaEmailEnabled}
                    onChange={(e) =>
                      updateUserAction({
                        dailyIdeaEmailEnabled: e.target.checked,
                        dailyIdeaEmailTime: null,
                      })
                    }
                  />
                }
                label="Enable daily idea emails?"
              />
            </FormGroup>
          </SmallAlert>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Daily email time"
              value={dailyIdeaEmailTime}
              onChange={(newValue) =>
                updateUserAction({
                  dailyIdeaEmailEnabled: null,
                  dailyIdeaEmailTime: newValue,
                })
              }
            />
          </LocalizationProvider>

          {currentUserProfile?.dailyIdeaLastSentAt && (
            <SmallAlert
              variant="outlined"
              color="warning"
              icon={<MarkEmailReadIcon />}
              sx={{ py: 0.6 }}
            >
              <AlertTitle sx={{ mb: 0 }}>Last sent at:</AlertTitle>
              <Typography variant="subtitle2">
                {format(new Date(currentUserProfile.dailyIdeaLastSentAt), "Pp")}
              </Typography>
            </SmallAlert>
          )}
        </Stack>
      </Stack>
    </Paper>
  )
}

export default DailyIdeaSettings
