import { useLocation, useNavigate } from "react-router-dom"

import AssessmentIcon from "@mui/icons-material/Assessment"
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh"
import AutoModeIcon from "@mui/icons-material/AutoMode"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import CodeIcon from "@mui/icons-material/Code"
import LocalActivityIcon from "@mui/icons-material/LocalActivity"
import MissedVideoCallIcon from "@mui/icons-material/MissedVideoCall"
import MovieFilterIcon from "@mui/icons-material/MovieFilter"
import PasswordIcon from "@mui/icons-material/Password"
import PermMediaIcon from "@mui/icons-material/PermMedia"
import PersonIcon from "@mui/icons-material/Person"
import { Box, Divider, ListItemButton, Tooltip } from "@mui/material"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"

// import ListSubheader from "@mui/material/ListSubheader"
import InfoAlertsToggle from "../../components/InfoAlertsToggle"

const NavListItem = (props) => {
  const { path, title, open } = props

  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Tooltip title={!open ? title : false} placement="right">
      <div style={{ cursor: "pointer" }}>
        <ListItemButton
          sx={{
            "& .MuiListItemIcon-root": {
              minWidth: "40px",
            },
            paddingLeft: "15px", // Applied directly to ListItem
          }}
          selected={location.pathname.startsWith(path)}
          onClick={() => navigate(path)}
        >
          {props.children}
        </ListItemButton>
      </div>
    </Tooltip>
  )
}

export const AccountPageList = ({ open }: { open: boolean }) => {
  return (
    <>
      <NavListItem
        open={open}
        path="/app/account/video-creator"
        title="Creator"
      >
        <ListItemIcon>
          <MovieFilterIcon />
        </ListItemIcon>
        <ListItemText primary="Creator" secondary="Import, edit, and render" />
      </NavListItem>

      <Divider />

      <NavListItem open={open} path="/app/account/import-jobs" title="Imports">
        <ListItemIcon>
          <CloudDownloadIcon />
        </ListItemIcon>
        <ListItemText primary="Imports" />
      </NavListItem>

      <NavListItem open={open} path="/app/account/render-jobs" title="Shorts">
        <ListItemIcon>
          <MissedVideoCallIcon />
        </ListItemIcon>
        <ListItemText primary="Shorts" />
      </NavListItem>

      <NavListItem open={open} path="/app/account/presets" title="Presets">
        <ListItemIcon>
          <AutoFixHighIcon />
        </ListItemIcon>
        <ListItemText primary="Presets" />
      </NavListItem>

      <NavListItem open={open} path="/app/account/assets" title="Assets">
        <ListItemIcon>
          <PermMediaIcon />
        </ListItemIcon>
        <ListItemText primary="Assets" />
      </NavListItem>

      <NavListItem open={open} path="/app/account/daily-ideas" title="Automate">
        <ListItemIcon>
          <AutoModeIcon />
        </ListItemIcon>
        <ListItemText primary="Automate" />
      </NavListItem>
      {/* <NavListItem path="/app/account/past-ideas">
        <ListItemIcon>
          <LightbulbIcon />
        </ListItemIcon>
        <ListItemText primary="Past Ideas" />
      </NavListItem> */}

      <Divider />

      <NavListItem
        open={open}
        path="/app/account/subscription"
        title="Subscription"
      >
        <ListItemIcon>
          <LocalActivityIcon />
        </ListItemIcon>
        <ListItemText primary="Subscription" />
      </NavListItem>
      <NavListItem open={open} path="/app/account/usage" title="Usage">
        <ListItemIcon>
          <AssessmentIcon />
        </ListItemIcon>
        <ListItemText primary="Usage" />
      </NavListItem>
      <NavListItem open={open} path="/app/account/api-keys" title="API Keys">
        <ListItemIcon>
          <CodeIcon />
        </ListItemIcon>
        <ListItemText primary="API Keys" />
      </NavListItem>
      <NavListItem open={open} path="/app/account/profile" title="Profile">
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="Profile" />
      </NavListItem>
      <NavListItem
        open={open}
        path="/app/account/change-password"
        title="Change Password"
      >
        <ListItemIcon>
          <PasswordIcon />
        </ListItemIcon>
        <ListItemText primary="Change Password" />
      </NavListItem>

      <Divider />

      <Box sx={{ pl: 1.75, mb: 2, pt: 2 }}>
        <InfoAlertsToggle hideLabel={!open} labelLeftPadding="6px" />
      </Box>
    </>
  )
}
