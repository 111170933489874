import * as React from "react"

import { SRTWithWordTimings } from "@trimmr/trimmr-lib/types/SRT/SRTWithWordTimings"

import FormatQuoteIcon from "@mui/icons-material/FormatQuote"
import LightbulbIcon from "@mui/icons-material/Lightbulb"
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  ThemeProvider,
} from "@mui/material"
import Chip from "@mui/material/Chip"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

import { darkTheme, lightTheme } from "../lib/mdTheme"
import { useAppSelector } from "../redux"
import CaptionEditorWithWordTimings from "./CaptionEditor/CaptionEditorWithWordTimings"

function CaptionChip({
  aiTextChoice,
  captionDataWithWordTimings,
  filterStartTime,
  filterEndTime,
  contentAIExplanation,
}: {
  aiTextChoice?: string
  contentAIExplanation?: string
  captionDataWithWordTimings?: SRTWithWordTimings[]
  filterStartTime?: number
  filterEndTime?: number
}) {
  const [open, setOpen] = React.useState(false)
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)

  return (
    <>
      <Chip
        onClick={() => setOpen(true)}
        size="small"
        icon={<FormatQuoteIcon />}
        variant="outlined"
        label="Captions"
        style={{ cursor: "pointer" }}
      />

      <ThemeProvider theme={darkModeEnabled ? darkTheme : lightTheme}>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="captionchip-dialog-title"
          fullWidth
          maxWidth="xl"
        >
          <DialogTitle id="captionchip-dialog-title">Captions</DialogTitle>

          <DialogContent sx={{ pb: 2 }}>
            {contentAIExplanation && (
              <Stack spacing={1} direction="row" sx={{ mb: 2, mt: 1 }}>
                <LightbulbIcon />

                <Typography variant="body1">{contentAIExplanation}</Typography>
              </Stack>
            )}
            {aiTextChoice && (
              <Stack spacing={1} direction="row" sx={{ mb: 2 }}>
                <FormatQuoteIcon sx={{ color: "text.secondary" }} />

                <Typography variant="body1" color="text.secondary">
                  {aiTextChoice}
                </Typography>
              </Stack>
            )}

            {captionDataWithWordTimings &&
              Boolean(captionDataWithWordTimings.length) && (
                <Box sx={{ pt: 2 }}>
                  <CaptionEditorWithWordTimings
                    filterStartTime={filterStartTime}
                    filterEndTime={filterEndTime}
                    renderStaticCaptionData={captionDataWithWordTimings}
                  />
                </Box>
              )}
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </>
  )
}

export default CaptionChip
