import { SxProps } from "@mui/material"
import Link from "@mui/material/Link"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"

export default function Copyright({
  sx = {},
  withPaper,
}: {
  sx?: SxProps
  withPaper?: boolean
}) {
  const content = () => {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        sx={withPaper ? {} : sx}
      >
        {"Copyright © "}
        <Link color="textPrimary" href="https://trimmr.ai/">
          Trimmr
        </Link>{" "}
        {new Date().getFullYear()}
      </Typography>
    )
  }
  if (withPaper) {
    return <Paper sx={{ ...sx, p: 4 }}>{content()}</Paper>
  }
  return content()
}
